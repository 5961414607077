import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class UsefulLink extends Component {
    render() {
        return (
            <div className="widget-link widget-ft">
                <h6 className="widget-title">USEFUL LINK</h6>
                <div className="list-wrap clearfix">
                    <ul className="one-of-two">
                      
                        <li><Link to="/about-us" onClick={() => {window.location.href="/home"}}>Home</Link></li>
                        <li><Link to="/instructors" onClick={() => {window.location.href="/sap-ariba-courses"}}>SAP ARIBA COURSE</Link></li>
                        <li><Link to="/courses-grid-v1" onClick={() => {window.location.href="/"}}>SAP FIELDGLASS COURSE</Link></li>
                        
                        
                    </ul>
                    
                    <ul className="one-of-two">
                        
                        <li><Link to="/blog" onClick={() => {window.location.href="/about-us"}}>About Us </Link></li>
                        <li><Link to="/event-single" onClick={() => {window.location.href="/"}}>FAQs</Link></li>
                        <li><Link to="/event-v1" onClick={() => {window.location.href="/contact-us"}}>  Contact Us</Link></li>
                        
                    </ul>
                </div>
            </div>
        );
    }
}

export default UsefulLink;