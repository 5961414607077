import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import React from 'react';
import WishlistComponent from '../../WishlistComponent';
import Layout from '../layout/layout';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    width: '100%',
  },
  wishlistBox: {
    minHeight: '100vh',
    width: '80%',
    margin: '0 auto',
  },
}));

export default function Wishlist() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Layout>
        <Grid item lg={9} md={9} sm={8} xs={12} className={classes.wishlistBox}>
          <WishlistComponent />
        </Grid>
      </Layout>
    </div>
  );
}
