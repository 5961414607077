import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import dataContent from './dataContentRight'
class ContentRight extends Component {
    render() {
        return (
            <div className="content-page pd-left-30 ">
                <div className="heading clearfix border-f-e6f3ff border-ra4">
                    <div className="taskbar">
                        <ul className="list">
                            <li><span className="color-f3728b font-Poppins font-weight-700"> 1660</span>Courses</li>
                            <li><span className="color-2ebd59 font-Poppins font-weight-700"> 32,060 </span>Video Tutorials</li>
                        </ul>
                    </div>
                    <div className="select-order flat-text-right">
                        <div className="wrap-select">
                            <select className="order-by">
                                <option>Sort By: release date</option>
                            </select>
                        </div>
                    </div>
                </div> {/* /.heading */}
                <div className="flat-courses style4">
                    {
                        dataContent.map ( data => (
                            <div className={data.classnames} key={data.id}>
                                <div className="course-border border-f-e6f3ff border-ra4 clear-sub-columns transition-vline">
                                    <div className="course-img-box">
                                        <div className="course-img img-vline">
                                            <Link to="#"><img src={data.imgsrc} alt="bookflare" /></Link>
                                            <div className="overlay">
                                                <span className="vline" />
                                                <span className="vline vline-bottom" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="course-content">
                                        <div className="text-wrap border-bt-e6f3ff">
                                            <h6 className="title"><Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>{data.title}</Link></h6>
                                            <p className="teacher"><Link to="#">{data.teacher}</Link></p>
                                            <p className="description">{data.description}</p>
                                        </div>
                                        <div className="wrap-rating-price">
                                            <ul className="list meta-rate">
                                                <li>
                                                    <ul className="list star-list">
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                    </ul>
                                                    <span /><span>{data.count}</span>
                                                </li>
                                                <li>
                                                    <span> {data.date}</span>
                                                </li>
                                                <li>
                                                    Views <span className="color-2ebd59">{data.views}</span>
                                                </li>
                                            </ul>
                                            <span className="price">{data.price}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="flat-paginations">
                    <ul className="list flat-text-left pagination-wrap">
                        <li className="disabled"><Link to="#" className="btn-navs">PRE</Link></li>
                        <li className="active"><Link to="#">1</Link></li>
                        <li><Link to="#">2</Link></li>
                        <li><Link to="#">3</Link></li>
                        <li><Link to="#">4</Link></li>
                        <li><Link to="#">5</Link></li>
                        <li><Link to="#" className="btn-navs">NEXT</Link></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default ContentRight;