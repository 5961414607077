import React, { Component } from "react";
import { Link } from "react-router-dom";
class RecentImage extends Component {
  render() {
    return (
      <div className="widget-about ">
        <div className="widget-link widget-ft">
          <h6 className=" widget-title">Contact</h6>
        </div>
        <div className="list-info">
          <ul>
            <li className="phone">
              <Link to="#">+123 345 678 000</Link>
            </li>
            <li className="phone">
              <Link to="#">+123 345 678 000</Link>
            </li>
            <li className="mail">
              <Link to="#">info@aribafieldglassonlinetrainig.com</Link>
            </li>
          </ul>
        </div>
        <div className="socails">
          <ul className="list">
            {/* <li>
              <Link to="#">
                <span className="fa fa-twitter" />
              </Link>
            </li> */}
            <li>
              <Link to={{ pathname: "https://www.linkedin.com/company/80087525/admin/" }} target="_blank">
                <span className="fa fa-linkedin-square" />
              </Link>
            </li>
            <li>
              <Link to={{ pathname: "https://www.facebook.com/Ariba-Fieldglasss-Online-Training-106069778491252" }} target="_blank">
                <span className="fa fa-facebook-official" />
              </Link>
            </li>
            <li>
              <Link to={{ pathname: "https://www.instagram.com/aribafieldglass/" }} target="_blank">
                <span className="fa fa-instagram" />
              </Link>
            </li>
            <li>
              <Link to={{ pathname: "https://in.pinterest.com/aribafieldglass/_saved/"}} target="_blank">
                <span className="fa fa-pinterest-square" />
              </Link>
            </li>
          </ul>
        </div>
        {/* <div className="wrap-btn">
                    <Link to={'//www.pinterest.com'} target="_blank" className="btn-view-more">VIEW MORE</Link>
                </div> */}
      </div>
    );
  }
}

export default RecentImage;
