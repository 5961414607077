import React, { Component } from 'react';
import {
  Header,
  TopBar,
  Paramater,
  Footer,
  BottomBar,
  Loader,
} from '../layouts/general';
import {
  AccordionCompent,
  BannerComponent,
  InforComponent,
  TabAboutComponent,
} from '../layouts/pages';
import { VideoComponent } from '../layouts/home02';
import { BannerProfessional } from '../layouts/home04';

class AboutUs extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          names: 'Pages',
        },
      ],
      dataBanner: [
        {
          id: 1,
          classename01: 'flat-title-page parallax parallax7 style3',
          classename02: 'container wrap-title-page ',
          links: '/about-us',
          names: 'About Us',
        },
      ],
    };
  }
  render() {
    
    return (
      <div className="bg-body2">
        <div className="boxed">
          <Loader />
          <TopBar />
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}
          {this.state.dataBanner.map((data) => (
            <BannerComponent data={data} key={data.id} />
          ))}
          <div className="content-wrap about-us-page">
            <InforComponent />
            {/* <TabAboutComponent /> */}
            <VideoComponent />
            <section className="flat-faqs">
              <div className="heading flat-text-center">
                <h6 className="title-small">QUESTIONS AND ANSWERS (Q&amp;A)</h6>
                <h3 className="title font-Poppins">
                  Frequently Asked Questions (FAQ)
                </h3>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-1" />
                  <div className="col-md-10">
                  {/* faq */}
                    <AccordionCompent />
                  </div>
                </div>
              </div>
            </section>
            <section class="flat-free-month parallax parallax14">
              <div class="container">
                <BannerProfessional />
              </div>
            </section>
          </div>
          <section className="flat-feature parallax parallax2 style2">
            <div className="container">
              <Paramater />
            </div>
          </section>
          <Footer />
          <BottomBar />
        </div>
      </div>
    );
  }
}

export default AboutUs;
