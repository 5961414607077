import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import httpService from '../../../HttpService/httpService';

export default function AcordionCompent() {
  const [faq, setFaq] = useState([]);
  useEffect(() => {
    httpService
      .get('/faqs/for/customer')
      .then((res) => {
        console.log("this is faq response",res)
        setFaq(res.data.faqs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      {faq.map((data) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            key={data.id}
          >
            <h6>{data.question}</h6>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{data.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
