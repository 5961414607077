import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Header, TopBar , Paramater, Footer, BottomBar, Loader } from '../layouts/general';
import { BannerComponent, ShopComponent } from '../layouts/pages';

class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          names: 'Pages',
          names02: 'Shop',
        },
      ],
      dataBanner: [
        {
          id: 1,
          classename01: 'flat-title-page parallax parallax5 style3',
          classename02: 'container wrap-title-page ',
          links: '/shop',
          names: 'Shop',
        },
      ],
    };
  }
  render() {
    return (
      <div className="bg-body2">
        <div className="boxed">
          <Loader />
          <TopBar />
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}
          {this.state.dataBanner.map((data) => (
            <BannerComponent data={data} key={data.id} />
          ))}
          <div className="content-wrap shop-page">
            <div className="container">
              <div className="content-page-wrap">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="flat-filter clearfix border-f-e6f3ff border-ra4">
                      <div className="show-product">
                        <p className="result-count">
                          Showing 1–9 of 60 results
                        </p>
                      </div>
                      <div className="woocommerce-ordering ">
                        <form method="get">
                          <div className="wrap-select">
                            <select name="orderby" className="orderby">
                              <option value="menu_order" selected="selected">
                                Default sorting
                              </option>
                              <option value="popularity">
                                Sort by popularity
                              </option>
                              <option value="rating">
                                Sort by average rating
                              </option>
                              <option value="date">Sort by newness</option>
                              <option value="price">
                                Sort by price: low to high
                              </option>
                              <option value="price-desc">
                                Sort by price: high to low
                              </option>
                            </select>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <ShopComponent />
                <div className="flat-paginations">
                  <ul className="list flat-text-center pagination-wrap">
                    <li className="disabled">
                      <Link to="#" className="btn-navs">
                        PRE
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="#">1</Link>
                    </li>
                    <li>
                      <Link to="#">2</Link>
                    </li>
                    <li>
                      <Link to="#">3</Link>
                    </li>
                    <li>
                      <Link to="#">4</Link>
                    </li>
                    <li>
                      <Link to="#">5</Link>
                    </li>
                    <li>
                      <Link to="#" className="btn-navs">
                        NEXT
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section className="flat-feature parallax parallax2">
            <div className="container">
              <Paramater />
            </div>
          </section>
          <Footer />
          <BottomBar />
        </div>
      </div>
    );
  }
}

export default Shop;