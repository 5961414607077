import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import datas from './data'

class CardIntructor extends Component {
    render() {
        return (
            <div className="row">
                {
                    datas.map(data =>(
                        <div className="col-lg-4 col-md-6" key={data.id}>
                            <div className={data.classnames}>
                                <div className="avata-member">
                                    <Link to="#"><img src={data.src} alt="bookflare"
                                            className="transition-img" /></Link>
                                </div>
                                <div className="info-member">
                                    <h6 className="name"><Link to="#">{data.name}</Link></h6>
                                        <p className="position">{data.position}</p>
                                    <p className="description">
                                        {data.description}
                                    </p>
                                </div>
                                <div className={data.classicon}>
                                    <ul className="list">
                                        <li className="facbook"><Link to="#"><i className="fa fa-facebook" /></Link></li>
                                        <li className="instagram"><Link to="#"><i className="fab fa-instagram" /></Link></li>
                                        <li className="skype"><Link to="#"><i className="fab fa-skype" /></Link></li>
                                        <li className="github"><Link to="#"><i className="fab fa-github" /></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))
                }                
            </div>
        );
    }
}

export default CardIntructor;