import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import CartComponent from '../cart/cart-component';
import CheckoutComponent from '../cart/checkout-component';
import Layout from '../layout/layout';

const useStyles = makeStyles((theme) => ({
  box: {
    margin: '0px',
    padding: '0px',
    height: 'auto',
    background: 'black',
    '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      padding: '0px',
    },
  },
  shoppingCart: {
    color: 'white',
    fontSize: '40px',
    textAlign: 'left',
    padding: '10px',
    fontFamily: `'Abril Fatface', cursive`,
  },
  cartData: {
    maxWidth: '90%',
    padding: '0px',
    margin: '0 auto',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px',
      margin: '0px',
      width: '100%',
      marginLeft: '21px',
      marginBottom: '7px',
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: '100%',
    },
  },
  checkoutCard: {
    padding: '0px !important',
    marginTop: '10px!',
    [theme.breakpoints.down('xs')]: {
      '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
        padding: '0px ',
      },
    },
  },
}));

export default function Cart() {
  const classes = useStyles();
  return (
    <>
      <Layout>
        <Box className={classes.box}>
          <Typography className={classes.shoppingCart}>
            Shopping Cart
          </Typography>
        </Box>
        <Grid container className={classes.cartData} spacing={2}>
          <Grid item lg={9} md={9} sm={8} xs={12}>
            <CartComponent />
          </Grid>
          <Grid
            item
            lg={3}
            md={3}
            sm={4}
            xs={12}
            className={classes.checkoutCard}
          >
            <CheckoutComponent />
          </Grid>
        </Grid>
      </Layout>
    </>
  );
}

