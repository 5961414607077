import React, { useState } from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { checkValidity } from "../../../Shared";
import httpService from "../../../HttpService/httpService";
import CustomizedSnackbars from "../../../Constants/config";
import { useHistory } from "react-router-dom";

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url(../images/register/gear-background.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#cbdae1",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "auto",
    flexGrow: 1,
  },
  paper: {
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#1f3a68",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1f3a68",
    "&:hover": {
      background: "#1f3a68",
      color: "#f7c13a",
    },
  },
  mainGrid: {
    backgroundColor: "transperent",
    margin: "25px 0 25px 0",
  },
  formField: {
    padding: "5px 0px 10px 0px",
  },
  headlogin: {
    fontSize: "1.875rem",
    fontWeight: "700",
    lineHeight: "2.25rem",
  },
}));

export default function LoginComponent() {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  let history = useHistory();

  const [stateObj, setError] = useState({
    error: {
      email: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          isEmail: true,
        },
      },
      password: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          minLength: 8,
          isPass: true,
          maxLength: 15,
        },
      },
    },
    account: { email: "", password: "" },
  });

  const changeHandler = (e) => {
    const { value, name } = e.target;
    const { error, account } = stateObj;
    const validationObj = error[name];

    const { errorObject } = checkValidity(value, name, validationObj);
    setError({
      ...stateObj,
      error: { ...error, [name]: { errorObject } },
      account: { ...account, [name]: value },
    });
  };
  const closeSnackbar = () => {
    setSnackbar(false);
  };
  const onSubmitHandler = () => {
    const { error, account } = stateObj;

    for (let err in error) {
      const value = account[err];
      const name = err;
      const validationObj = error[name];
      const { errorObject } = checkValidity(value, name, validationObj);
      error[name] = { errorObject };
    }
    setError({ account, error: error });

    httpService
      .post("/customer/login", {
        email: account.email,
        password: account.password,
      })
      .then((res) => {
        console.log('login',res);
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem("customer_name", res.data.customerName);
        localStorage.setItem("customerId", res.data.customerId);

        setSeverity("success");
        setMessage(res.data.message);
        setSnackbar(true);

        // history.push("/my-account");
        // window.location.reload();
        setTimeout(() => {
         history.push("/my-account");
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("warning");
        setMessage(err.response.data.message);
        setSnackbar(true);
      });
  };

  return (
    <Grid
      container
      component="main"
      className={classes.image}
      justifyContent="center"
    >
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        className={classes.mainGrid}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h2" variant="h2" className={classes.headlogin}>
            Sign in to your account
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              className={classes.formField}
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              disabled={false}
              onChange={changeHandler}
              error={!stateObj.error.email.errorObject.isValid}
              helperText={stateObj.error.email.errorObject.errorMessage}
            />
            <TextField
              className={classes.formField}
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={changeHandler}
              error={!stateObj.error.password.errorObject.isValid}
              helperText={stateObj.error.password.errorObject.errorMessage}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmitHandler}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                Don't have an account?{" "}
                <Link href="/registeration" variant="body2">
                  {"Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <CustomizedSnackbars
              open={snackbar}
              closeSnackbar={closeSnackbar}
              message={message}
              severity={severity}
            />
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
