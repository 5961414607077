import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import datashop from './dataShop'

class ShopComponent extends Component {
    render() {
        return (
            <div className="row flat-products">
                {
                    datashop.map( data => (
                        <div className="col-lg-4 col-md-6" key={data.id}>
                            <div className="product-item transition-img-socails border-f-e6f3ff">
                                <div className="product-img transition-img">
                                    <img src={data.srcimg} alt="bookflare" />
                                    <div className="overlay" />
                                    <div className="socails">
                                        <ul className="list">
                                            <li><Link to="#"><span className="icon-search" /></Link></li>
                                            <li><Link to="#"><span className="icon_heart_alt" /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="product-content flat-text-center border-bt-e6f3ff">
                                    <h6 className="title"><Link to="/shop-single" onClick={() => {window.location.href="/shop-single"}}>{data.title}</Link></h6>
                                    <div className="price-wrap">
                                        <span className="sale-price">{data.pricesale}</span><span class="price">{data.price}</span>
                                    </div>
                                </div>
                                <div className="wrap-btn flat-text-center">
                                    <Link to="#" className="flat-button btn-cart">ADD TO CART</Link>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ShopComponent;