import {
  mobileNumberRegularExp,
  passwordRegularExp,
  emailRegularExp,
  firstNameRegularExp,
  lastNameRegularExp,
  errorMessage,
} from '../Shared/errorMessage';

export function checkValidity(value, name, rules) {
  let isValid = false;
  const { errorObject } = rules;
  let errorMsg = '';
  if (errorObject.required === false) {
    isValid = true;
  }
  if (errorObject.isDropdown) {
    if (errorObject.required) {
      if (!value) {
        isValid = false;
        errorMsg = errorMessage[name];
      } else {
        isValid = true;
        console.log(isValid);
      }
    }
  } else if (errorObject.isDate) {
    if (errorObject.required) {
      const dateValue = Date.parse(value)
        ? new Date(value).toISOString()
        : null;
      if (!dateValue) {
        isValid = false;
        errorMsg = errorMessage[name];
      } else {
        isValid = true;
      }
    }
  } else {
    if (errorObject.required) {
      if (errorObject.isNumeric) {
        if (isNaN(value)) {
          isValid = false;
          errorMsg = `${errorMessage[`${name}Numeric`]}`;
        } else {
          isValid = value >= 0;
        }
      } else {
        isValid = value.trim() !== '';
        if (!isValid) errorMsg = errorMessage[name];
      }
    }
    if (errorObject.isEmail && isValid === true) {
      const patt = new RegExp(emailRegularExp);
      isValid = patt.test(value);
      if (!isValid) errorMsg = `${errorMessage[`${name}InValid`]}`;
    }
    if (errorObject.minLength && isValid === true) {
      isValid = value.length >= errorObject.minLength;
      if (!isValid)
        errorMsg = `${errorMessage[`${name}Minlength`]} ${
          errorObject.minLength
        } character`;
    }
    if (errorObject.maxLength && isValid === true) {
      isValid = value.length <= errorObject.maxLength;
      if (!isValid)
        errorMsg = `${errorMessage[`${name}Maxlength`]} ${
          errorObject.maxLength
        } character`;
    }
    if (errorObject.isPass && isValid === true) {
      const patt = new RegExp(passwordRegularExp);
      isValid = patt.test(value);
      if (!isValid) errorMsg = `${errorMessage[`${name}InValid`]}`;
    }
    if (errorObject.isfirstName && isValid === true) {
      const patt = new RegExp(firstNameRegularExp);
      isValid = patt.test(value);
      if (!isValid) errorMsg = `${errorMessage[`${name}InValid`]}`;
    }
    if (errorObject.fMinlength && isValid === true) {
      isValid = value.length >= errorObject.fMinlength;
      if (!isValid)
        errorMsg = `${errorMessage[`${name}Minlength`]} ${
          errorObject.fMinlength
        } character`;
    }
    if (errorObject.fMaxlength && isValid === true) {
      isValid = value.length <= errorObject.fMaxlength;
      if (!isValid)
        errorMsg = `${errorMessage[`${name}Maxlength`]} ${
          errorObject.fMaxlength
        } character`;
    }
    if (errorObject.islastName && isValid === true) {
      const patt = new RegExp(lastNameRegularExp);
      isValid = patt.test(value);
      if (!isValid) errorMsg = `${errorMessage[`${name}InValid`]}`;
    }
    if (errorObject.lMinlength && isValid === true) {
      isValid = value.length >= errorObject.lMinlength;
      if (!isValid)
        errorMsg = `${errorMessage[`${name}Minlength`]} ${
          errorObject.lMinlength
        } character`;
    }
    if (errorObject.lMaxlength && isValid === true) {
      isValid = value.length <= errorObject.lMaxlength;
      if (!isValid)
        errorMsg = `${errorMessage[`${name}Maxlength`]} ${
          errorObject.lMaxlength
        } character`;
    }
    if (errorObject.isNumber && isValid === true) {
      const patt = new RegExp(mobileNumberRegularExp);
      isValid = patt.test(value);
      if (!isValid) errorMsg = `${errorMessage[`${name}IsNumber`]}`;
    }
    if (errorObject.MobmaxLength && isValid === true) {
      isValid = value.length <= errorObject.MobmaxLength;
      console.log(isValid);
      if (!isValid)
        errorMsg = `${errorMessage[`${name}Maxlength`]} ${
          errorObject.MobmaxLength
        } digits`;
    }
    if (errorObject.MobminLength && isValid === true) {
      isValid = value.length >= errorObject.MobminLength;
      console.log(isValid);
      if (!isValid)
        errorMsg = `${errorMessage[`${name}Minlength`]} ${
          errorObject.MobminLength
        } digits`;
    }

    if (errorObject.isName && isValid === true) {
      const patt = new RegExp(firstNameRegularExp);
      isValid = patt.test(value);
      if (!isValid) errorMsg = `${errorMessage[`${name}InValid`]}`;
    }

    if (errorObject.nMinlength && isValid === true) {
      isValid = value.length >= errorObject.nMinlength;
      if (!isValid)
        errorMsg = `${errorMessage[`${name}Minlength`]} ${
          errorObject.nMinlength
        } character`;
    }
    if (errorObject.nMaxlength && isValid === true) {
      isValid = value.length <= errorObject.nMaxlength;
      if (!isValid)
        errorMsg = `${errorMessage[`${name}Maxlength`]} ${
          errorObject.nMaxlength
        } character`;
    }
       if (errorObject.Match && isValid === true) {

     // const passwordValue = newPassword.value;
    //  console.log(newPassword.value);
      // const confirmPasswordValue = confirmPassword.value;
      // if (confirmPasswordValue != passwordValue) {
      //   isValid = false;
      //   errorMsg = `${errorMessage[`${name}Match`]}`;
      // } else {
      //   //isValid = true;
      //   console.log('password matched');
      // }
    }
    // if (errorObject.isSubject&& isValid === true) {
    //   const patt = new RegExp(emailRegularExp);
    //   isValid = patt.test(value);
    //   if (!isValid) errorMsg = `${errorMessage[`${name}InValid`]}`;
    // }
    // if (errorObject.isMsg&& isValid === true) {
    //   const patt = new RegExp(emailRegularExp);
    //   isValid = patt.test(value);
    //   if (!isValid) errorMsg = `${errorMessage[`${name}InValid`]}`;
    // }
  
  }

 

  if (!isValid) {
    rules = {
      ...rules,
      errorObject: { ...errorObject, errorMessage: errorMsg, isValid },
    };
  } else {
    rules = {
      ...rules,
      errorObject: { ...errorObject, errorMessage: '', isValid },
    };
  }
  return rules;
}
