import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box,Typography, Container,Grid,Card,CardContent,CardActions,CardHeader,CardMedia} from '@material-ui/core';
// import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import MoreVertIcon from '@material-ui/icons/MoreVert';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function MyCoursesDashboard() {
  const classes = useStyles();

  return (
     <Container component="main" maxidth="md" className="">
        
        <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
            <Card className={classes.root}>
            <CardHeader
                avatar={
                <Avatar aria-label="recipe" className={classes.avatar}>
                    R
                </Avatar>
                }
                // action={
                //   
                // }
                title={
                    <Typography variant="h5" component="h2">SAP Ariba Course</Typography>
                }
            
                subheader="September 14, 2016"
            />
            <CardMedia
                className={classes.media}
                image="images/feature/24-7-SAP Ariba-Server-access.png"
                title="Paella dish"
            />
            <CardContent>
                <Typography><IconButton aria-label="add to favorites"><DateRangeIcon/></IconButton>Purchased Date:14/8/2016</Typography>
                
                <Typography><IconButton aria-label="add to favorites"><ScheduleIcon/></IconButton>Course Duration : 30 Days</Typography>
                <Typography><IconButton aria-label="add to favorites"><MonetizationOnIcon/></IconButton>Course : $300</Typography>
            </CardContent>
            <CardActions disableSpacing>
                
                
            </CardActions>
            
            </Card>
            
            
        </Grid>  

        <Grid item xs={12} md={6}>
        <Card className={classes.root}>
        <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            R
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={
            <Typography variant="h5" component="h2">SAP Ariba Course</Typography>
        }
       
        subheader="September 14, 2016"
      />
      <CardMedia
        className={classes.media}
        image="images/feature/24-7-SAP Ariba-Server-access.png"
        title="Paella dish"
      />
      <CardContent>
      <Typography><IconButton aria-label="add to favorites"><DateRangeIcon/></IconButton>Purchased Date:14/8/2016</Typography>
      
      <Typography><IconButton aria-label="add to favorites"><ScheduleIcon/></IconButton>Course Duration : 30 Days</Typography>
      <Typography><IconButton aria-label="add to favorites"><MonetizationOnIcon/></IconButton>Course : $300</Typography>
      </CardContent>
      <CardActions disableSpacing>
          
        
      </CardActions>
     
    </Card>
            
            
    </Grid>  

</Grid> 
 </Container>
  );
}
