import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class TabCoaching extends Component {
    render() {
        return (
            <div className="flat-tabs ">
                <ul className="tab-title clear-sub-columns style5">
                    <li className="item-title one-of-four">
                        <div className="iconbox style4 flat-text-center one">
                            <div className="iconbox-icon">
                                <span className="icon-portfolio-1">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" /><span className="path12" /><span
                                        className="path13" /><span className="path14" /><span className="path15" /><span
                                        className="path16" /><span className="path17" />
                                </span>
                            </div>
                            <div className="iconbox-content">
                                <h6 className="title">
                                    Online Learn Courses
                                </h6>
                            </div>
                        </div>
                    </li>
                    <li className="item-title one-of-four">
                        <div className="iconbox style4 flat-text-center two">
                            <div className="iconbox-icon">
                                <span className="icon-portfolio-3">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" /><span className="path12" /><span
                                        className="path13" /><span className="path14" /><span className="path15" /><span
                                        className="path16" /><span className="path17" />
                                </span>
                            </div>
                            <div className="iconbox-content">
                                <h6 className="title">
                                    Career Achievements
                                </h6>
                            </div>
                        </div>
                    </li>
                    <li className="item-title one-of-four">
                        <div className="iconbox style4 flat-text-center three">
                            <div className="iconbox-icon">
                                <span className="icon-curriculum">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" /><span className="path12" /><span
                                        className="path13" />
                                </span>
                            </div>
                            <div className="iconbox-content">
                                <h6 className="title">
                                    Course Management
                                </h6>
                            </div>
                        </div>
                    </li>
                    <li className="item-title one-of-four">
                        <div className="iconbox style4 flat-text-center four">
                            <div className="iconbox-icon">
                                <span className="icon-contract-1"><span className="path1" /><span className="path2" /><span
                                        className="path3" /><span className="path4" /><span className="path5" /><span
                                        className="path6" /><span className="path7" /><span className="path8" /><span
                                        className="path9" /><span className="path10" /><span className="path11" /><span
                                        className="path12" /><span className="path13" /><span className="path14" /></span>
                            </div>
                            <div className="iconbox-content">
                                <h6 className="title">
                                    Teacher Management
                                </h6>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="tab-content-wrap">
                    <div className="tab-content box-shadow ">
                        <div className="item-content clearfix">
                            <div className="img flat-text-center">
                                <img src="images/feature/img-tab.png" alt="bookflare" />
                            </div>
                            <div className="text-wrap img-right clearfix">
                                <h4 className="title">
                                    Online Learn Courses
                                </h4>
                                <p className="text">Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                    the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley
                                    of type and scrambled it to make a type specimen book.Smply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry’s when an unknown printerit to make a
                                    type specimen book. Smply dummy text of the printing and typesetting industry.</p>
                                <div className="wrap-btn">
                                    <Link to="#" className="btn-register">REGISTER NOW</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content box-shadow ">
                        <div className="item-content clearfix">
                            <div className="img flat-text-center">
                                <img src="images/feature/img-tab.png" alt="bookflare" />
                            </div>
                            <div className="text-wrap img-right clearfix">
                                <h4 className="title">
                                    Career Achievements
                                </h4>
                                <p className="text">Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                    the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley
                                    of type and scrambled it to make a type specimen book.Smply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry’s when an unknown printerit to make a
                                    type specimen book. Smply dummy text of the printing and typesetting industry.</p>
                                <div className="wrap-btn">
                                    <Link to="#" className="btn-register">REGISTER NOW</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content box-shadow ">
                        <div className="item-content clearfix">
                            <div className="img flat-text-center">
                                <img src="images/feature/img-tab.png" alt="bookflare" />
                            </div>
                            <div className="text-wrap img-right clearfix">
                                <h4 className="title">
                                    Course Management
                                </h4>
                                <p className="text">Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                    the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley
                                    of type and scrambled it to make a type specimen book.Smply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry’s when an unknown printerit to make a
                                    type specimen book. Smply dummy text of the printing and typesetting industry.</p>
                                <div className="wrap-btn">
                                    <Link to="#" className="btn-register">REGISTER NOW</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content box-shadow ">
                        <div className="item-content clearfix">
                            <div className="img flat-text-center">
                                <img src="images/feature/img-tab.png" alt="bookflare" />
                            </div>
                            <div className="text-wrap img-right clearfix">
                                <h4 className="title">
                                    Teacher Management
                                </h4>
                                <p className="text">Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                    the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley
                                    of type and scrambled it to make a type specimen book.Smply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry’s when an unknown printerit to make a
                                    type specimen book. Smply dummy text of the printing and typesetting industry.</p>
                                <div className="wrap-btn">
                                    <Link to="#" className="btn-register">REGISTER NOW</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TabCoaching;