import React, { Component } from 'react';
import {Link} from 'react-router-dom';
//import { ComparisionComponent } from '.';
import datacourses from '../homeanimate/dataBox'
class ComparisionComponent extends Component {
    render() {
        return (
          // <!-- Pricing # -->
          <div className="pricing">
            <div className="container-fluid">
              <div className="pricing-table table-responsive">
                <table className="table">
                  {/* <!-- Heading --> */}
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>
                     
                            <div className="princing-item yellow course style2  effect-animation" >
                                  <div className="pricing-divider1 ">
                                        <h5 className="text-light" style={{textTransform:'capitalize'}}>Self Paced <br/>Training</h5>
                                        <h5 className="my-0 display-4 text-light font-weight-normal mb-3"><span className="h3">$</span> 250 <span className="h5">/mo</span></h5>
                                        <svg className='pricing-divider-img' enable-background='new 0 0 300 100' height='100px' id='Layer_1' preserveAspectRatio='none' version='1.1' viewBox='0 0 300 100' width='300px' x='0px' xmlSpace='preserve' xlinkHref='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' y='0px'>
                                            <path className='deco-layer deco-layer--1' d='M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                                            c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z' fill='#FFFFFF' opacity='0.6'></path>
                                                <path className='deco-layer deco-layer--2' d='M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                                            c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z' fill='#FFFFFF' opacity='0.6'></path>
                                                <path className='deco-layer deco-layer--3' d='M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                                            H42.401L43.415,98.342z' fill='#FFFFFF' opacity='0.7'></path>
                                                <path className='deco-layer deco-layer--4' d='M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                                            c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z' fill='#FFFFFF'></path>
                                        </svg>
                                    </div>

                                    <div className="card-body bg-white mt-0 shadow">
                                        {/* <ul className="list-unstyled mb-5 position-relative">
                                       
                                        </ul> */}
                                        <Link to="#" ><button className="btn btn-lg btn-block  btn-custom font-weight-700 border-ra4 v2">Enroll Now</button></Link>
                                      
                                    </div>
                                </div>
                        
                        
                      
                      </th>
                      <th className="">
                                <div className="princing-item green course style2  effect-animation" >
                                  <div className="pricing-divider1 ">
                                        <h5 className="text-light" style={{textTransform:'capitalize'}}>Self Paced Training with Server Access</h5>
                                        <h4 className="my-0 display-4 text-light font-weight-normal mb-3"><span className="h3">$</span> 250 <span className="h5">/mo</span></h4>
                                        <svg className='pricing-divider-img' enable-background='new 0 0 300 100' height='100px' id='Layer_1' preserveAspectRatio='none' version='1.1' viewBox='0 0 300 100' width='300px' x='0px' xmlSpace='preserve' xlinkHref='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' y='0px'>
                                            <path className='deco-layer deco-layer--1' d='M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                                            c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z' fill='#FFFFFF' opacity='0.6'></path>
                                                <path className='deco-layer deco-layer--2' d='M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                                            c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z' fill='#FFFFFF' opacity='0.6'></path>
                                                <path className='deco-layer deco-layer--3' d='M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                                            H42.401L43.415,98.342z' fill='#FFFFFF' opacity='0.7'></path>
                                                <path className='deco-layer deco-layer--4' d='M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                                            c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z' fill='#FFFFFF'></path>
                                        </svg>
                                    </div>

                                    <div className="card-body bg-white mt-0 shadow">
                                        {/* <ul className="list-unstyled mb-5 position-relative">
                                       
                                        </ul> */}
                                        <Link to="#" ><button className="btn btn-lg btn-block  btn-custom font-weight-700 border-ra4 v2">Enroll Now</button></Link>
                                      
                                    </div>
                                </div>
                      </th>
                      <th>
                    
                      <div className="princing-item blue course style2  effect-animation" >
                            <div className="pricing-divider1 ">
                                        <h5 className="text-light" style={{textTransform:'capitalize'}}>Instructor Led Live Training</h5>
                                        <h4 className="my-0 display-4 text-light font-weight-normal mb-3"><span className="h3">$</span> 250 <span className="h5">/mo</span></h4>
                                        <svg className='pricing-divider-img' enable-background='new 0 0 300 100' height='100px' id='Layer_1' preserveAspectRatio='none' version='1.1' viewBox='0 0 300 100' width='300px' x='0px' xmlSpace='preserve' xlinkHref='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' y='0px'>
                                            <path className='deco-layer deco-layer--1' d='M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                                            c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z' fill='#FFFFFF' opacity='0.6'></path>
                                                <path className='deco-layer deco-layer--2' d='M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                                            c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z' fill='#FFFFFF' opacity='0.6'></path>
                                                <path className='deco-layer deco-layer--3' d='M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                                            H42.401L43.415,98.342z' fill='#FFFFFF' opacity='0.7'></path>
                                                <path className='deco-layer deco-layer--4' d='M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                                            c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z' fill='#FFFFFF'></path>
                                        </svg>
                                    </div>

                                    <div className="card-body bg-white mt-0 shadow">
                                        {/* <ul className="list-unstyled mb-5 position-relative">
                                       
                                        </ul> */}
                                        <Link to="#" ><button className="btn btn-lg btn-block  btn-custom font-weight-700 border-ra4 v2">Enroll Now</button></Link>
                                      
                            </div>
                        </div>
                      </th>
                      <th>
                        <div className="princing-item red course style2  effect-animation" >
                                  <div className="pricing-divider1 ">
                                        <h5 className="text-light" style={{textTransform:'capitalize'}}>{" "} {" "}Server <br/>Access {'\u00A0'} {" "}<br/></h5>
                                        <h4 className="my-0 display-4 text-light font-weight-normal mb-3"><span className="h3">$</span> 250 <span className="h5">/mo</span></h4>
                                        <svg className='pricing-divider-img' enable-background='new 0 0 300 100' height='100px' id='Layer_1' preserveAspectRatio='none' version='1.1' viewBox='0 0 300 100' width='300px' x='0px' xmlSpace='preserve' xlinkHref='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' y='0px'>
                                            <path className='deco-layer deco-layer--1' d='M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                                            c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z' fill='#FFFFFF' opacity='0.6'></path>
                                                <path className='deco-layer deco-layer--2' d='M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                                            c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z' fill='#FFFFFF' opacity='0.6'></path>
                                                <path className='deco-layer deco-layer--3' d='M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                                            H42.401L43.415,98.342z' fill='#FFFFFF' opacity='0.7'></path>
                                                <path className='deco-layer deco-layer--4' d='M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                                            c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z' fill='#FFFFFF'></path>
                                        </svg>
                                    </div>

                                    <div className="card-body bg-white mt-0 shadow">
                                        {/* <ul className="list-unstyled mb-5 position-relative">
                                       
                                        </ul> */}
                                        <Link to="#" ><button className="btn btn-lg btn-block  btn-custom font-weight-700 border-ra4 v2">Enroll Now</button></Link>
                                      
                                    </div>
                        </div>
                                     
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Unlimited Access row */}
                    <tr>  
                      <td><span className="ptable-title">Unlimited Access</span></td>
                      <td>
                        <i className="fa fa-hdd-o red"></i>
                        500 MB
                      </td>
                      <td>
                        <i className="fa fa-hdd-o green"></i>
                        100 GB
                      </td>
                      <td>
                        <i className="fa fa-hdd-o lblue"></i>
                        500 GB
                      </td>
                      <td>
                        <i className="fa fa-hdd-o lblue"></i>
                        500 GB
                      </td>
                    </tr>
                   
                    {/* Free Demo session */}

                    <tr>
                      <td><span className="ptable-title">Free Demo session</span></td>
                      <td>
                        <i className="fa fa-signal red"></i>
                        <i className="fas fa-check-circle"></i>
                      </td>
                      <td>
                        <i className="fa fa-signal green"></i>
                        500 TB
                      </td>
                      <td>
                        <i className="fa fa-signal lblue"></i>
                        1000 TB
                      </td>
                      <td>
                        <i className="fa fa-hdd-o lblue"></i>
                        500 GB
                      </td>
                    </tr>
                    <tr>
                      <td><span className="ptable-title">Technical Support</span></td>
                      <td>
                        <i className="fa fa-shield red"></i>
                        100% Secure
                      </td>
                      <td>
                        <i className="fa fa-shield green"></i>
                        100% Secure
                      </td>
                      <td>
                        <i className="fa fa-shield lblue"></i>
                        100% Secure
                      </td>
                      <td>
                        <i className="fa fa-hdd-o lblue"></i>
                        500 GB
                      </td>
                    </tr>

                    {/* Study material */}
                    <tr>
                      <td><span className="ptable-title">Study material</span></td>
                      <td>
                        <i className="fa fa-times red"></i>
                        No Support
                      </td>
                      <td>
                        <i className="fa fa-phone green"></i>
                        Phone
                      </td>
                      <td>
                        <i className="fa fa-envelope-o lblue"></i>
                        E-mail / Phone
                      </td>
                      <td>
                        <i className="fa fa-hdd-o lblue"></i>
                        500 GB
                      </td>
                    </tr>

                      {/* Live Instructor */}
                    <tr>
                      <td><span className="ptable-title">Live Instructor</span></td>
                      <td>
                        <i className="fa fa-times red"></i>
                        No Support
                      </td>
                      <td>
                        <i className="fa fa-phone green"></i>
                        Phone
                      </td>
                      <td>
                        <i className="fa fa-envelope-o lblue"></i>
                        E-mail / Phone
                      </td>
                      <td>
                        <i className="fa fa-hdd-o lblue"></i>
                        500 GB
                      </td>
                    </tr>
                  
                    {/* <tr>
                      <td>&nbsp;</td>
                      <td className="bg-red"><a className="btn" href="#">Sign Up</a></td>
                      <td className="bg-green"><a className="btn" href="#">Sign Up</a></td>
                      <td className="bg-lblue"><a className="btn" href="#">Sign Up</a></td>
                    </tr> */}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
    
    
   );
  }
}

export default ComparisionComponent;
        
