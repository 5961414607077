const txtBoxMsg = "Please Enter ";
const ddlMsg = "Please Select ";

export const emailRegularExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const urlRegularExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const passwordRegularExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*]).{8,15}$/;
export const mobileNumberRegularExp = /^[0-9]*$/;
export const productPriceRegularExp = /^[0-9]*\.?[0-9]*$/;
export const firstNameRegularExp =   /^[a-zA-Z]*$/;
export const lastNameRegularExp =    /^[a-zA-Z]*$/;

export const errorMessage = {
    email: `${txtBoxMsg}Email`,
    emailInValid: 'Email address is not Valid',
    
    password: `${txtBoxMsg}Password`,
    passwordMinlength: "Please Enter a password of length greater than ",
    passwordMaxlength: "Password should be less than ",
    passwordInValid: "Password must have 1 of each A-Z, a-z, 0-9, special characters",

    newPassword: `${txtBoxMsg}New Password`,
    newPasswordMinlength: "Please Enter a password of length greater than ",
    newPasswordMaxlength: "Password should be less than ",
    newPasswordInValid: "Password must have 1 of each A-Z, a-z, 0-9, special characters",

    oldPassword: `${txtBoxMsg}Old Password`,
    oldPasswordMinlength: "Please Enter a password of length greater than ",
    oldPasswordMaxlength: "Password should be less than ",
    oldPasswordInValid: "Password must have 1 of each A-Z, a-z, 0-9, special characters",

    confirmPassword: `${txtBoxMsg}Confirm Password`,
    confirmPasswordMinlength: "Please Enter a password of length greater than ",
    confirmPasswordMaxlength: "Password should be less than ",
    confirmPasswordMatch: "Password should be the same",
   
    Name: `${txtBoxMsg} Name`,
    NameInValid: `${txtBoxMsg}characters only`,
    NameMinlength: "Please Enter a  Name of length greater than ",
    NameMaxlength: " Name should be less than ",
    NameInValid: `${txtBoxMsg}characters only`,

    firstName: `${txtBoxMsg}First Name`,
    firstNameMinlength: "Please Enter a First Name of length greater than ",
    firstNameMaxlength: "First Name should be less than ",
    firstNameInValid: `${txtBoxMsg}characters only`,


    lastName: `${txtBoxMsg}Last Name`,
    lastNameMinlength: "Please Enter a Last Name of length greater than ",
    lastNameMaxlength: "Last Name should be less than ",
    lastNameInValid: `${txtBoxMsg}characters only`,

    mobile: `${txtBoxMsg}Mobile Number`,
    mobileIsNumber: "Please enter Numbers only",
    mobileMinlength: "Please Enter a Mobile Number of length",
    mobileMaxlength: "Mobile number should be ",

    country: `${ddlMsg}Country`,
    course: `${ddlMsg}Course`,

    subject: `${txtBoxMsg}Subject`,
    msg: `${txtBoxMsg}Message`,
   
   
    
}

export const confirmationPopupMessages = {
    delete: "Are you sure you want to delete "
}

export const snackbarMessages = {
    deleteSuccess: "Delete Successfully !!",
    deleteError: "Error in Deletion!!",
    createSuccess: "Create Successfully !!",
    createError: "Error in Creation !!",
    activeSuccess: "Category Activated Successfully !!",
    deactiveSuccess: "Category Deactivated !!",
    userActiveSuccess: "User Activated Successfully !!",
    userDeactiveSuccess: "User Deactivated !!",
    defaultCategory: "Atleast one record should be present !!",
    locationActiveSuccess: "Location Status Changed Successfully !!",
    couponActiveSuccess: "Coupon Status Changed Successfully !!",
    roleActiveSuccess: "Role Status Changed Successfully !!",
    selectCategory: "Please Select Category !!",
    productActiveSuccess: "Product Activated Successfully !!",
    productDeactiveSuccess: "Product Deactivated !!",
    brandActiveSuccess: "Brand Activated Successfully !!",
    brandDeactiveSuccess: "Brand Deactivated !!",
}
