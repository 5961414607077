
import react, {Component} from "react";
import { Link } from 'react-router-dom';

class ContactForm2 extends Component {
render(){

    return(
        <div className="wrap-form flat-text-center" style={{background:'rgba(255,255,255,0.1)'}}>
             <p className="free"> Reach Us</p>
            <div className="heading" style={{ marginTop: '46px'}}>
                <h6 className="title" style={{color:'#fff'}}>Get In Touch </h6>
                <p className="description"  style={{color:'#fff'}}>We’re committed to changing the future of learning for the better.<br></br></p>
                <p  style={{color:'#fff'}}>Join us today and build your career in SAP.</p>
            </div>
            <div className=" contact-iconbox style1">
                <div>
                    <div className="iconbox ct-iconbox style1 left">
                        <div className="iconbox-icon">
                            <span className=""><i className="fa fa-envelope"></i></span>
                        </div>
                        <div className="iconbox-content ">
                        <ul className="no-bullets title ct">
                            <li>Drop A Mail</li>
                            <li>info@aribafieldglassonlinetrainig.com</li>
                           
                            </ul>
                        </div>
                    </div>
                </div>
             
                <div>
                    <div className="iconbox style1 left ct-iconbox">
                        <div className="iconbox-icon">
                            <span className=""><i className="fa fa-phone"></i></span>
                        </div>
                        <div className="iconbox-content ">
                            {/* <h5 className="title ct"> <span style = {{padding:'5px' }}>Call Us</span><br></br>
                                <span style = {{padding:'5px'}}>+91 840 887 8222</span><br></br>
                                <span style = {{padding:'5px' }}>  +1 678 389 8898</span>
                            </h5> */}
                            <ul className="no-bullets title ct">
                            <li>Call Us</li>
                            <li>+91 840 887 8222</li>
                            <li>+1 678 389 8898</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                
               
                
            </div>
        </div>
    );

}

}
export default ContactForm2;