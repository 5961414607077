import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { checkValidity } from "../../../Shared";
import httpService from "../../../HttpService/httpService";
import CustomizedSnackbars from "../../../Constants/config";
import { useHistory } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#1f3a68",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1f3a68",
    "&:hover": {
      background: "#1f3a68",
      color: "#f7c13a",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "theme.palette.background.paper",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
  formControl: {
    margin: "0px",
    minWidth: 120,
  },

  formField: {
    padding: "5px 0px 10px 0px",
  },
  headlogin: {
    fontSize: "1.875rem",
    fontWeight: "700",
    lineHeight: "2.25rem",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  paper: {
    margin: "14px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    padding: "20px",
  },
  root: {
    "& .MuiAutocomplete-inputRoot": {
      padding: "0px",
      height: "46px",
      background: "transperent",
      marginTop: "5px;",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: "20px",
    },
  },
  updateModal: {
    "&. hover": {
      boxShadow: "0 2px 8px #bbb",
    },
  },
}));

export default function UpdateProfile() {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  let history = useHistory();

  const [stateObj, setError] = useState({
    error: {
      firstName: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          isfirstName: true,
          fMinlength: 3,
          fMaxlength: 18,
        },
      },
      lastName: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          islastName: true,
          lMinlength: 3,
          lMaxlength: 18,
        },
      },

      mobile: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          isMobile: true,
          MobminLength: 10,
          MobmaxLength: 10,
          isNumber: true,
        },
      },
      country: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          isDropdown: true,
        },
      },
    },
    account: {
      firstName: "",
      lastName: "",
      mobile: "",
      country: null,
    },
  });

  const customerId = localStorage.getItem("customerId");
  // console.log(customerId);
  useEffect(() => {
    const { account } = stateObj;
    httpService
      .get(`/customer/${customerId}`)
      .then((res) => {
        console.log("customer result", res.data.customer[0]);
        setError({
          ...stateObj,
          account: {
            ...account,
            firstName: res.data.customer[0].first_name,
            lastName: res.data.customer[0].last_name,
            mobile: res.data.customer[0].mobile,
            country: {
              id: res.data.customer[0].country_id,
              name: res.data.customer[0].country_name,
            },
          },
        });
      })
      .catch((err) => {
        console.log("errors", err.response.data.message);
      });
  }, []);

  useEffect(() => {
    httpService
      .get("/countries")
      .then((res) => {
        setCountries(res.data.countries[0]);
      })
      .catch((err) => err);
  }, []);

  const changeHandler = (e) => {
    const { value, name } = e.target;
    const { error, account } = stateObj;
    const validationObj = error[name];
    const { errorObject } = checkValidity(value, name, validationObj);
    setError({
      ...stateObj,
      error: { ...error, [name]: { errorObject } },
      account: { ...account, [name]: value },
    });
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  const onDropdownChangeHandler = (cntrlName, value) => {
    const { error, account } = stateObj;
    const validationObj = error[cntrlName];
    const id = value === null ? 0 : value.id;
    const { errorObject } = checkValidity(id, cntrlName, validationObj);

    setError({
      ...stateObj,
      error: { ...error, [cntrlName]: { errorObject } },
      account: { ...account, [cntrlName]: value },
    });
  };

  const onSubmitHandler = () => {
    const { error, account } = stateObj;
    let result = true;
    for (let err in error) {
      const value = account[err];
      const name = err;
      const validationObj = error[name];
      const { errorObject } = checkValidity(value, name, validationObj);
      if (errorObject.errorMessage !== "") {
        result = false;
      }
      error[name] = { errorObject };
    }
    setError({ account, error: error });

    if (result === true) {
      httpService
        .put(`/customer/${customerId}`, {
          firstName: account.firstName,
          lastName: account.lastName,

          mobile: account.mobile,
          countryId: account.country.id,
        })
        .then((res) => {
          console.log("success", res);
          console.log(res.data);
          setSeverity("sucess");
          setMessage(res.data.message);
          setSnackbar(true);

          //history.push("/my-account/my-profile");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          setSeverity("warning");
          setMessage(err.response.data.message);
          setSnackbar(true);
        });
    }
  };

  return (
    <div className="updateModal">
      <Container>
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h2" variant="h2" className={classes.headlogin}>
            Edit Profile
          </Typography>

          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formField}
                  // autoComplete="off"
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  id="firstname"
                  label="First Name"
                  value={stateObj.account.firstName}
                  autoFocus
                  onChange={changeHandler}
                  error={!stateObj.error.firstName.errorObject.isValid}
                  helperText={stateObj.error.firstName.errorObject.errorMessage}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formField}
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  value={stateObj.account.lastName}
                  //autoComplete="off"
                  onChange={changeHandler}
                  error={!stateObj.error.lastName.errorObject.isValid}
                  helperText={stateObj.error.lastName.errorObject.errorMessage}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  className={classes.formField}
                  variant="outlined"
                  required
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  value={stateObj.account.mobile}
                  autoComplete="off"
                  onChange={changeHandler}
                  error={!stateObj.error.mobile.errorObject.isValid}
                  helperText={stateObj.error.mobile.errorObject.errorMessage}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  className={classes.root}
                  onChange={(event, newValue) => {
                    onDropdownChangeHandler("country", newValue);
                  }}
                  id="country-select-demo"
                  options={countries}
                  value={stateObj.account.country}
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!stateObj.error.country.errorObject.isValid}
                      helperText={
                        stateObj.error.country.errorObject.errorMessage
                      }
                      label="Select Country"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "off", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button
              // type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={onSubmitHandler}
            >
              Update
            </Button>

            <CustomizedSnackbars
              open={snackbar}
              closeSnackbar={closeSnackbar}
              message={message}
              severity={severity}
            />
          </form>
        </div>
      </Container>
    </div>
  );
}
