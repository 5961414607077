import React, { Component } from 'react';

class Paramater extends Component {
    render() {
        return (
            <div className="row flat-iconbox style1">
                <div className="col-lg-4 col-md-4">
                    <div className="iconbox style1 left">
                        <div className="iconbox-icon">
                        <span ><i class="fas fa-server"></i></span>
                        </div>
                        <div className="iconbox-content">
                            <h5 className="title">Server access</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="iconbox style1 center">
                        <div className="iconbox-icon">
                        <span><i class="fas fa-cog"></i></span>
                        </div>
                        <div className="iconbox-content">
                            <h5 className="title">24x7 technical support</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="iconbox style1 right">
                        <div className="iconbox-icon" style={{left:'24px'}}>
                        <span><i class="fas fa-desktop"></i></span>
                        </div>
                        <div className="iconbox-content">
                            <h5 className="title">Absolutely free demo</h5>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Paramater;