import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class ContentSingle extends Component {
    render() {
        return (
            <div className="content-wrap  courses-single-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="content-page-wrap">
                                <div className="course-single">
                                    <div className="img-single">
                                        <Link to="#"><img src="images/courses/img-Details.jpg" alt="bookflare" /></Link>
                                    </div>
                                    <div className="content">
                                        <div className="heading">
                                            <h6 className="title"><Link to="#">After Effects CC 2018: Complete Course from Novice to
                                                    Expert</Link></h6>
                                            <p className="description">Lorem Ipsn gravida nibh velalito auctor alipuet. Aenean
                                                sollicitudin, lorem quis bibendum auci elit conse ipsutis sem nibh id elit. Duis sed
                                                odio sit amet nibh vulputate cursus.</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="price-wrap">
                                                    <span className="sale-price color-f3728b">$19.99</span>
                                                    <span className="price"> $199.99</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="wrap-cart flat-text-right">
                                                    <Link to="#" className="btn-cart">Add To Cart</Link>
                                                    <Link to="#" className="flat-button btn-buy border-ra4 float-right">BUY NOW</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="author border-t-e6f3ff">
                                            <div className="author-avata">
                                                <img src="images/events/author-teacher.jpg" alt="bookflare" />
                                            </div>
                                            <div className="author-info">
                                                <h6 className="name"><Link to="#">Charlotte H. Bronk</Link></h6>
                                                <div className="wrap-list">
                                                    <ul className="list meta-list">
                                                        <li>
                                                            <span className="title">Categories:</span>
                                                            <Link to="#"><span> Software </span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#"><span>49,997</span></Link>
                                                            <span className="title">Students Enrolled</span>
                                                        </li>
                                                    </ul>
                                                    <ul className="list meta-rate">
                                                        <li>
                                                            <ul className="list star-list">
                                                                <li><i className="far fa-star" /></li>
                                                                <li><i className="far fa-star" /></li>
                                                                <li><i className="far fa-star" /></li>
                                                                <li><i className="far fa-star" /></li>
                                                                <li><i className="far fa-star" /></li>
                                                            </ul>
                                                            <span className="font-weight-700">5.0 (980) </span>
                                                        </li>
                                                        <li>
                                                            <span className="font-weight-700"> Sep 07, 2018</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> {/* /.course-single */}
                                <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff">
                                    <ul className="tab-title style1 clearfix border-bt-e6f3ff">
                                        <li className="item-title  overview">
                                            <span className="inner">OVERVIEW</span>
                                        </li>
                                        <li className="item-title event">
                                            <span className="inner">DESCRIPTION</span>
                                        </li>
                                        <li className="item-title requirements">
                                            <span className="inner">REQUIREMENTS</span>
                                        </li>
                                        <li className="item-title review">
                                            <span className="inner">REVIEW</span>
                                        </li>
                                    </ul>
                                    <div className="tab-content-wrap">
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="text-wrap img-right clearfix">
                                                    <div className="img-single-small flat-text-center">
                                                        <img src="images/events/img-small.png" alt="bookflare" />
                                                    </div>
                                                    <h6 className="title"><Link to="#">Limitless Learning, Limitless
                                                            Possibilities</Link></h6>
                                                    <p className="text">Lorem Ipsn gravida nibh vel velit auctor aliquet. Aenean
                                                        sollicitudin, lorem quis bibendum auci elit consequat ipsutis sem nibh id
                                                        elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi
                                                        accum dolor same mipsum rvelit. </p>
                                                    <h6 className="title"><Link to="#">About This Course</Link></h6>
                                                    <p className="text">Per inceptos himenaeos. Mauris in erat justo. Nullam ac urna
                                                        eu felis dapibus condimentum sit amet a augue. Sed non mauris vitae erat
                                                        consequat auctor eu in elit. Class aptent tacitio sociosqu ad litora
                                                        torquent.</p>
                                                </div>
                                                <div className="text-wrap img-left clearfix">
                                                    <div className="img-single-small flat-text-center">
                                                        <img src="images/courses/img-small.png" alt="bookflare" />
                                                    </div>
                                                    <h6 className="title"> Here some of the techniques you will Learn:</h6>
                                                    <p className="text">Lorem Ipsn gravida nibh vel velit auctor aliquet sollic
                                                        elorem quis bibendum auci elit conseo.</p>
                                                    <ul className="list-skill">
                                                        <li> Top-secret tricks for shortcut enthusiasts</li>
                                                        <li> Assigning and converting color profiles</li>
                                                        <li> Using Smart Filters for creative effects</li>
                                                        <li> Turning a cityscape into a tiny planet</li>
                                                        <li> Hunting down seams with the Offset filter</li>
                                                        <li> Using blur and noise to focus attention &amp; simulate motion</li>
                                                    </ul>
                                                </div>
                                                <div className="text-wrap">
                                                    <h6 className="title"><Link to="#">Learn and Master Motion Graphics and Visual
                                                            Effects in After Effects</Link></h6>
                                                    <p className="text">Contrary to popular belief, Lorem Ipsum is not simply random
                                                        text. It has roots in a piece of classical Latin literature from 45 BC,
                                                        making it over 2000 years old. Richard McClintock, a Latin professor at
                                                        Hampden-Sydney College in Virginia, looked up one of the more obscure Latin
                                                        words, consectetur, from a Lorem Ipsum passage, and going through the cites
                                                        of the word in classical literature, discovered the undoubtable source.
                                                        Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum
                                                        et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.
                                                        This book is a treatise on the theory of ethics, very popular during the
                                                        Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..",
                                                        comes from a line in section 1.10.32.</p>
                                                    <p className="text"> The standard chunk of Lorem Ipsum used since the 1500s is
                                                        reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de
                                                        Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact
                                                        original form, accompanied by English versions from the 1914.</p>
                                                </div>
                                                <div className="wrap-checkmark-tag clearfix">
                                                    <div className="tags one-of-two">
                                                        <Link to="#" className="tag-item bg-7ffe53">Design</Link>
                                                        <Link to="#" className="tag-item bg-fe5367">After Effect</Link>
                                                        <Link to="#" className="tag-item bg-536dfe">Software</Link>
                                                    </div>
                                                    <div className="checkmark one-of-two flat-text-right">
                                                        <span>Was this review helpful?</span>
                                                        <Link to="#" className="btn-yes">Yes</Link>
                                                        <Link to="#" className="btn-no">No</Link>
                                                        <span className="report">Report</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="img-single-small flat-text-center">
                                                    <img src="images/events/img-small.png" alt="bookflare" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h6 className="title"><Link to="#">Limitless Learning, Limitless
                                                            Possibilities</Link></h6>
                                                    <p className="text">Lorem Ipsn gravida nibh vel velit auctor aliquet. Aenean
                                                        sollicitudin, lorem quis bibendum auci elit consequat ipsutis sem nibh id
                                                        elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi
                                                        accum dolor same mipsum rvelit. </p>
                                                </div>
                                                <div className="text-wrap">
                                                    <h6 className="title"><Link to="#">About This Event</Link></h6>
                                                    <p className="text">Per inceptos himenaeos. Mauris in erat justo. Nullam ac urna
                                                        eu felis dapibus condimentum sit amet a augue. Sed non mauris vitae erat
                                                        consequat auctor eu in elit. Class aptent tacitio sociosqu ad litora
                                                        torquent.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <h1>No Review</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="sidebar-right">
                                <div className="widget-subscribe flat-text-center">
                                    <h4 className="widget-title ">Subscribe Newslatter </h4>
                                    <p className="text">Netus et malesuad fames ac tmgest blandi cursus risuar etiam non puam lacus.
                                    </p>
                                    <p className="over font-Freestyle">Over </p>
                                    <p className="wrap-over"><span className="count  font-Freestyle">20,640</span> <span
                                            className="free"> Free Tutorial</span></p>
                                    <div className="wrap-description">
                                        <p className="description">Develop in-demand skills with access to thousands of expert-led
                                            courses on business, tech and creative topics.</p>
                                    </div>
                                    <div className="wrap-form-subscribe">
                                        <form>
                                            <input type="email" name="email" placeholder="Enter your Email" />
                                            <button className="btn-send flat-button">SEND</button>
                                        </form>
                                    </div>
                                </div>
                                <div className="flat-courses style3">
                                    <div className="course style3  text-fb868f ">
                                        <div className="course-border border-f-e6f3ff border-ra4 transition-vline">
                                            <div className="course-img img-vline">
                                                <Link to="#"><img src="images/sidebar/course-sidaber-1.jpg" alt="bookflare" /></Link>
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                            <div className="course-content">
                                                <div className="text-wrap border-bt-e6f3ff">
                                                    <p className="teacher"><Link to="#">Erika P. Sera</Link></p>
                                                    <h6 className="title"><Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>Complete Java Masterclass</Link></h6>
                                                    <div className="wrap-rating">
                                                        <ul className="list star-list">
                                                            <li><i className="far fa-star" /></li>
                                                            <li><i className="far fa-star" /></li>
                                                            <li><i className="far fa-star" /></li>
                                                            <li><i className="far fa-star" /></li>
                                                            <li><i className="far fa-star-half" /></li>
                                                        </ul>
                                                        <span>4.7 (2,632)</span>
                                                    </div>
                                                    <span className="price v2">$44.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="course style3  text-2ebd59   ">
                                        <div className="course-border border-f-e6f3ff border-ra4 transition-vline">
                                            <div className="course-img img-vline">
                                                <Link to="#"><img src="images/sidebar/course-sidaber-2.jpg" alt="bookflare" /></Link>
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                            <div className="course-content">
                                                <div className="text-wrap border-bt-e6f3ff">
                                                    <p className="teacher"><Link to="#">Charlotte H. Bronk</Link></p>
                                                    <h6 className="title"><Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>Node.js Developer Course</Link></h6>
                                                    <div className="wrap-rating">
                                                        <ul className="list star-list">
                                                            <li><i className="far fa-star" /></li>
                                                            <li><i className="far fa-star" /></li>
                                                            <li><i className="far fa-star" /></li>
                                                            <li><i className="far fa-star" /></li>
                                                            <li><i className="far fa-star" /></li>
                                                        </ul>
                                                        <span>5.0 (980)</span>
                                                    </div>
                                                    <span className="price v2">FREE</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
            }
}

export default ContentSingle;