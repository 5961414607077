import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class InforComponent extends Component {
    render() {
        const linkClickHandle = () => {
            window.scrollTo({ top: 0 });
            window.location.href='/contact-us';
          }
        return (
            <div className="container">
                <div className="content-page-wrap">
                    <div className="flat-about-info">
                        <div className="info-img border-ra4">
                            <Link to="#"><img src="images/about/diving-innovation1.png" alt="bookflare" /></Link>
                        </div>
                        <div className="info-content border-ra4 border-f-e6f3ff">
                            <div className="heading">
                                <h6 className="title-small"> Welcome to, </h6>
                                <h3 className="title"><Link to="#">Online Ariba Fieldglass Training</Link></h3>
                            </div>
                            <p className="text">Hello, Online  Ariba Fieldglass  is a Leading Online SAP Training Provider since 2020. It is one of the best SAP  Ariba Fieldglass Online Training Institute, which provides High-Quality Industry Level Training with Real Time Projects. </p>
                            <p className="text">We are certified company imparting IT training from basic to advanced technologies.We provide training by working professionals who are having 8-10 years of industry experience, who guide the candidate with the best knowledge and work culture of most SAP industry.</p>
                            <p className="text inherit">Lear SAP from industry experts. We are known as one of the best SAP Online Training Institutes with a clear vision of imparting Quality Education and Training among students.</p>
                            <p className="text">With the dawn of the twentieth century, the Company handed over the Institute to the
                            The courses are created by seasoned SAP Experts and contain videos, , step by step guides, Real-time projects, Server access that will certainly help you. The training would be accompanied by live project and full technical support, which makes the candidates ready to be absorbed in the industry.</p>
                            <div className="wrap-btn">
                                <Link  to="#" onClick={linkClickHandle} className="flat-button btn-contact ">CONTACT US</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InforComponent;