import { addToCartContants } from '../actions/constants';

const initState = {
  cartData: [],
};
export default (state = initState, action) => {
  switch (action.type) {
    case addToCartContants.ADD_TO_CART_REQUEST:
      state = {
        ...state,
        cartData: action.payload.carts,
      };
      break;
    default:
      break;
  }
  return state;
};

/* 
Reducer return the state of the application
*/
