
import React, {useState} from 'react';
import { Avatar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { checkValidity } from '../../../Shared';
import httpService from '../../../HttpService/httpService';
import CustomizedSnackbars from '../../../Constants/config';
import { useHistory } from "react-router-dom"
const useStyles = makeStyles((theme) => ({
    // root: {
    //   height: '100vh',
    // },
    image: {
      backgroundImage: 'url(../images/register/bg-register-sl.jpg)',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#cbdae1',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: 'auto',
      flexGrow: 1,
    },
    paper: {
      margin: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      // margin: theme.spacing(1),
      backgroundColor: '#1f3a68',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      background: '#1f3a68',
      '&:hover': {
        background: '#1f3a68',
        color: '#f7c13a',
      },
    },
    mainGrid: {
      backgroundColor: 'transperent',
      margin: '25px 0 25px 0',
    },
    formField: {
      padding: '5px 0px 10px 0px',
    },
    headlogin:{
      fontSize: '1.875rem',
  fontWeight: '700',
  lineHeight: '2.25rem'
  }
  }));
export default function HomeForm() {
const classes=useStyles();
    return(
  <Grid
      container
      component="main"
      className={classes.image}
      justifyContent="center"
    >
    
<Grid classes="overlay"></Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        className={classes.mainGrid}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h2" variant="h2" className={classes.headlogin}>
          Sign in to your account
          </Typography>
          <form className={classes.form} noValidate >
            <TextField
              className={classes.formField}
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              //autoComplete="off"
              autoFocus
              
            />
            
          </form>
        </div>
      </Grid>
    </Grid>
    );

}
