import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid, IconButton, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import httpService from '../../../HttpService/httpService';
import { CartContext } from '../layout/layout';
import CustomizedSnackbars from '../../../Constants/config';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    margin: '2%',
    marginBottom: '2%',
    marignLeft: '0',
    height: 'auto',
    borderRadius: '0px',
    [theme.breakpoints.up('sm')]: {
      padding: '5px',
      marginTop: '8%',
    },
  },
  checkoutContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      maxWidth: '84%',
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    textDecoration: ' line-through',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pos: {
    marginBottom: 12,
  },
  totalPrice: {
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'space-between',
    fontFamily: `'Abril Fatface', cursive`,
    textTransform: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  totalSaving: {
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'space-between',
    fontFamily: `'Abril Fatface', cursive`,
    textTransform: 'none',
  },
  button: {
    background: '#1F3A68',
    color: 'white',
    position: 'relative',
    width: '100%',
    height: '45px',
    marginTop: '10px',
    marginBottom: '10px',
    fontFamily: `'Abril Fatface', cursive'`,
    fontSize: '15px',
    textTransform: 'none',
    '&:hover': {
      background: '#1F3A68',
      color: 'white',
    },
    '&.MuiButton-text': {
      borderRadius: '0px',
    },
  },
  cardButton: {
    marginTop: '10px',
    marginBottom: '10px',
    padding: '0',
  },
  applyCouponIcon: {
    display: 'flex',
    marginTop: '10px',
  },
  clearIconButton: {
    height: '5px',
    width: '5px',
    color: 'black',
  },
  couponCodeText: {
    color: 'gray',
    fontFamily: `'Abril Fatface', cursive`,
    textTransform: 'none',
    fontSize: '15px',
    marginLeft: '5px',
    [theme.breakpoints.only('sm')]: {
      margin: '0px',
    },
  },
  searchField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    borderRadius: '0px',
    minHeight: '40px',
    marginTop: '5px',
  },
  couponField: {
    marginTop: '5px',
    width: '80%',
    maxHeight: '23px',
    borderRadius: '0px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
      height: '44px',
    },
  },
  couponBotton: {
    marginTop: '5px',
    flexGrow: '1',
    background: '#1F3A68',
    color: 'white',
    borderRadius: '0px',
    minHeight: '40px',
    '&:hover': {
      background: '#1F3A68',
      color: 'white',
    },
  },
  discountPrice: {
    fontSize: '18px',
    fontFamily: 'inherit',
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));

export default function CheckoutComponent(props) {
  const { token, cartdata, cartDataBeforeLogin } =
    useContext(CartContext) || {};
  const classes = useStyles();
  const [coupon, setCoupon] = useState();
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [price, setPrice] = useState(0);
  const [saving, setSaving] = useState(0);
  const [couponApplied, setCouponApplied] = useState(false);
  const [payment, setPayment] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [signature, setSignature] = useState('');

  const data = token ? cartdata : cartDataBeforeLogin;

  const totalDiscount = data.reduce((acc, curr) => {
    acc = acc + curr.discount;
    return acc;
  }, 0);

  const totalPrice = data.reduce((acc, curr) => {
    acc = acc + curr.price;
    return acc;
  }, 0);

  const totalSaving = (totalDiscount / totalPrice) * 100;

  useEffect(() => {
    setPrice(totalPrice);
    setDiscount(totalDiscount);
    setSaving(totalSaving ? totalSaving.toFixed(2) : 0);
  }, [totalDiscount, totalPrice, totalSaving]);

  const applyCoupon = () => {
    httpService
      .post('/customer/apply/coupon', { couponCode: coupon })
      .then((res) => {
        setPrice(price - res.data.coupon[0].coupon_discount);
        setCouponApplied(true);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setSeverity('error');
        setOpen(true);
      });
  };

  const handleCheckout = () => {
    httpService
      .post('/checkout', {
        amount: price,
        currency: 'INR',
        receipt: 'test#123',
        notes: { desc: 'Select Payment Method' },
      })
      .then((res) => {
        const options = {
          key: 'rzp_test_zVLIP9Go4VNtOu',
          amount: res.data.order.amount,
          currency: res.data.order.currency,
          name: 'FIELDGLASS ARIBA',
          description: res.data.order.notes.desc,
          image: 'https://example.com/your_logo',
          order_id: res.data.order.id,
          handler: function (response) {
            setPaymentId(response.razorpay_payment_id);
            setOrderId(response.razorpay_order_id);
            setSignature(response.razorpay_signature);
            setPayment(true);
          },
          prefill: {
            name: 'Irfan Ansari',
            email: 'irfanas542@gmail.com',
            contact: '9999999999',
          },
          notes: {
            address: 'Razorpay Corporate Office',
          },
          theme: {
            color: '#3399cc',
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on('payment.failed', function (response, error) {
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  const revokeCoupon = () => {
    setCouponApplied(false);
  };

  return (
    <Card className={classes.root}>
      <CustomizedSnackbars
        open={open}
        onClose={closeSnackbar}
        severity={severity}
        message={message}
      />
      <Grid container className={classes.checkoutContainer}>
        <Grid item xs={12} sm={11} md={12} className={classes.discountPrice}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {discount}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={11} md={12} className={classes.totalPrice}>
          <Typography variant="body2" component="h5">
            SubTotal:
          </Typography>
          <Typography>
            <b>{price}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={11} md={12} className={classes.totalSaving}>
          <Typography variant="body2" component="p">
            Total Savings ({saving})%:
          </Typography>
          <Typography>{discount}</Typography>
        </Grid>
        <Grid item xs={12} sm={11} md={12} className={classes.totalSaving}>
          <Typography variant="body2" component="p">
            Tax:
          </Typography>
          <Typography>18%</Typography>
        </Grid>
        <Grid item xs={12} sm={11} md={12} className={classes.totalSaving}>
          <Typography variant="h5" component="h2">
            Total:
          </Typography>
          <Typography>
            <b>{price + (price * 18) / 100}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={11} md={12} className={classes.cardButton}>
          <Button
            id="rzp-button1"
            className={classes.button}
            onClick={handleCheckout}
          >
            Checkout
          </Button>
        </Grid>
        <Grid item xs={5} sm={11} md={12}>
          <Typography>
            <b>Promotions</b>
          </Typography>
        </Grid>
        {couponApplied === true ? (
          <Grid
            item
            xs={12}
            sm={11}
            md={12}
            className={classes.applyCouponIcon}
          >
            <IconButton
              className={classes.clearIconButton}
              onClick={revokeCoupon}
            >
              <ClearIcon />
            </IconButton>
            <Typography className={classes.couponCodeText}>
              LV2LRNCP80921B
            </Typography>
            <Typography className={classes.couponCodeText}>
              {couponApplied === true ? 'is applied' : ''}
            </Typography>
          </Grid>
        ) : (
          ''
        )}
        {couponApplied === false ? (
          <Grid item xs={12} sm={11} md={12} className={classes.searchField}>
            <TextField
              className={classes.couponField}
              name="couponCode"
              variant="outlined"
              margin="dense"
              fullWidth
              rows={2}
              id="couponCode"
              autoFocus
              onChange={(e) => setCoupon(e.target.value)}
            />
            {
              <Button
                className={classes.couponBotton}
                onClick={couponApplied ? null : applyCoupon}
              >
                apply
              </Button>
            }
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Card>
  );
}
