import React, { Component, useState } from 'react';
import { checkValidity } from '../../../Shared';
import httpService from '../../../HttpService/httpService';
import CustomizedSnackbars from '../../../Constants/config';

import { Button, TextField, Grid, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#1f3a68',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#1f3a68',
    '&:hover': {
      background: '#1f3a68',
      color: '#f7c13a',
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'theme.palette.background.paper',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  formControl: {
    margin: '0px',
    minWidth: 120,
  },

  formField: {
    padding: '5px 0px 10px 0px',
    '& .MuiFormLabel-root': {
      // color: "#c2c2c2",
    },
  },
  headlogin: {
    fontSize: '1.875rem',
    fontWeight: '700',
    lineHeight: '2.25rem',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '0px',
      height: '46px',
      background: 'transperent',
      marginTop: '4px;',
    },
    '& .MuiOutlinedInput-root': {
      paddingLeft: '20px',
    },
  },
  country: {
    '& .MuiFormLabel-root': {
      // color: "#c2c2c2",
    },
  },
  // d_snackbar: {
  //   "& .MuiSnackbar-anchorOriginTopRight": {
  //     top: "0px",
  //     left: "auto",
  //     right: "24px",
  //     position: "relative",
  //     paddingBottom: "35px",
  //   },
  // },
}));

export default function ContactForm() {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const [stateObj, setError] = useState({
    error: {
      Name: {
        errorObject: {
          required: true,
          errorMessage: '',
          isValid: true,
          isName: true,
          nMinlength: 3,
          nMaxlength: 18,
        },
      },

      email: {
        errorObject: {
          required: true,
          errorMessage: '',
          isValid: true,
          isEmail: true,
        },
      },
      subject: {
        errorObject: {
          required: true,
          errorMessage: '',
          isValid: true,
          // isSubject: true,
        },
      },
      msg: {
        errorObject: {
          required: true,
          errorMessage: '',
          isValid: true,
          // isMsg: true,
        },
      },
    },
    account: {
      Name: '',
      email: '',
      subject: '',
      msg: '',
    },
  });

  const changeHandler = (e) => {
    const { value, name } = e.target;
    const { error, account } = stateObj;
    const validationObj = error[name];
    const { errorObject } = checkValidity(value, name, validationObj);
    setError({
      ...stateObj,
      error: { ...error, [name]: { errorObject } },
      account: { ...account, [name]: value },
    });
  };
  const closeSnackbar = () => {
    setSnackbar(false);
  };

  const onSubmitHandler = () => {
    const { error, account } = stateObj;
    let result = true;
    for (let err in error) {
      const value = account[err];
      const name = err;
      const validationObj = error[name];
      const { errorObject } = checkValidity(value, name, validationObj);
      if (errorObject.errorMessage !== '') {
        result = false;
      }
      error[name] = { errorObject };
    }
    setError({ account, error: error });

    // httpService
      // .post('/contactus/details', {
      //   name: account.Name,
      //   email: account.email,
      //   subject: account.subject,
      //   message: account.msg,
      // })
      // .then((res) => {
      //   console.log('result', res.data.message);
      //   setSeverity("success");
      //   setMessage(res.data.message);
      //   setSnackbar(true);
      // })
      // .catch((err) => {
      //   console.log(err);
      //   setSeverity("warning");
      //   setMessage(err.response.data.message);
      //   setSnackbar(true);
      // });
if(result===true){
      httpService
      .post("/contactus/details", {
        name: account.Name,
        email: account.email,
        subject: account.subject,
        message: account.msg,
      })
      .then((res) => {
        console.log('contactus',res);
        

        setSeverity("success");
        setMessage(res.data.message);
        setSnackbar(true);

        // history.push("/my-account");
        // window.location.reload();
        setTimeout(() => {
       //   history.push("/my-account");
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setSeverity("warning");
        setMessage(err.response.data.message);
        setSnackbar(true);
      });
    }
  };
  return (
    <div className="wrap-form flat-text-center">
      <p className="free" style={{ color: '#fff' }}>
        Fillup The Form
      </p>
      <div className="heading">
        <h6 className="title">For any query feel free to contact us </h6>
        {/* <p className="description">Stay Sharp. Get ahead with Learning Paths.</p> */}
      </div>
      <form className={classes.form} noValidate autoComplete="off">
        <Grid container spacing={2}>
        <CustomizedSnackbars
              open={snackbar}
              closeSnackbar={closeSnackbar}
              message={message}
              severity={severity}
            />
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.formField}
              // autoComplete="off"
              name="Name"
              variant="outlined"
              fullWidth
              id="firstName"
              label="Name"
              required
              onChange={changeHandler}
              error={!stateObj.error.Name.errorObject.isValid}
              helperText={stateObj.error.Name.errorObject.errorMessage}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.formField}
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              // autoComplete="off"
              onChange={changeHandler}
              error={!stateObj.error.email.errorObject.isValid}
              helperText={stateObj.error.email.errorObject.errorMessage}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              className={classes.formField}
              variant="outlined"
              required
              fullWidth
              id="Subject"
              label="Subject"
              name="subject"
              //  autoComplete="off"
              onChange={changeHandler}
              error={!stateObj.error.subject.errorObject.isValid}
              helperText={stateObj.error.subject.errorObject.errorMessage}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              className={classes.root}
              id="answer"
              name="msg"
              label="Message"
              type="text"
              variant="outlined"
              multiline={true}
              rows={5}
              required
              fullWidth
              onChange={changeHandler}
              error={!stateObj.error.msg.errorObject.isValid}
              helperText={stateObj.error.msg.errorObject.errorMessage}
            />
            {/* <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              label="Message"
              style={{ border: '1px solid #c2bcbc' }}
              name="msg"
              onChange={changeHandler}
              error={!stateObj.error.msg.errorObject.isValid}
              helperText={stateObj.error.msg.errorObject.errorMessage}
            /> */}
          </Grid>
        </Grid>
        <Button
          // type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmitHandler}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}
