import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class SlideTestimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSlider: [
                {
                    id: 1,
                    classnames: 'testimonial clearfix',
                    src:'images/testimonials/avata-index-2.png',
                    names: 'Maria Ustinova',
                    positions: 'Teacher Tranining',
                    blockquotes: 'Best Rate helped our business increase incoming cash flows. Since it supports a vast number of cryptocurrencies and fiat money options, it’s impossible to not like it.'
                },
                {
                    id: 2,
                    classnames: 'testimonial clearfix top-110',
                    src:'images/testimonials/avata-index-1.png',
                    names: 'Roman Boyko',
                    positions: 'Web Developer',
                    blockquotes: 'Best Rate helped our business increase incoming cash flows. Since it supports a vast number of cryptocurrencies and fiat money options, it’s impossible to not like it.'
                },
                {
                    id: 3,
                    classnames: 'testimonial clearfix',
                    src:'images/testimonials/avata-index-2.png',
                    names: 'Maria Ustinova',
                    positions: 'Teacher Tranining',
                    blockquotes: 'Best Rate helped our business increase incoming cash flows. Since it supports a vast number of cryptocurrencies and fiat money options, it’s impossible to not like it.'
                },
                {
                    id: 4,
                    classnames: 'testimonial clearfix top-110',
                    src:'images/testimonials/avata-index-1.png',
                    names: 'Roman Boyko',
                    positions: 'Web Developer',
                    blockquotes: 'Best Rate helped our business increase incoming cash flows. Since it supports a vast number of cryptocurrencies and fiat money options, it’s impossible to not like it.'
                },
                {
                    id: 5,
                    classnames: 'testimonial clearfix',
                    src:'images/testimonials/avata-index-2.png',
                    names: 'Maria Ustinova',
                    positions: 'Teacher Tranining',
                    blockquotes: 'Best Rate helped our business increase incoming cash flows. Since it supports a vast number of cryptocurrencies and fiat money options, it’s impossible to not like it.'
                },
                {
                    id: 6,
                    classnames: 'testimonial clearfix top-110',
                    src:'images/testimonials/avata-index-1.png',
                    names: 'Roman Boyko',
                    positions: 'Web Developer',
                    blockquotes: 'Best Rate helped our business increase incoming cash flows. Since it supports a vast number of cryptocurrencies and fiat money options, it’s impossible to not like it.'
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="flat-carousel" data-column={2} data-column2={1} data-loop="true" data-column3={1} data-gap={30}
                data-dots="true" data-nav="false">
                <div className="wrap-testimonials clearfix owl-carousel">
                    {
                        this.state.dataSlider.map(data => (
                            <div className={data.classnames} key={data.id}>
                                <div className="wrap-avata-socails flat-text-center">
                                    <div className="avata">
                                        <img src={data.src} alt="bookflare" />
                                    </div>
                                    <div className="socails">
                                        <ul className="list">
                                            <li><Link to="#"><i className="fab fa-instagram" /></Link></li>
                                            <li><Link to="#"><i className="fab fa-skype" /></Link></li>
                                            <li><Link to="#"><i className="fab fa-github" /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wrap-content">
                                    <div className="info">
                                        <h4 className="name">{data.names}</h4>
                                        <p className="position">{data.positions}</p>
                                    </div>
                                    <blockquote>
                                        {data.blockquotes}
                                    </blockquote>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default SlideTestimonial;