
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  makeStyles,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    subform: {
      padding: "10px",
      border: "1px solid #d9d2d2",
      marginTop: "10px",
      marginBottom: "10px",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      background: "#1f3a68",
      "&:hover": {
        background: "#1f3a68",
        color: "#f7c13a",
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "theme.palette.background.paper",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
    },
    formControl: {
      margin: "0px",
      minWidth: 120,
    },
  
    formField: {
      padding: "5px 0px 10px 0px",
    },
    headlogin: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "2.25rem",
      paddingBottom: "20px",
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
    },
    root: {
      "& .MuiAutocomplete-inputRoot": {
        padding: "0px",
        height: "46px",
        background: "transperent",
        marginTop: "5px;",
      },
      "& .MuiOutlinedInput-root": {
        paddingLeft: "20px",
      },
    },
    order:{
      display:'flex',
    }
  }));
export default function CheckouSummary() {
    const classes = useStyles();
  return (
    
      <Grid container spacing={2} className="check-form2">
           <Typography
                  component="h2"
                  variant="h2"
                  className={classes.headlogin}
                >
                  Order Details
                </Typography>
                <Grid container spacing={2}>
              <Grid item xs={12} sm={3} xl={3}  className={classes.order}>
              <img
                  src="images/logo/ariba-feildglass-logo.png"
                  alt="bookflare"
                  width={157}
                  height={29}
                  data-retina="images/logo/ariba-feildglass-logo.png"
                  data-width={157}
                  data-height={29}
                />

              </Grid>
              <Grid item xs={12} sm={3} xl={3}>
              <Typography>
              Self Paced
Training
              </Typography>

              </Grid>
              </Grid>
      </Grid>
   
  );
}
