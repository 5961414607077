import React, { Component } from 'react';
import { Link } from 'react-router-dom'
class RelatedProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataShop : [
                {
                    id: 1,
                    srcimg: 'images/shop/product-1.jpg',
                    title: 'Accessibility for Everyone',
                    pricesale: '$19.99',
                    price: '$99.99',
                },
                {
                    id: 2,
                    srcimg: 'images/shop/product-2.jpg',
                    title: 'Just Enough Research',
                    pricesale: '$26.99',
                    price: '$54.99',
                },
                {
                    id: 3,
                    srcimg: 'images/shop/product-3.jpg',
                    title: 'Color Accessibility Workflows',
                    pricesale: '$58.99',
                    price: undefined,
                }
            ]
        }
    }
    
    render() {
        return (
            <section className="flat-related-products shop-single">
                <div className="container">
                    <div className="flat-title flat-text-center">
                        <h2 className="title">Related Products</h2>
                        <p className="description">Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                            the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                            and scrambled it to make a type specimen book.</p>
                    </div>
                    <div className="row">
                        {
                            this.state.dataShop.map( data => (
                                <div className="col-lg-4 col-md-12" key={data.id}>
                                    <div className="product-item transition-img-socails border-f-e6f3ff">
                                        <div className="product-img transition-img">
                                            <img src={data.srcimg} alt="bookflare" />
                                            <div className="overlay" />
                                            <div className="socails">
                                                <ul className="list">
                                                    <li><Link to="#"><span className="icon-search" /></Link></li>
                                                    <li><Link to="#"><span className="icon_heart_alt" /></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-content flat-text-center border-bt-e6f3ff">
                                            <h6 className="title"><Link to="/shop-single" onClick={() => {window.location.href="/shop-single"}}>{data.title}</Link></h6>
                                            <div className="price-wrap">
                                                <span className="sale-price">{data.pricesale}</span><span className="price">{data.price}</span>
                                            </div>
                                        </div>
                                        <div className="wrap-btn flat-text-center">
                                            <Link to="#" className="flat-button btn-cart">ADD TO CART</Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default RelatedProduct;