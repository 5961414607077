import React, { Component } from 'react';
import { Link } from 'react-router-dom'
class SingleBlogComponent extends Component {
render() {
    return (
        <div className="col-lg-8 col-md-12">
            <div className="content-page-wrap">
                <div className="post-single border-bt-f0f0f0">
                    <div className="img-single">
                        <img src="images/blog/blog-single.jpg" alt="bookflare" />
                    </div>
                    <div className="content-single">
                        <h3 className="title">
                            <Link to="#">Learning, friendship and fun for everyone</Link>
                        </h3>
                        <div className="list-meta">
                            <ul className="list">
                                <li className="time">20.10.2019</li>
                                <li className="counter-like">4</li>
                                <li className="counter-comment">3</li>
                                <li className="folder">Education</li>
                            </ul>
                        </div>
                        <p className="text1">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                            leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                            with the release of Letraset sheets.
                        </p>
                        <h6 className="sub-title">It’s only natural. Entanglement happens.</h6>
                        <p className="text2">
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin
                            professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words,
                            consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical
                            literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
                            of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This
                            book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of
                            Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                        </p>
                        <blockquote>
                            The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
                            Sections 1.10.32 and 1.10.33 from de Finibus
                        </blockquote>
                        <p className="text3">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                            and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                            leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                            with the release of Letraset sheets.
                        </p>
                        <h6 className="sub-title">It’s only natural. Entanglement happens.</h6>
                        <p className="text4">
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin
                            professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words,
                            consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical
                            literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33
                            of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This
                            book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of
                            Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="admin-wrap">
                                <h6 className="admin">POST FROM HELLO MATERIALS</h6>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="socails flat-text-right">
                                <ul className="list">
                                    <li>SHARE</li>
                                    <li className="facebook">
                                        <Link to="#"><span className="ion-social-facebook" /></Link>
                                    </li>
                                    <li className="google">
                                        <Link to="#"><span className="ion-social-googleplus" /></Link>
                                    </li>
                                    <li className="twitter">
                                        <Link to="#"><span className="ion-social-twitter" /></Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-next-pre clearfix border-bt-f0f0f0">
                    <div className="wrap-pre next-pre one-of-two flat-text-left clearfix">
                        <Link to="#">
                        <div className="icon pre transition">
                            <span className="ion-arrow-left-c" />
                        </div>
                        <div className="content">
                            <p>Summer Course Starts From 1st June </p>
                            <p>Previous</p>
                        </div>
                        </Link>
                    </div>
                    <div className="wrap-next next-pre one-of-two flat-text-right active clearfix">
                        <Link to="#">
                        <div className="icon next transition">
                            <span className="ion-arrow-right-c" />
                        </div>
                        <div className="content">
                            <p>Learning, friendship and fun for everyone</p>
                            <p>Next</p>
                        </div>
                        </Link>
                    </div>
                </div>
                <div className="flat-comments border-bt-f0f0f0">
                    <h3 className="title">COMMENTS</h3>
                    <ul>
                        <li>
                            <div className="comment bg-fff border-f-e6f3ff">
                                <div className="comment-img">
                                    <img src="images/blog/comment-1.jpg" alt="bookflare" />
                                </div>
                                <div className="comment-content">
                                    <h6 className="name">
                                        <Link to="#">RICHARD OBRIEN</Link>
                                    </h6>
                                    <span className="datetime">12 Mar 2019, 20:02</span>
                                    <p className="description">
                                        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
                                        piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard
                                        McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of
                                        the more obscure Latin words.
                                    </p>
                                    <div className="wrap-btn">
                                        <Link to="#" className="btn-reply">reply</Link>
                                    </div>
                                </div>
                            </div>
                            <ul className="comment-child">
                                <li>
                                    <div className="comment ">
                                        <div className="comment-img">
                                            <img src="images/blog/comment-2.jpg" alt="bookflare" />
                                        </div>
                                        <div className="comment-content">
                                            <h6 className="name">
                                                <Link to="#">ANDREA BLACK</Link>
                                            </h6>
                                            <span className="datetime">12 Feb 2019, 20:02</span>
                                            <p className="description">
                                                Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written
                                                in 45 BC. This book is a treatise on the theory of ethics, very popular during
                                                the Renais sance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet.
                                            </p>
                                            <div className="wrap-btn">
                                                <Link to="#" className="btn-reply">reply</Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="flat-form-reply">
                    <h3 className="title">LEAVE A REPLY</h3>
                    <form id="form-reply" method="get">
                        <div className="wrap-input-all clearfix">
                            <div className="wrap-input one-of-two pd-right-15">
                                <input type="text" name="name" placeholder="Name" required />
                            </div>
                            <div className="wrap-input one-of-two pd-left-15">
                                <input type="email" name="email" placeholder="Email" required />
                            </div>
                        </div>
                        <div className="wrap-textarea">
                            <textarea name="massage" placeholder="Your comment..." required defaultValue={""} />
                            </div>
                        <div className="wrap-btn">
                            <button type="submit" className="flat-button btn-send">SEND MASSAGE</button>
                        </div>   
                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default SingleBlogComponent;