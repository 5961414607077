import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Bull,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
//import 'react-tabs/style/react-tabs.css';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LockIcon from "@material-ui/icons/Lock";

import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";

import MyProfileComponent from "./MyProfileComponent";
import ResetPassword from "./ResetPassword";
import MyCoursesDashboard from "./MyCoursesDashboard";
import WelcomeDashboard from "./WelcomeDashboard";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    // height: 224,
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function DashboardComponent() {
  const classes = useStyles();
  const linkClickHandleLogout = () => {
    localStorage.removeItem("token");
    //localStorage.removeItem("customer_name");
    //  window.location.href='/login';
    window.location.reload();
  };
  return (
    <div className={classes.root}>
      <Container
        component="main"
        maxWidth="xl"
        className=""
        justifycontent="center"
      >
        {/* <Container component="main" maxWidth="lg" className="main-dash"> */}
        <Grid container spacing={2}>
          <Tabs>
            <Grid item sm={12} xs={12} lg={3} className="dash-col1">
              <TabList>
                <Tab>
                  <Grid>
                    <Grid className="dashi1">
                      <DashboardIcon />
                    </Grid>
                    Dashboard
                  </Grid>
                </Tab>
                <Tab>
                  <Grid>
                    <Grid className="dashi1">
                      <AccountCircleIcon />
                    </Grid>
                    My Profile
                  </Grid>
                </Tab>
                <Tab>
                  <Grid>
                    <Grid className="dashi1">
                      <DashboardIcon px={2} />
                    </Grid>
                    My Courses
                  </Grid>
                </Tab>
                <Tab>
                  <Grid>
                    <Grid className="dashi1">
                      <LockIcon px={2} />
                    </Grid>
                    Forget Password
                  </Grid>
                </Tab>
                <Link to="#" onClick={linkClickHandleLogout}> <Tab>
                  <Grid>
                    <Grid className="dashi1">
                      <PowerSettingsNewIcon px={2} />
                    </Grid>
                  
                              Sign Out
                          
                    
                  </Grid>
                </Tab>  </Link>
              </TabList>
            </Grid>
            <Grid item sm={12} xs={12} lg={9} className="dash-col2">
              <TabPanel>
                <Grid className="panel-content">
                  <Grid className="">
                    <WelcomeDashboard />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid className="panel-content">
                  <MyProfileComponent />
                </Grid>
              </TabPanel>

              <TabPanel>
                <Grid className="panel-content">
                  <MyCoursesDashboard />
                </Grid>
              </TabPanel>

              <TabPanel>
                <Grid className="panel-content">
                  <ResetPassword />
                </Grid>
              </TabPanel>

              <TabPanel>
                <Grid className="panel-content">
                  <Typography>My Profile</Typography>
                </Grid>
              </TabPanel>
            </Grid>
          </Tabs>
        </Grid>
      </Container>
    </div>
  );
}
