import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class TabCourses extends Component {
    render() {
        return (
            <div className="flat-tabs">
                <ul className="tab-title clearfix style4">
                    <li className="item-title  overview">
                        <span className="inner"> Learn the best of SAP Ariba and Fieldglass</span>
                    </li>
                    <li className="item-title event">
                        <span className="inner"> Choose SAP as a Career  </span>
                    </li>
                    {/* <li className="item-title requirements">
                        <span className="inner">VOVABULARY</span>
                    </li>
                    <li className="item-title review">
                        <span className="inner">KID'S COURSES</span>
                    </li> */}
                </ul>
                <div className="tab-content-wrap">
                    <div className="tab-content ">
                        <div className="item-content">
                            <div className="text-wrap img-right clearfix">
                                <div className="video flat-text-center">
                                    <img src="images/register/video.png" alt="bookflare" />
                                    <a data-type="iframe" href="https://www.youtube.com/embed/2Ge1GGitzLw?autoplay=1"
                                        className=" fancybox"><span className="icon-video" /></a>
                                </div>
                                <h6 className="title">
                                Learn the best of SAP Ariba and SAP Fieldglass with us! 
                                </h6>
                                <p className="text">The productivity enhancement training is the main area that we focus on. This is to build the productive and skilled workforce in order to meet the challenges of worldwide business competitions by corporates. We provide industry-best training and skill-building services, highly effective guidance on professional certifications, standardized quality content development and delivery.</p>
                               
                                <p className="text">We provide training for SAP Ariba and SAP Fieldglass. Before you start with your training you can enroll for a free demo also!</p>
                                <p className="text">We deliver highly skilled and well-trained professionals who completely meet the requirements of your corporate trainings. In doing so we fill the vacuum of gap to the Corporate companies. It is a complete assurance from our side that our industry-expert trainers will exceed your demand of standards and quality as they are highly advanced in the implementation of technologies arising daily from researches in the IT Industry.</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content ">
                        <div className="item-content">
                            <div className="text-wrap img-right clearfix">
                                <div className="video flat-text-center">
                                    <img src="images/register/video.png" alt="bookflare" />
                                    <a data-type="iframe" href="https://www.youtube.com/embed/2Ge1GGitzLw?autoplay=1"
                                        className=" fancybox"><span className="icon-video" /></a>
                                </div>
                                <h6 className="title">
                                Choose SAP as a Career 
                                </h6>
                                <p className="text">SAP is the most demanding career options these days! Almost every type of industry is implementing SAP to achieve the desired success and remain at the top. SAP offers a variety of software packages for enterprise technologists, much of it in the enterprise resource planning (ERP) space. Many large companies demand that their developers have at least some familiarity with SAP’s software. That means SAP training is essential.</p>
                                
                                <p className="text">Generally speaking, experience with SAP gives you a beneficial and sought after toolset that you can use to assemble your vocation. Web noticed that SAP professions can be extraordinary springboards to more broad administration jobs, for example, utilitarian information combination supervisor, overseer of material administration or a practical/coordination director.</p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content ">
                        <div className="item-content">
                            <div className="text-wrap img-right clearfix">
                                <div className="video flat-text-center">
                                    <img src="images/register/video.png" alt="bookflare" />
                                    <a data-type="iframe" href="https://www.youtube.com/embed/2Ge1GGitzLw?autoplay=1"
                                        className=" fancybox"><span className="icon-video" /></a>
                                </div>
                                <h6 className="title">
                                    VOVABULARY
                                </h6>
                                <p className="text">Lorem Ipsn gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem
                                    quis bibendum auci elit consequat ipsutis sem nibh id elit. Duis sed odio sit amet nibh
                                    vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt
                                    aucto.</p>
                                <h6 className="title mg-bottom-25">
                                    REQUIREMENTS
                                </h6>
                                <p className="text">Per inceptos himenaeos. Mauris in erat justo. Nullam ac urna eu felis dapibus
                                    condntum sit amet a augue. Sed non mauris vitae erat consequat auctor eu in elit. Class aptento
                                    taciti sociosqu ad litora torquent.</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="tab-content ">
                        <div className="item-content">
                            <div className="text-wrap img-right clearfix">
                                <div className="video flat-text-center">
                                    <img src="images/register/video.png" alt="bookflare" />
                                    <a data-type="iframe" href="https://www.youtube.com/embed/2Ge1GGitzLw?autoplay=1"
                                        className=" fancybox"><span className="icon-video" /></a>
                                </div>
                                <h6 className="title">
                                    KID'S COURSES
                                </h6>
                                <p className="text">Lorem Ipsn gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem
                                    quis bibendum auci elit consequat ipsutis sem nibh id elit. Duis sed odio sit amet nibh
                                    vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt
                                    aucto.</p>
                                <h6 className="title mg-bottom-25">
                                    REQUIREMENTS
                                </h6>
                                <p className="text">Per inceptos himenaeos. Mauris in erat justo. Nullam ac urna eu felis dapibus
                                    condntum sit amet a augue. Sed non mauris vitae erat consequat auctor eu in elit. Class aptento
                                    taciti sociosqu ad litora torquent.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default TabCourses;