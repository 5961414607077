import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class BannerProfessional extends Component {
    render() {
        return (
            <div className="service-wrap flat-text-center">
                <h2 className="title"><Link to="#">Start Free Demo!</Link></h2>
                <p className="description">You can now join our free demo before you start with your SAP online training. Learn from the industry experts and step into a successful SAP career.</p>
                <div className="wrap-btn">
                    {/* <Link to="https://docs.google.com/forms/d/1wPlJl6ca-pdv31ypjTj0fxmXPd094ATYK7BkHgMDRxA/viewform?edit_requested=true" className="flat-button btn-start border-ra4">Start Free Demo</Link> */}
                    <Link to={{ pathname: "https://docs.google.com/forms/d/1wPlJl6ca-pdv31ypjTj0fxmXPd094ATYK7BkHgMDRxA/viewform?edit_requested=true" }} target="_blank" className="flat-button btn-start border-ra4">Start Free Demo</Link>

                </div>
            </div>
        );
    }
}

export default BannerProfessional;