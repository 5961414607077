import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class EventBoxs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 1,
                    dataanimate: 'zoomIn',
                    delay: '0.2s',
                    offset: '75%',
                    srcimg: 'images/feature/24 7 SAP ARIBA Server Access1.jpg',
                    title: 'TED Talks at UCF College of Education',
                    time: '08:00 - 11:00',
                    address: 'Melbourne',
                    day: '8',
                    month: 'JUNE',
                    classNames: 'col-lg-3  col-md-12 effect-animation'
                },
                {
                    id: 2,
                    dataanimate: 'zoomIn',
                    delay: '0.4s',
                    offset: '75%',
                    srcimg: 'images/feature/customized batch schedule.jpg',
                    title: 'Summer Course Starts From June',
                    time: '08:00 - 11:00',
                    address: 'Melbourne',
                    day: '10',
                    month: 'JUNE',
                    classNames: 'col-lg-3   col-md-12 effect-animation'
                },
                {
                    id: 3,
                    dataanimate: 'zoomIn',
                    delay: '0.6s',
                    offset: '75%',
                    srcimg: 'images/feature/expert trainers with more than 12 years of experience.jpg',
                    title: 'Importance of Research Seminar',
                    time: '08:00 - 11:00',
                    address: 'Melbourne',
                    day: '17',
                    month: 'JUNE',
                    classNames: 'col-lg-3 col-md-12 effect-animation'
                },
                {
                    id: 4,
                    dataanimate: 'zoomIn',
                    delay: '0.6s',
                    offset: '75%',
                    srcimg: 'images/feature/full technical support.jpg',
                    title: 'Importance of Research Seminar',
                    time: '08:00 - 11:00',
                    address: 'Melbourne',
                    day: '17',
                    month: 'JUNE',
                    classNames: 'col-lg-3 col-md-12 effect-animation'
                },
                {
                    id: 5,
                    dataanimate: 'zoomIn',
                    delay: '0.6s',
                    offset: '75%',
                    srcimg: 'images/feature/simple and best sap ariba training material.jpg',
                    title: 'Importance of Research Seminar',
                    time: '08:00 - 11:00',
                    address: 'Melbourne',
                    day: '17',
                    month: 'JUNE',
                    classNames: 'offset-lg-3  col-lg-3  col-md-12 effect-animation'
                },
                {
                    id: 6,
                    dataanimate: 'zoomIn',
                    delay: '0.6s',
                    offset: '75%',
                    srcimg: 'images/feature/PDF and recorded videos.jpg',
                    title: 'Importance of Research Seminar',
                    time: '08:00 - 11:00',
                    address: 'Melbourne',
                    day: '17',
                    month: 'JUNE',
                    classNames: '  col-lg-3   col-md-12   effect-animation '
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className={data.classNames} data-animation={data.dataanimate} data-animation-delay={data.delay} data-animation-offset={data.offset} key={data.id}>
                            <div className="event style2">
                                <div className="event-img">
                                    <Link to="#"><img src={data.srcimg} alt="bookflare" /></Link>
                                </div>
                                {/* <div className="event-content">
                                    <h6 className="title"><Link to="/event-single" onClick={() => {window.location.href="/event-single"}}>{data.title}</Link></h6>
                                    <div className="meta-list">
                                        <ul className="list">
                                            <li className="time"><Link to="#">{data.time}</Link></li>
                                            <li className="address"><Link to="#">{data.address}</Link></li>
                                        </ul>
                                    </div>
                                    <div className={data.classNames}>
                                        <p className="day">{data.day}</p>
                                        <p className="month">{data.month}</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default EventBoxs;