import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { checkValidity } from "../../../Shared";
import httpService from "../../../HttpService/httpService";
import CustomizedSnackbars from "../../../Constants/config";
import { useHistory } from "react-router-dom";

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
  Grid,
  Paper,
  Link,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#1f3a68",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1f3a68",
    "&:hover": {
      background: "#1f3a68",
      color: "#f7c13a",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "theme.palette.background.paper",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
  formControl: {
    margin: "0px",
    minWidth: 120,
  },

  formField: {
    padding: "5px 0px 10px 0px",
  },
  headlogin: {
    fontSize: "1.875rem",
    fontWeight: "700",
    lineHeight: "2.25rem",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    "& .MuiAutocomplete-inputRoot": {
      padding: "0px",
      height: "46px",
      background: "transperent",
      marginTop: "5px;",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: "20px",
    },
  },
}));

export default function RegisterationComponent() {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  let history = useHistory();
  const [stateObj, setError] = useState({
    error: {
      firstName: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          isfirstName: true,
          fMinlength: 3,
          fMaxlength: 18,
        },
      },
      lastName: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          islastName: true,
          lMinlength: 3,
          lMaxlength: 18,
        },
      },
      email: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          isEmail: true,
        },
      },
      password: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          minLength: 8,
          isPass: true,
          maxLength: 15,
        },
      },
      mobile: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          isMobile: true,
          MobminLength: 10,
          MobmaxLength: 10,
          isNumber: true,
        },
      },
      country: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          isDropdown: true,
        },
      },
    },
    account: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobile: "",
      country: null,
    },
  });

  useEffect(() => {
    httpService
      .get("/countries")
      .then((res) => {
        setCountries(res.data.countries[0]);
      })
      .catch((err) => err);
  }, []);

  const changeHandler = (e) => {
    const { value, name } = e.target;
    const { error, account } = stateObj;
    const validationObj = error[name];
    const { errorObject } = checkValidity(value, name, validationObj);
    setError({
      ...stateObj,
      error: { ...error, [name]: { errorObject } },
      account: { ...account, [name]: value },
    });
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  const onDropdownChangeHandler = (cntrlName, value) => {
    const { error, account } = stateObj;
    const validationObj = error[cntrlName];
    const id = value === null ? 0 : value.id;
    const { errorObject } = checkValidity(id, cntrlName, validationObj);

    setError({
      ...stateObj,
      error: { ...error, [cntrlName]: { errorObject } },
      account: { ...account, [cntrlName]: value },
    });
  };

  const onSubmitHandler = () => {
    const { error, account } = stateObj;
    let result = true;
    for (let err in error) {
      const value = account[err];
      const name = err;
      const validationObj = error[name];
      const { errorObject } = checkValidity(value, name, validationObj);
      if (errorObject.errorMessage !== "") {
        result = false;
      }
      error[name] = { errorObject };
    }
    setError({ account, error: error });

    httpService
      .post("/customer", {
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        mobile: account.mobile,
        countryId: account.country.id,
        password: account.password,
      })
      .then((res) => {
        // console.log('success',res.data.message);
        // console.log(res.data.data.message);
        // setMessage(err.response.data.message);

        setSeverity("success");
        setMessage(res.data.message);
        setSnackbar(true);
        setTimeout(() => {
        history.push('/my-account');
        }, 5000);
      })
      .catch((err) => {
        // console.log(err);
        setSeverity("warning");
        setMessage(err.response.data.message);
        setSnackbar(true);
      });
  };

  return (
    <>
      <Paper className="Reg-image">
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div className="paper ">
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography
              component="h2"
              variant="h2"
              className={classes.headlogin}
            >
              Create your account
            </Typography>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.formField}
                    // autoComplete="off"
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={changeHandler}
                    error={!stateObj.error.firstName.errorObject.isValid}
                    helperText={
                      stateObj.error.firstName.errorObject.errorMessage
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.formField}
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    //autoComplete="off"
                    onChange={changeHandler}
                    error={!stateObj.error.lastName.errorObject.isValid}
                    helperText={
                      stateObj.error.lastName.errorObject.errorMessage
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    className={classes.formField}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    // autoComplete="off"
                    onChange={changeHandler}
                    error={!stateObj.error.email.errorObject.isValid}
                    helperText={stateObj.error.email.errorObject.errorMessage}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    className={classes.formField}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    // autoComplete="off"
                    onChange={changeHandler}
                    error={!stateObj.error.password.errorObject.isValid}
                    helperText={
                      stateObj.error.password.errorObject.errorMessage
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.formField}
                    variant="outlined"
                    required
                    fullWidth
                    id="mobile"
                    label="Mobile"
                    name="mobile"
                    //  autoComplete="off"
                    onChange={changeHandler}
                    error={!stateObj.error.mobile.errorObject.isValid}
                    helperText={stateObj.error.mobile.errorObject.errorMessage}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Autocomplete
                    className={classes.root}
                    // value={stateObj.account.country}
                    // onChange={(event, newValue) => {
                    //   onDropdownChangeHandler('country', newValue);
                    // }}
                    id="country-select-demo"
                    options={countries}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderInput={(params) => (
                      <TextField
                        label="Select Country"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="country-select-demo"
                    className={classes.root}
                    value={stateObj.account.country}
                    onChange={(event, newValue) => {
                      onDropdownChangeHandler("country", newValue);
                    }}
                    autoHighlight
                    options={countries}
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a country"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                        error={!stateObj.error.country.errorObject.isValid}
                        helperText={
                          stateObj.error.country.errorObject.errorMessage
                        }
                      />
                    )}
                  />{" "}
                </Grid>
              </Grid>
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onSubmitHandler}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  Already have an account?
                  <Link href="/login" variant="body2" className="alreday">
                    Sign in
                  </Link>
                </Grid>
              </Grid>
              <CustomizedSnackbars
                open={snackbar}
                closeSnackbar={closeSnackbar}
                message={message}
                severity={severity}
              />
            </form>
          </div>
        </Container>
      </Paper>
    </>
  );
}
