import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import httpService from "../../../HttpService/httpService";
import { checkValidity } from "../../../Shared";
import CustomizedSnackbars from "../../../Constants/config";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    //padding:'50px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1f3a68",
    "&:hover": {
      background: "#1f3a68",
      color: "#f7c13a",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "theme.palette.background.paper",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
  formControl: {
    margin: "0px",
    minWidth: 120,
  },

  formField: {
    padding: "5px 0px 10px 0px",
  },

  headlogin: {
    fontSize: "1.875rem",
    fontWeight: "700",
    lineHeight: "2.25rem",
    padding: "10px 0 10px 0",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
 
  const [snackbar, setSnackbar] = React.useState(false);
  const [severity, setSeverity] = React.useState("success");
  const [message, setMessage] = React.useState("");
  // let history = useHistory();
  const [stateObj, setError] = useState({
    error: {
      newPassword: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          minLength: 8,
          maxLength: 15,
          isPass: true,
          Match: true,
        },
      },
      confirmPassword: {
        errorObject: {
          required: true,
          errorMessage: "",
          isValid: true,
          minLength: 8,
         
          isPass: true,
          maxLength: 15,
          Match: true,
        },
      },

      oldPassword: {
        errorObject: {
          required: true,
           errorMessage: '',
          isValid: true,
          minLength: 8,
          isPass: true,
          maxLength: 15,
        },
      },
    },

    account: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const changeHandler = (e) => {
    const { value, name } = e.target;
    const { error, account } = stateObj;
    const validationObj = error[name];
    const { errorObject } = checkValidity(value, name, validationObj);
    setError({
      ...stateObj,
      error: { ...error, [name]: { errorObject } },
      account: { ...account, [name]: value },
    });
  };
  const onSubmitHandler = () => {
    const { error, account } = stateObj;
    let result = true;
    for (let err in error) {
      const value = account[err];
      const name = err;
      const validationObj = error[name];
      const { errorObject } = checkValidity(value, name, validationObj);

      if (errorObject.errorMessage !== "") {
        result = false;
        // console.log('result');
      }
      error[name] = { errorObject };
    }
    setError({ account, error: error });

    if (result === true) {
      httpService
        .patch(`/custmer/reset/pass`, {
          oldPassword: account.oldPassword,
          newPassword: account.newPassword,
          confirmPassword: account.confirmPassword,
        })
        .then((res) => {
          console.log("success", res);
          setSeverity("sucess");
          setMessage(res.data.message);
          setSnackbar(true);
          localStorage.removeItem("token");

          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
          setSeverity("warning");
          setMessage(err.response.data.message);
          setSnackbar(true);
        });
    }
  };

  return (
    <Grid maxwidth="sm" container display="flex" justifyContent="center">
      <Grid item sm={6}>
        <form className="form-myprofile" noValidate>
          <Typography component="h2" variant="h2" className={classes.headlogin}>
            Change Password
          </Typography>
          <Grid container>
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                className={classes.formField}
                autoComplete="off"
                variant="outlined"
                fullWidth
                type="password"
                id="oldPassword"
                name="oldPassword"
                label="Old Password "
                autoFocus
                onChange={changeHandler}
                error={!stateObj.error.oldPassword.errorObject.isValid}
                helperText={stateObj.error.oldPassword.errorObject.errorMessage}
              />
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <TextField
                className={classes.formField}
                variant="outlined"
                required
                fullWidth
                type="password"
                name="newPassword"
                id="Newpassword"
                label="New Password"
                autoComplete="off"
                onChange={changeHandler}
                error={!stateObj.error.newPassword.errorObject.isValid}
                helperText={stateObj.error.newPassword.errorObject.errorMessage}
              />
            </Grid>

            <Grid item md={12} xs={12} sm={12}>
              <TextField
                className={classes.formField}
                variant="outlined"
                required
                fullWidth
                type="password"
                id="Confirm"
                label="Confirm Password"
                name="confirmPassword"
                autoComplete="off"
                onChange={changeHandler}
                error={!stateObj.error.confirmPassword.errorObject.isValid}
                helperText={
                  stateObj.error.confirmPassword.errorObject.errorMessage
                }
              />
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onSubmitHandler}
          >
            Update
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}
