import React, { Component } from 'react';
import { BottomBar, Footer, Header, TopBar, Loader } from '../layouts/general';
 import {  RegisterationComponent } from '../layouts/pages';

class Registeration extends Component
{
    constructor(props){
        super(props);
        this.state={
            headers:[
                {
                    id:1,
                    name:'pages'
                }
            ],

        }
    }
    
    render(){
        return(
            <div className="bg-body2">
                <div className="boxed" style={{height:'100%'}}>
                    {/* <Loader/> */}
                    <TopBar/>
                    {
                        this.state.headers.map(data=>(
                            <Header data={data} key={data.id} />
                        ))
                    } 
                    
                        <RegisterationComponent/>
    
                       
                </div>
            </div>    
    
    
        
    
    
    
        );
    }
    

}

export default Registeration;