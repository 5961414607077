import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import GradeIcon from '@material-ui/icons/Grade';
import httpService from '../../../HttpService/httpService';
import { IMG_URL } from '../../../Constants/config';
import CustomizedSnackbars from '../../../Constants/config.js';
import { Link,useParams } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  bottomIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  myClassName: {
    position: 'relative',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  favorites: {
    position: 'relative',
    '&:hover': {
      backgroundColor: '#fffff',

      color: '#1f3a68',
    },
  },
  CourseCardContent: {
    textAlign: 'center',
    border: '1px solid #e6f3ff',
  },
  mainCon: {
    paddingTop: '40px',
    paddingBottom: '40px',
  },

  Grids: {
    '&.MuiGrid-item': {
      paddingBottom: '30px',
    },
  },
  discount: {
    textDecoration: 'line-through',
    padding: '10px',
  },
}));

export default function CourseCard(props) {
  console.log("course card was called")
  const classes = useStyles();
  const [courses, setCourses] = useState([]);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const [snackbar, setSnackbar] = useState(false);
  const customerId = localStorage.getItem('customerId');
  const token = localStorage.getItem('token');
  let { id } = useParams();
  //console.log(id);
  useEffect(() => {
    httpService
      .get('/customer/all/courses/', { params: { courseTypeId: `${id}` } })
      .then((res) => {
        setCourses(res.data.courses);
        
      })
      .catch((err) => {
        console.log(err);
      });

  }, [id]);

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  const addToCartAfterLogin = (customerId, courseId) => {
    httpService
      .post(`/add-to-cart/${customerId}/${courseId}`)
      .then((res) => {
        setSnackbar(true);
        setMessage(res.data.message);
        setSeverity('success');
      })
      .catch((err) => {
        setSnackbar(true);
        setMessage(err.response.data.message);
        setSeverity('warning');
      });
  };

  const addCourseToCart = async (courseData) => {
    if (token) {
      await addToCartAfterLogin(customerId, courseData.id);
    } else {
      const cartExistingData = JSON.parse(
        localStorage.getItem('sap-cart-list')
      );
      if (cartExistingData) {
        const itemExisting = cartExistingData.filter(
          (cart) => cart.id === courseData.id
        );
        if (itemExisting.length > 0) {
          setSnackbar(true);
          setSeverity('warning');
          setMessage('Course is already present in your cart');
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        } else {
          let existingData = [];
          existingData.push(...cartExistingData, courseData);
          localStorage.setItem('sap-cart-list', JSON.stringify(existingData));
          setSeverity('success');
          setMessage('Course added to cart');
          setSnackbar(true);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        }
      } else {
        localStorage.setItem('sap-cart-list', JSON.stringify([courseData]));
      }
    }
  };

  const addToWishlistAfterLogin = (customerId, courseId) => {
    httpService
      .post('/customer/addto/wishlist', { customerId, courseId })
      .then((res) => {
        setMessage(res.data.message);
        setSeverity('success');
        setSnackbar(true);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setSeverity('error');
        setSnackbar(true);
      });
  };

  const addToWishlist = async (courseData) => {
    if (token) {
      await addToWishlistAfterLogin(customerId, courseData.id);
    } else {
      const wishlistExistingData = JSON.parse(
        localStorage.getItem('sap-wishlist')
      );
      if (wishlistExistingData) {
        const wishlistExisting = wishlistExistingData.filter(
          (cart) => cart.id === courseData.id
        );
        if (wishlistExisting.length > 0) {
          setSnackbar(true);
          setSeverity('warning');
          setMessage('Course is already present in your wishlist');
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        } else {
          let existingData = [];
          existingData.push(...wishlistExistingData, courseData);
          localStorage.setItem('sap-wishlist', JSON.stringify(existingData));
          setSeverity('success');
          setMessage('Course added to your wishlist');
          setSnackbar(true);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        }
      } else {
        localStorage.setItem('sap-wishlist', JSON.stringify([courseData]));
        setSeverity('success');
        setMessage('Course added to your wishlist');
        setSnackbar(true);
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      }
    }
  };
  const linkClick= () => {
    window.scrollTo({ top: 0 });
    window.location.href='/courses-single';
  }
  return courses.map((course, idx) => (
    <Grid item xs={12} sm={3} className={classes.Grids} key={idx}>
      <Card elevation={0} className={classes.CourseCardContent}>
       <Link to="#" onClick={linkClick}> <CardMedia
          className={classes.media}
          image={`${IMG_URL}/${course.image_path}`}
          title="Paella dish"
        /></Link>
        <CardContent>
          <Typography
            variant="h5"
            component="h2"
            className="courseHead"
            style={{ fontWeight: '600' }}
          >
            {course.category_name}
          </Typography>
          <Grid>
            <Typography>
              <IconButton
                aria-label="add to favorites"
                style={{
                  color: '#FFA534',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
                className={classes.myClassName}
              >
                4.8
                <GradeIcon />
                <GradeIcon />
                <GradeIcon />
                <GradeIcon />
                <StarHalfIcon />
              </IconButton>
            </Typography>
          </Grid>
        </CardContent>
        <CardActions disableSpacing>
          <Grid className={classes.bottomIcons}>
            <Grid>
              <Typography>
                <IconButton
                  aria-label="add to favorites"
                  className={classes.favorites}
                  onClick={() => addToWishlist(course)}
                >
                  <FavoriteBorderIcon />
                </IconButton>
              </Typography>
            </Grid>
            <Grid>
              <Typography>
                <IconButton
                  aria-label="add to ShoppingCart"
                  className={classes.favorites}
                  onClick={() => addCourseToCart(course)}
                >
                  <AddShoppingCartIcon />
                </IconButton>
              </Typography>
            </Grid>
            <Grid>
              <Typography className="bottombarCourse">
                {' '}
                ₹{course.total_price}
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes.discount}>
                ₹{course.price}
              </Typography>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <CustomizedSnackbars
        open={snackbar}
        severity={severity}
        message={message}
        onClose={closeSnackbar}
      />
    </Grid>
  ));
}
