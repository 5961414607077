import { React, useState, useContext } from 'react';
import {
  makeStyles,
  CardContent,
  Typography,
  Button,
  Card,
  IconButton,
  Grid,
  Avatar,
} from '@material-ui/core/';

import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import httpService from '../HttpService/httpService';
import CustomizedSnackbars, { IMG_URL } from '../Constants/config';
import { CartContext } from './layouts/layout/layout';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '80%',
    maxWidth: '100%',
    margin: '0 auto',
    marginTop: '2%',
    height: 'auto',
    borderRadius: '0px',
    '&.MuiCardActionArea-root ': {
      backgroundColor: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
      height: 'auto',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 'auto',
    },
  },
  cardActionArea: {
    display: 'flex',
    height: 'auto',
    width: '100%',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 'auto',
    },
    [theme.breakpoints.only('md')]: {
      height: 'auto',
    },
  },
  avatar: {
    height: '80px',
    width: '125px',
    margin: '10px',
    borderRadius: '0px',
    padding: '0px',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      height: '80px',
      width: '80px',
      margin: '5px',
    },
  },
  courseTitle: {
    textTransform: 'none',
    fontSize: '14px',
    fontFamily: 'inherit',
    color: 'black',
    lineHeight: '13px',
    marginTop: '5px',
    [theme.breakpoints.only('sm')]: {
      marginTop: '10px',
    },
  },

  discountPriceText: {
    fontSize: '16px',
    textAlign: 'center',
    textDecoration: 'line-through',
    fontFamily: `'Abril Fatface', cursive'`,
    textTransform: 'none',
  },
  priceAndDiscont: {
    display: 'flex',
    flexDirection: 'column',
  },
  priceTag: {
    padding: ' 0px !important',
    marginTop: '2px',
    height: '5px',
    width: '5px',
    color: '#1F3A68',
    '&:hover': {
      background: 'none',
      color: '#1F3A68',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '18px',
      height: '27px',
      marginTop: '-10px',
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: '6px',
      height: '27px',
      marginTop: '-10px',
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: '5px',
      marginBottom: '13px',
    },
    [theme.breakpoints.up('md')]: {
      height: '30px',
      marginLeft: '10px',
    },
  },
  cardContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  cardButtons: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      marginTop: '5px',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      height: '20px',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      display: 'flex',
      maxHeight: '50px',
      flexDirection: 'column',
    },
  },
  cardButton: {
    color: '#1F3A68',
    textTransform: 'none',
    fontFamily: 'inherit',
    fontSize: '15px',
    margin: '0px',
    padding: '0px',
    '&:hover': {
      background: 'none',
      color: '#1F3A68',
    },
    [theme.breakpoints.down('xs')]: {
      height: '20px',
      fontSize: '12px',
      justifyContent: 'flex-start',
    },
  },
  priceGrid: {
    paddingBottom: '0px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      paddingBottom: '0px',
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '15px',
      marginLeft: '10px',
    },
  },
  coursePrices: {
    fontSize: '18px',
    padding: '0px !important',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '18px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0px',
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      maxWidth: '100px',
    },
  },
  coursePriceText: {
    textAlign: 'center',
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      marginRight: '5px',
    },
  },
  cardContentBox: {
    width: '100%',
    paddingBottom: '0px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '0px',
    },
  },
  coursePriceBox: {
    marginTop: '0px',
    [theme.breakpoints.down('xs')]: {
      height: '26px',
      marginLeft: '0px',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '8px',
    },
  },
}));

export default function WishlistComponent() {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const customerId = localStorage.getItem('customerId');

  const {
    token,
    wishlist = [],
    wishlistBeforeLogin = [],
  } = useContext(CartContext) || {};

  console.log('wishlistBeforeLogin',token, wishlistBeforeLogin);

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  const removeFromWishList = (courseId) => {
    if (token) {
      httpService
        .patch(`/customer/wishlist/remove/${customerId}/${courseId}`)
        .then((res) => {
          setMessage(res.data.message);
          setSeverity('success');
          setSnackbar(true);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        })
        .catch((err) => {
          setMessage(err.response.data.message);
          setSeverity('error');
          setSnackbar(true);
        });
    }
  };

  /* make it helper or pass into props */
  const addToCartAfterLogin = (customerId, courseId) => {
    httpService
      .post(`/add-to-cart/${customerId}/${courseId}`)
      .then((res) => {
        setSnackbar(true);
        setMessage(res.data.message);
        setSeverity('success');
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      })
      .catch((err) => {
        setSnackbar(true);
        setMessage(err.response.data.message);
        setSeverity('warning');
      });
  };

  const addCourseToCart = async (courseData) => {
    if (token) {
      await addToCartAfterLogin(customerId, courseData.course_id);
    } else {
      const cartExistingData = JSON.parse(
        localStorage.getItem('sap-cart-list')
      );
      if (cartExistingData) {
        const itemExisting = cartExistingData.filter(
          (cart) => cart.course_id === courseData.course_id
        );
        if (itemExisting.length > 0) {
          setSnackbar(true);
          setSeverity('warning');
          setMessage('Course is already present in your cart');
        } else {
          let existingData = [];
          existingData.push(...cartExistingData, courseData);
          localStorage.setItem('sap-cart-list', JSON.stringify(existingData));
        }
      } else {
        localStorage.setItem('sap-cart-list', JSON.stringify([courseData]));
      }
    }
  };

  return (wishlist.length > 0 ? wishlist : wishlistBeforeLogin).map(
    (course, idx) => (
      <div key={idx}>
        <CustomizedSnackbars
          open={snackbar}
          closeSnackbar={closeSnackbar}
          severity={severity}
          message={message}
        />
        <Card className={classes.root}>
          <Grid item xs={12} className={classes.cardActionArea}>
            <Avatar
              alt="course image"
              src={`${IMG_URL}/${course.image_path}`}
              className={classes.avatar}
            />
            <CardContent className={classes.cardContentBox}>
              <Grid container item xs={12} className={classes.cardContainer}>
                <Grid item xs={12} md={8} sm={12} lg={8}>
                  <Typography className={classes.courseTitle}>
                    {course.category_name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sm={8}
                  lg={2}
                  className={classes.cardButtons}
                >
                  <Button
                    size="small"
                    color="primary"
                    className={classes.cardButton}
                    onClick={() => removeFromWishList(course.course_id)}
                  >
                    Remove
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    className={classes.cardButton}
                    onClick={() => addCourseToCart(course)}
                  >
                    Add To Cart
                  </Button>
                </Grid>
                <Grid item xs={12} md={2} sm={12} className={classes.priceGrid}>
                  <Grid
                    container
                    item
                    spacing={2}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.coursePriceBox}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={1}
                      className={classes.coursePrices}
                    >
                      <Typography className={classes.coursePriceText}>
                        {course.price}
                      </Typography>
                      <Typography className={classes.discountPriceText}>
                        {course.discount}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={1}>
                      <IconButton className={classes.priceTag}>
                        <LocalOfferIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Card>
      </div>
    )
  );
}
