import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class TabAboutComponent extends Component {
  render() {
    return (
      <div className="flat-about-detail ">
        <div className="container">
          <div className="heading">
            <h6 className="title">
              Whatever discipline you choose, you’ll find your old ideas
              challenged and your new ideas embraced. Goldsmiths is a place
              where together we are creating the knowledge of the future.
            </h6>
          </div>
        </div>
        <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff style2">
          <ul className="tab-title style2 clearfix border-bt-e6f3ff  flat-text-center">
            <li className="item-title  overview">
              <span className="inner">WHO WE ARE</span>
            </li>
            <li className="item-title event">
              <span className="inner">OUR EDUCATION</span>
            </li>
            <li className="item-title review">
              <span className="inner">OUR STORY</span>
            </li>
          </ul>
          <div className="tab-content-wrap">
            <div className="tab-content">
              <div className="item-content">
                <div className="container">
                  <div className="flat-imgbox">
                    <div className="imgbox">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="imgbox-content left">
                            <h3 className="title">
                              <Link to="#">An Incubator for Ideas</Link>
                            </h3>
                            <p className="text">
                              Lorem Ipsn gravida nibh vel velit auctor aliquet.
                              Aenean sollicitudin, lorem quis bibendum auci elit
                              consequat ipsutis sem nibh id elit. Duis sed odio
                              sit amet nibh vulputate cursus a sit amet mauris.
                              Morbi accum dolor same mipsum rvelit. Lorem Ipsum
                              is simply dummy text of the printing and
                              typesetting industry.
                            </p>
                            <p className="text">
                              The standard chunk of Lorem Ipsum used since the
                              1500s is reproduced below for those interested.
                              Sections 1.10.32 and 1.10.33 from "de Finibus
                              Bonorum ealorum" by Cicero are also reproduced in
                              their exact original form, accompanied by English
                              versions.
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="imgbox-img">
                            <Link to="#">
                              <img
                                src="images/about/diving-innovation2.png"
                                alt="bookflare"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="imgbox">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="imgbox-img">
                            <Link to="#">
                              <img
                                src="images/about/detail-2.jpg"
                                alt="bookflare"
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="imgbox-content right">
                            <h3 className="title">
                              <Link to="#">President &amp; Leadership</Link>
                            </h3>
                            <p className="text">
                              Lorem Ipsn gravida nibh vel velit auctor aliquet.
                              Aenean sollicitudin, lorem quis bibendum auci elit
                              consequat ipsutis sem nibh id elit. Duis sed odio
                              sit amet nibh vulputate cursus a sit amet mauris.
                              Morbi accum dolor same mipsum rvelit. Lorem Ipsum
                              is simply dummy text of the printing and
                              typesetting industry.
                            </p>
                            <p className="text">
                              The standard chunk of Lorem Ipsum used since the
                              1500s is reproduced below for those interested.
                              Sections 1.10.32 and 1.10.33 from "de Finibus
                              Bonorum ealorum" by Cicero are also reproduced in
                              their exact original form, accompanied by English
                              versions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-content">
              <div className="item-content">
                <div className="container">
                  <div className="flat-imgbox">
                    <div className="imgbox">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="imgbox-content left">
                            <h3 className="title">
                              <Link to="#">An Incubator for Ideas</Link>
                            </h3>
                            <p className="text">
                              Lorem Ipsn gravida nibh vel velit auctor aliquet.
                              Aenean sollicitudin, lorem quis bibendum auci elit
                              consequat ipsutis sem nibh id elit. Duis sed odio
                              sit amet nibh vulputate cursus a sit amet mauris.
                              Morbi accum dolor same mipsum rvelit. Lorem Ipsum
                              is simply dummy text of the printing and
                              typesetting industry.
                            </p>
                            <p className="text">
                              The standard chunk of Lorem Ipsum used since the
                              1500s is reproduced below for those interested.
                              Sections 1.10.32 and 1.10.33 from "de Finibus
                              Bonorum ealorum" by Cicero are also reproduced in
                              their exact original form, accompanied by English
                              versions.
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="imgbox-img">
                            <Link to="#">
                              <img
                                src="images/about/detail-1.jpg"
                                alt="bookflare"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="imgbox">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="imgbox-img">
                            <Link to="#">
                              <img
                                src="images/about/detail-2.jpg"
                                alt="bookflare"
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="imgbox-content right">
                            <h3 className="title">
                              <Link to="#">President &amp; Leadership</Link>
                            </h3>
                            <p className="text">
                              Lorem Ipsn gravida nibh vel velit auctor aliquet.
                              Aenean sollicitudin, lorem quis bibendum auci elit
                              consequat ipsutis sem nibh id elit. Duis sed odio
                              sit amet nibh vulputate cursus a sit amet mauris.
                              Morbi accum dolor same mipsum rvelit. Lorem Ipsum
                              is simply dummy text of the printing and
                              typesetting industry.
                            </p>
                            <p className="text">
                              The standard chunk of Lorem Ipsum used since the
                              1500s is reproduced below for those interested.
                              Sections 1.10.32 and 1.10.33 from "de Finibus
                              Bonorum ealorum" by Cicero are also reproduced in
                              their exact original form, accompanied by English
                              versions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-content">
              <div className="item-content">
                <div className="container">
                  <div className="flat-imgbox">
                    <div className="imgbox">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="imgbox-img">
                            <Link to="#">
                              <img
                                src="images/about/detail-2.jpg"
                                alt="bookflare"
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="imgbox-content right">
                            <h3 className="title">
                              <Link to="#">President &amp; Leadership</Link>
                            </h3>
                            <p className="text">
                              Lorem Ipsn gravida nibh vel velit auctor aliquet.
                              Aenean sollicitudin, lorem quis bibendum auci elit
                              consequat ipsutis sem nibh id elit. Duis sed odio
                              sit amet nibh vulputate cursus a sit amet mauris.
                              Morbi accum dolor same mipsum rvelit. Lorem Ipsum
                              is simply dummy text of the printing and
                              typesetting industry.
                            </p>
                            <p className="text">
                              The standard chunk of Lorem Ipsum used since the
                              1500s is reproduced below for those interested.
                              Sections 1.10.32 and 1.10.33 from "de Finibus
                              Bonorum ealorum" by Cicero are also reproduced in
                              their exact original form, accompanied by English
                              versions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TabAboutComponent;
