import React, { Component } from 'react';
import { Header, TopBar, Loader } from '../layouts/general';
import { LoginComponent } from '../layouts/pages';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          name: 'pages',
        },
      ],
    };
  }

  render() {
    return (
      <div className="bg-body2">
        <div className="boxed">
          <Loader />
          <TopBar />
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}

          <LoginComponent />
        </div>
      </div>
    );
  }
}

export default Login;
