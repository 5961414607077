import React, { Component } from 'react';
import { BottomBar, Footer, Header, TopBar, Loader } from '../layouts/general';
import { Featured, Slider, FormApply, TabCourses } from '../layouts/home01';
import { CourseBox, TitleHeading, EventBoxs } from '../layouts/homeanimate';
import { HomeForm } from '../layouts/pages';
import { Link } from 'react-router-dom';

class HomeAnimate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          names: 'Home',
        },
      ],
      titleCourses: [
        {
          id: 1,
          title: 'Discover Our Popular Courses',
          classTitle: 'title',
          classDescription: 'description',
          description:
            'SAP Ariba , SAP Fieldglass online training Courses are designed to prepare you for entry & advanced level job profiles in the field of both technical & functional part. This is the right time to choose the right career. Get started with us today.',
        },
      ],
      titleIntructor: [
        {
          id: 1,
          classTitle: 'title',
          classDescription: 'description',
          title: 'Our Skilled Instructors',
          description:
            'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        },
      ],
      titleEvents: [
        {
          id: 1,
          classTitle: 'title',
          classDescription: 'description',
          title: 'Features Of Training ',
          description:
            'We strive hard to get you all the potentials you need to touch heights. Have a look at what we have to offer you through this Online SAP training !',
        },
      ],
      titleTestimonial: [
        {
          id: 1,
          classTitle: 'title',
          classDescription: 'description',
          title: 'What Our Teacher’s Say',
          description:
            'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        },
      ],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    const linkClickHandle = () => {
        window.scrollTo({ top: 0 });
        window.location.href='/comparision';
      }
    return (
      <div className="bg-body3">
        <div className="boxed">
          <Loader />
          <TopBar />
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}
          <Slider />
          <Featured />
          <section className="flat-popular-courses style2 v2 bg-gradient">
            <div className="container">
              {this.state.titleCourses.map((data) => (
                <TitleHeading data={data} key={data.id} />
              ))}
              <CourseBox />
              <div className="wrap-btn flat-text-center">
                <Link
                   to="#" onClick={linkClickHandle}
                  className="flat-button btn-all-sourses font-Poppins font-weight-700 border-ra4 v2"
                >
                  BROWSE ALL COURSES
                </Link>
              </div>
            </div>
          </section>
          <section className="flat-register-now">
            <div className="overlay" />
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-5 effect-animation"
                  data-animation="fadeInLeft"
                  data-animation-delay="0.2s"
                  data-animation-offset="75%"
                >
                  <FormApply />
                </div>
                <div
                  className="col-lg-7 effect-animation"
                  data-animation="fadeInRight"
                  data-animation-delay="0.2"
                  data-animation-offset="75%"
                >
                  <TabCourses />
                </div>
              </div>
            </div>
          </section>
          {/* <HomeForm/> */}
          {/* <section className="flat-skilled-instructors">
                        <div className="container">
                            {
                                this.state.titleIntructor.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <Instructor />
                        </div>
                    </section> */}
          <section
            className="flat-upcoming-events style1"
            style={{ width: '100%' }}
          >
            <div className="container">
              {this.state.titleEvents.map((data) => (
                <TitleHeading data={data} key={data.id} />
              ))}
              <div className="flat-events style2">
                <EventBoxs />
              </div>
            </div>
          </section>
          {/* <section className="flat-testimonials style1">
                        <div className="container">
                            {
                                this.state.titleTestimonial.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <SlideTestimonial />
                        </div>
                    </section> */}
          <section
            className="flat-feature parallax parallax2"
            style={{ width: '100%' }}
          >
            <div className="container">
              <div className="row flat-iconbox style1">
                <div className="col-lg-4 col-md-4">
                  <div
                    className="iconbox style1 left effect-animation"
                    data-animation="zoomIn"
                    data-animation-delay={0}
                    data-animation-offset="75%"
                  >
                    <div className="iconbox-icon">
                      <span ><i className="fas fa-server"></i></span>
                    </div>
                    <div className="iconbox-content">
                      <h5 className="title">Server access</h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div
                    className="iconbox style1 left effect-animation"
                    data-animation="zoomIn"
                    data-animation-delay={0}
                    data-animation-offset="75%"
                  >
                    <div className="iconbox-icon">
                    <span><i className="fas fa-cog"></i></span>
                    </div>
                    <div className="iconbox-content">
                      <h5 className="title">24x7 technical support</h5>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4">
                  <div
                    className="iconbox style1 center effect-animation"
                    data-animation="zoomIn"
                    data-animation-delay="0.2s"
                    data-animation-offset="75%"
                  >
                    <div className="iconbox-icon">
                      <span><i class="fas fa-cog"></i></span>
                    </div>
                    <div className="iconbox-content">
                      <h5 className="title">24x7 technical support</h5>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-4 col-md-4">
                  <div
                    className="iconbox style1 right effect-animation"
                    data-animation="zoomIn"
                    data-animation-delay="0.4s"
                    data-animation-offset="75%"
                  
                  >
                    <div className="iconbox-icon"   style={{left: '23px'}}>
                      <span><i className="fas fa-desktop"></i></span>
                    </div>
                    <div className="iconbox-content">
                      <h5 className="title">Absolutely free demo</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
          {/* <button className="primary">whatsapp
     <Link to="wa.me/917249625129?text=I'm%20interested%20in%20your%20car%20for%20sale" />
    </button> 
    <div className="wrap-btn flat-text-center">
                <Link onClick={()=> window.open("https://wa.me/919356146614?text=I'm%20interested%20in%20your%20car%20for%20sale", "_blank")}
                  
                  className="flat-button btn-all-sourses font-Poppins font-weight-700 border-ra4 v2"
                >
                  BROWSE ALL COURSES
                </Link> 
              </div>*/}
          <BottomBar />
        </div>
      </div>
    );
  }
}

export default HomeAnimate;
