const couresBox = [
    {
        id: 1,
        classNames: 'princing-item yellow course style2 one-of-four effect-animation',
        dataanimate: 'zoomIn',
        delay: '0.6s',
        offset: '75%',
        // classNames: 'princing-item red course style2 one-of-four effect-animation',
        srcimg: 'images/courses/server-access.png',
        // title1: 'Server',
        // title2: 'Access',

        title1: 'Self Paced' ,
        title2:  'Training',
        title3: '',
        teacher: 'Tom Steven',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '4.8 (7,982)',
        pricing: '$39.00',
        feature1:'Server Access',
        feature2:'Free Demo session',
        feature3:'Study material',
        feature4:'Interview Preparation',
        feature5:'Trianing Videos'
    },
    {
        id: 2,
        classNames: 'princing-item green course style2 one-of-four effect-animation',
        dataanimate: 'zoomIn',
        delay: '0.4s',
        offset: '75%',
        srcimg: 'images/courses/live-training.png',
        title1: 'Self Paced Training',
        title2: ' with Server',
        title3: ' Access',
        teacher: 'Rosy Janner',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '4.7 (2,632)',
        pricing: '$49.00',
        feature1:'Server Access',
        feature2:'Free Demo session',
        feature3:'Study material',
        feature4:'Interview Preparation',
        feature5:'Trianing Videos',
        space:'&nbsp;'
    },
    {
        id: 3,
        classNames: 'princing-item blue course style2 one-of-four effect-animation',
        dataanimate: 'zoomIn',
        delay: '0.6s',
        offset: '75%',
      
        srcimg: 'images/courses/instructor-led-trainig.png',
        title1: 'Instructor Led ',
        title2: 'Live ',
        title3: 'Training ',
        teacher: 'Tom Steven',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '4.8 (7,982)',
        pricing: '$39.00',
        feature1:'Server Access',
        feature2:'Free Demo session',
        feature3:'Study material',
        feature4:'Interview Preparation',
        feature5:'Trianing Videos'
    },
    {
        id: 4,
        classNames: 'princing-item red course style2 one-of-four effect-animation',
        dataanimate: 'zoomIn',
        delay: '0.6s',
        offset: '75%',
      
        srcimg: 'images/courses/server-access.png',

        title1: 'Server' ,
        title2:  'Access ',
        title3: '',
        teacher: 'Tom Steven',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '4.8 (7,982)',
        pricing: '$39.00',
        feature1:'Server Access',
        feature2:'Free Demo session',
        feature3:'Study material',
        feature4:'Interview Preparation',
        feature5:'Trianing Videos'
    }
]

export default couresBox;