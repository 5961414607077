import React, { Component } from 'react';
import Layout from '../layouts/layout/layout';
import DashboardComponent from '../layouts/MyAccount/DashboardComponent';

class MyAccount extends Component {
 
  render() {
    return (
      <Layout>
        <DashboardComponent />
      </Layout>
    );
  }
}
export default MyAccount;
