import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class Slider extends Component {
    render() {
        return (
            <div className="flat-slider style3">
                <div className="rev_slider_wrapper fullwidthbanner-container">
                    <div id="rev-slider3" className="rev_slider fullwidthabanner" data-version="5.4.8">
                        <ul>
                            <li data-transition="random">
                                <img src="images/slider/slider-3.jpg" alt="bookflare" className="rev-slidebg"
                                    data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat"
                                    data-bgparallax={15} />
                                <div className="overlay" />
                                <div className="tp-caption tp-resizeme font-Poppins font-weight-700 color-fff letter-spaceing-001"
                                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-172','-172','-72','-72']"
                                    data-fontsize="['60','60','40','32']" data-lineheight="['70','60','45','28']" data-width="full"
                                    data-height="none" data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1000}
                                    data-splitin="none" data-splitout="none" data-textalign="['center','center','center','center']"
                                    data-paddingright="['0','0','0','0']" data-responsive_offset="on">Self-Paced Learning Courses
                                    Online</div>
                                <div className="tp-caption tp-resizeme color-fff" data-x="['center','center','center','center']"
                                    data-hoffset="['0','','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['-65','-65','27','27']" data-fontsize="['20','20','16','14']"
                                    data-lineheight="['32','32','22','20']" data-width="full" data-height="none"
                                    data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1000}
                                    data-splitin="none" data-splitout="none" data-responsive_offset="on"
                                    data-textalign="['center','center','center','center']" data-paddingright="['175','0','0','0']"
                                    data-paddingleft="['175','0','0','0']"> We offer professional SEO services that help websites
                                    <span className=" color-fff font-size-20 font-italic text-underline"
                                        data-fontsize="['20','20','16','14']" data-lineheight="['32','32','22','20']">increase their
                                        organic search</span> score drastically in order to compete for the highest rankings.</div>
                                <div className="tp-caption " data-x="['center','center','center','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['56','67','153','123']" data-width="full" data-height="none"
                                    data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1000}
                                    data-splitin="none" data-splitout="none" data-responsive_offset="on"
                                    data-textalign="['center','center','center','center']"><Link to="#"
                                        className="flat-button btn-start-slider border-ra4 v2"> START A COURSE</Link> </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Slider;