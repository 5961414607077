import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class Featured extends Component {
    render() {
        return (
            <section className="flat-featured style3">
                <div className="wrap-featured">
                    <div className="container">
                        <div className="flat-iconbox clearfix style3 ">
                            <div className="iconbox one-of-three style3 flat-text-center left  ">
                                <div className="iconbox-icon">
                                    <span className="icon-contract-1"> <img src="../images/hm-icons/course-management.png" alt="bookflare"/></span>
                                </div>
                                <div className="iconbox-content">
                                    {/* <h6 className="title"><Link to="/courses-grid-v1" onClick={() => {window.location.href="/courses-grid-v1"}}>Course Management</Link></h6>
                                     */}
                                      <h6 className="title"><Link to="#" >1000+ students <br/>trained </Link></h6>
                                </div>
                            </div>
                            <div className="iconbox one-of-three style3 flat-text-center " style={{background:'#1f3a68',color:'#fffff'}}>
                                <div className="iconbox-icon">
                                    <span className="icon-contract-1"> <img src="../images/hm-icons/online-learn-course.png" alt="bookflare"/></span>
                                </div>
                                <div className="iconbox-content">
                                    {/* <h6 className="title"><Link to="/courses-grid-v1" onClick={() => {window.location.href="/courses-grid-v1"}}>Course Management</Link></h6>
                                     */}
                                      <h6 className="title"><Link to="#" style={{color:'#fff'}}>12+ years experienced trainers</Link></h6>
                                </div>
                            </div>
                            <div className="iconbox one-of-three style3 flat-text-center right">
                                <div className="iconbox-icon">
                                    <span className="icon-contract-1"> <img src="../images/hm-icons/teacher-management.png" alt="bookflare"/></span>
                                </div>
                                <div className="iconbox-content">
                                    {/* <h6 className="title"><Link to="/courses-grid-v1" onClick={() => {window.location.href="/courses-grid-v1"}}>Course Management</Link></h6>
                                     */}
                                      <h6 className="title"><Link to="#" >150+ <br/>batches</Link></h6>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Featured;