import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class Slider extends Component {
    render() {
        const clickCourse=()=>{
            window.scrollTo({ top: 0 });
            window.location.href='/sap-ariba-courses';
        }
        return (
            <div className="flat-slider style1">
                <div className="rev_slider_wrapper fullwidthbanner-container">
                    <div id="rev-slider1" className="rev_slider fullwidthabanner" data-version="5.4.8">
                        <ul>
                            <li data-transition="random">
                                <img src="images/slider/banner2.jpg" alt="bookflare" className="rev-slidebg"
                                     />
                                <div className="overlay" />
                                <div className="tp-caption tp-resizeme font-Poppins font-weight-700  color-fff letter-spaceing-001 not-hover"
                                    data-x="['center','center','center','center']" data-hoffset="['10','10','0','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-167','-167','-72','-72']"
                                    data-fontsize="['80','80','60','40']" data-lineheight="['100','100','80','60']"
                                    data-width="full" data-height="none" data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1000}
                                    data-splitin="none" data-splitout="none" data-textalign="['center','center','center','center']"
                                    data-responsive_offset="on">Learning Courses Online</div>
                                <div className="tp-caption tp-resizeme  color-fff flat-text-center "
                                    data-x="['left','left','left','center']" data-hoffset="['-2','-2','-2','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-62','-62','27','27']"
                                    data-fontsize="['20','20','16','14']" data-lineheight="['32','32','22','20']" data-width="full"
                                    data-height="none" data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1400}
                                    data-splitin="none" data-splitout="none" data-responsive_offset="on"
                                    data-textalign="['center','center','center','center']" data-paddingleft="[135, 60, 30, 0]"
                                    data-paddingright="[135, 60, 30, 0]">Our SAP Ariba and SAP Fieldglass online training courses are designed as per 
                                    <span className="color-fff font-size-20 font-italic text-underline not-hover"
                                        data-fontsize="['20','20','16','14']" data-lineheight="['32','32','22','20']"> the Industrial Requirement
                                        </span> with real time projects. Get started online today, and learn from industry experts.</div>
                                <div className="tp-caption" data-x="['left','left','left','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['70','67','123','123']" data-width="full" data-height="none"
                                    data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1800}
                                    data-splitin="none" data-splitout="none" data-responsive_offset="on"
                                    data-paddingtop="['50','50','50','50']" data-paddingbottom="['50','50','50','50']"
                                    data-textalign="['center','center','center','center']">
                                    <Link to="#" onClick={clickCourse} className="flat-button btn-start-slider border-ra4 "> START A COURSE</Link>
                                </div>
                            </li>
                            {/* <li data-transition="random">
                                <img src="images/slider/slider image1263x940.png" alt="bookflare" className="rev-slidebg"
                                     />
                                <div className="overlay" />
                                <div className="tp-caption tp-resizeme font-Poppins font-weight-700  color-fff letter-spaceing-001 not-hover"
                                    data-x="['center','center','center','center']" data-hoffset="['10','10','0','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-167','-167','-72','-72']"
                                    data-fontsize="['80','80','60','40']" data-lineheight="['100','100','80','60']"
                                    data-width="full" data-height="none" data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1000}
                                    data-splitin="none" data-splitout="none" data-textalign="['center','center','center','center']"
                                    data-responsive_offset="on">Learning Courses Online</div>
                                <div className="tp-caption tp-resizeme  color-fff flat-text-center "
                                    data-x="['left','left','left','center']" data-hoffset="['-2','-2','-2','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-62','-62','27','27']"
                                    data-fontsize="['20','20','16','14']" data-lineheight="['32','32','22','20']" data-width="full"
                                    data-height="none" data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1400}
                                    data-splitin="none" data-splitout="none" data-responsive_offset="on"
                                    data-textalign="['center','center','center','center']" data-paddingleft="[135, 60, 30, 0]"
                                    data-paddingright="[135, 60, 30, 0]"> We offer professional SEO services that help websites
                                    <span className="color-fff font-size-20 font-italic text-underline not-hover"
                                        data-fontsize="['20','20','16','14']" data-lineheight="['32','32','22','20']">increase their
                                        organic search</span> score drastically in order to compete for the highest rankings.</div>
                                <div className="tp-caption" data-x="['left','left','left','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['70','67','123','123']" data-width="full" data-height="none"
                                    data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1800}
                                    data-splitin="none" data-splitout="none" data-responsive_offset="on"
                                    data-paddingtop="['50','50','50','50']" data-paddingbottom="['50','50','50','50']"
                                    data-textalign="['center','center','center','center']">
                                    <Link to="#" className="flat-button btn-start-slider border-ra4 "> START A COURSE</Link>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Slider;