import React from 'react';
import Layout from '../layouts/layout/layout';
import AribaCoursesComponent from '../layouts/AribaCourses/AribaCoursesComponent';

export default function AribaCourses(props) {
  return (
    <div>
      <Layout >
        <AribaCoursesComponent  />
      </Layout>
    </div>
  );
}
