import React, { useEffect, useState } from 'react';
import httpService from '../../../HttpService/httpService';
import { BottomBar, Footer, TopBar, Header } from '../general';
import { useHistory } from 'react-router';
import { createContext } from 'react';

const CartContext = createContext();

export default function Layout(props) {
  const [cartdata, setCartdata] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const courseData = JSON.parse(localStorage.getItem('sap-cart-list'));
  const cartDataBeforeLogin = courseData ? courseData : [];
  const token = localStorage.getItem('token');
  const customerId = localStorage.getItem('customerId');
  const wishlistData = JSON.parse(localStorage.getItem('sap-wishlist'));
  const wishlistBeforeLogin = wishlistData ? wishlistData : [];

  const history = useHistory();

  useEffect(() => {
    if (token) {
      httpService
        .get(`/customer/carts/${customerId}/`)
        .then((carts) => {
          console.log(carts.data.cartData);
          setCartdata(carts.data.cartData ? carts.data.cartData : []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [customerId, token, history]);

  useEffect(() => {
    if (token) {
      httpService
        .get(`/customer/wishlist/${customerId}`)
        .then((res) => {
          setWishlist(res.data.wishlist ? res.data.wishlist : []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [customerId, token, history]);

  return (
    <>
      <CartContext.Provider
        value={{
          token,
          cartdata,
          cartDataBeforeLogin,
          wishlist,
          wishlistBeforeLogin,
        }}
      >
        <TopBar />
        <Header />
        {props.children}
        <Footer />
        <BottomBar />
      </CartContext.Provider>
    </>
  );
}

export { CartContext };
