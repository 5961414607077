import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import httpService from "../../../HttpService/httpService";
import Modal from "react-modal";
import UpdateProfile from "./UpdateProfile";


Modal.setAppElement("#root");
const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    //padding:'50px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1f3a68",
    "&:hover": {
      background: "#1f3a68",
      color: "#f7c13a",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "theme.palette.background.paper",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
  formControl: {
    margin: "0px",
    minWidth: 120,
  },

  formField: {
    padding: "5px 0px 10px 0px",
  },

  headlogin: {
    fontSize: "1.875rem",
    fontWeight: "700",
    lineHeight: "2.25rem",
    padding: "10px 0 10px 0",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  headlogin: {
    fontSize: '1.875rem',
    fontWeight: '700',
    lineHeight: '2.25rem',
    padding:'10px 0 10px 0'
  },

  formField: {
    //backgroundColor:'green',
    "& .MuiInputBase-root": {
      //backgroundColor:'blue',
      "& .MuiInputBase-input": {
        backgroundColor: "#fafafa",
        color: "#878787",
      },
    },
  },
  profiledata: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    fontSize: "16px",
  },
  values: {
    paddingLeft: "6px",
  },
  updateBtn:{
    justifyContent:'center'
  }
}));
export default function MyProfileComponent() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [customer, setCustomer] = useState([]);

  const customerId = localStorage.getItem("customerId");
  console.log(customerId);
  useEffect(() => {
    httpService
      .get(`/customer/${customerId}`)
      .then((res) => {
        //console.log("profile", res.data);
        setCustomer(res.data.customer);
      })
      .catch((err) => {
        console.log("errors", err.response.data.message);
      });
  }, []);


  const toggleModal = (customer) => {
    setIsOpen(!isOpen);

  };

  return (
    <Container component="main" maxidth="md" className="">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {customer.map((Custdata) => (
            <Card className={classes.root}>
              <CardHeader
                title={
                
                  <Typography component="h2" variant="h2" className={classes.headlogin}>
                   My Profile
                   </Typography>
                }
              />
             
              <CardContent>
              <Grid >
                <Grid className={classes.profiledata}>
                  <Typography
                    variant="h6"
                    component="h5"
                    className={classes.name}
                  >
                    Name:
                  </Typography>
                  <Typography className={classes.values}>
                    {Custdata.first_name} {Custdata.last_name}{" "}
                  </Typography>
                </Grid>
                <Grid className={classes.profiledata}>
                  <Typography variant="h6" component="h5"  className={classes.name}>
                    Mobile:
                  </Typography>
                  <Typography className={classes.values}>
                    {Custdata.mobile}    {' '}
                    
                  </Typography>
                </Grid>
                <Grid className={classes.profiledata}>
                  <Typography
                    variant="h6"
                    component="h5"
                    className={classes.name}
                  >
                    Country:
                  </Typography>
                  <Typography className={classes.values}>
                    {Custdata.country_name} {" "}
                  </Typography>
                </Grid>
                </Grid>
              </CardContent>
              <CardActions disableSpacing  className={classes.updateBtn}>    
              <Grid item xs={12} md={6} >
                     <Button
              
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={toggleModal}
         
            >
            Update 
            </Button>
            </Grid>
            <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <UpdateProfile/>
        {/* <button onClick={toggleModal}>Close modal</button> */}
      </Modal></CardActions>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
}
