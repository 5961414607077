import React, { Component } from 'react';
import { Header, TopBar , Paramater, Footer, BottomBar, Loader } from '../layouts/general';
import { BannerComponent, RelatedProduct, SingleProduct } from '../layouts/pages';

class ShopSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          names: 'Pages',
          names02: 'Shop',
        },
      ],
      dataBanner: [
        {
          id: 1,
          classename01: 'flat-title-page parallax parallax6 style3',
          classename02: 'container wrap-title-page ',
          links: '/shop-single',
          names: 'Shop Single',
        },
      ],
    };
  }
  render() {
    return (
      <div className="bg-body2">
        <div className="boxed">
          <Loader />
          <TopBar />
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}
          {this.state.dataBanner.map((data) => (
            <BannerComponent data={data} key={data.id} />
          ))}
          <div className="content-wrap shop-single-page">
            <div className="container">
              <div className="content-page-wrap">
                <SingleProduct />
              </div>
            </div>
          </div>
          <RelatedProduct />
          <section className="flat-feature parallax parallax2">
            <div className="container">
              <Paramater />
            </div>
          </section>
          <Footer />
          <BottomBar />
        </div>
      </div>
    );
  }
}

export default ShopSingle;