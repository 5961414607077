import React, { Component } from 'react';
import { BottomBar, Footer, Header, TopBar, Loader } from '../layouts/general';
import {  BannerComponent } from '../layouts/pages';
import { ContactForm,ContactForm2} from '../layouts/homeanimate';
import { Featured, Slider, FormApply,TabCourses } from '../layouts/home01';
//import { CourseBox, TitleHeading, Instructor, EventBoxs } from '../layouts/homeanimate';
import { Link } from 'react-router-dom';
import { render } from '@testing-library/react';

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          name: 'pages',
        },
      ],
      dataBanner: [
        {
          id: 1,
          classename01: 'flat-title-page  contact style3',
          classename02: 'container wrap-title-page ',
          links: '/contact-us',
          names: 'Contact Us',
        },
      ],
    };
  }

  render() {
    return (
      <div className="bg-body2">
        <div className="boxed">
          <Loader />
          <TopBar />
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}
          {this.state.dataBanner.map((data) => (
            <BannerComponent data={data} key={data.id} />
          ))}
          <section className="flat-register-now">
           
            <div className="overlay" />
            {/* <p style={{textAlign:'center',marginBottom:'20px',color:'#fff'}}>We’re committed to changing the future of learning for the better.   Join us today and build your career in SAP.</p> */}
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-5 effect-animation"
                  data-animation="fadeInLeft"
                  data-animation-delay="0.2s"
                  data-animation-offset="75%"
                >
                  <ContactForm />
                </div>
                <div
                  className="col-lg-7 effect-animation"
                  data-animation="fadeInRight"
                  data-animation-delay="0.2"
                  data-animation-offset="75%"
                >
                  <ContactForm2 />
                </div>
              </div>
            </div>
          </section>
          <Footer />
          <BottomBar />
        </div>
      </div>
    );
  }
}
export default ContactUs;