const menus = [
  {
    id: 1,
    name: 'HOME',
    //linkmenu: '/home-animate',
    linkmenu: '/home',
  },
  {
    id: 2,
    name: 'ABOUT US',
    linkmenu: '/about-us',
    namesub: undefined,
  },
  {
    id: 3,
    name: 'SAP ARIBA COURSE',
    linkmenu: '/sap-ariba-courses/1',
    namesub: undefined,
  },
  {
    id: 4,
    name: 'SAP FIELDGLASS COURSE',
    linkmenu: '/sap-fieldglass-courses/2',
    namesub: undefined,
  },
  {
    id: 5,
    name: 'CONTACT US',
    linkmenu: '/contact-us',
    namesub: undefined,
  },
];
// const menusWithMyAcc = [
//   {
//     id: 1,
//     name: 'HOME',
//     linkmenu: '/home-animate',
//   },
//   {
//     id: 2,
//     name: 'ABOUT US',
//     linkmenu: '/about-us',
//     namesub: undefined,
//   },
//   {
//     id: 3,
//     name: 'SAP ARIBA COURSE',
//     linkmenu: '/sap-ariba',
//     namesub: undefined,
//   },
//   {
//     id: 4,
//     name: 'SAP FIELDGLASS COURSE',
//     linkmenu: '/instructors',
//     namesub: undefined,
//   },
//   {
//     id: 5,
//     name: 'CONTACT US',
//     linkmenu: '/contact-us',
//     namesub: undefined,
//   },
//   {
//     id: 6,
//     name: 'MY ACCOUNT',
//     linkmenu: '/my-account',
//     namesub: undefined,
//   },

// ]

// export default {menus,menusWithMyAcc};
export default menus;
