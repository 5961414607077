import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/hoc/privateRoute";
import Cart from "./components/layouts/pages/cart";
import Checkout from "./components/layouts/pages/Checkout";
import AboutUs from "./components/pages/AboutUs";
import Blog from "./components/pages/Blog";
import BlogSingle from "./components/pages/BlogSingle";
import Comparision from "./components/pages/Comparision";
import ContactUs from "./components/pages/ContactUs";
import CourseSingle from "./components/pages/CourseSingle";
import CourseV1 from "./components/pages/CourseV1";
import CourseV2 from "./components/pages/CourseV2";
import CourseV3 from "./components/pages/CourseV3";
import EventSingle from "./components/pages/EventSingle";
import EventV1 from "./components/pages/EventV1";
import EventV2 from "./components/pages/EventV2";
import Faqs from "./components/pages/Faqs";
import Home01 from "./components/pages/Home01";
import Home02 from "./components/pages/Home02";
import Home03 from "./components/pages/Home03";
import HomeAnimate from "./components/pages/HomeAnimate";
import IntructorPage from "./components/pages/IntructorPage";
import Login from "./components/pages/Login";
import MyAccount from "./components/pages/MyAccount";
import Registeration from "./components/pages/Registeration";
import SapAriba from "./components/pages/SapAriba";
import AribaCourses from "./components/pages/AribaCourses";
import FieldglassCourses from "./components/pages/FieldglassCourses";
import Shop from "./components/pages/Shop";
import ShopSingle from "./components/pages/ShopSingle";
import pagePath from "./Constants/pagePath";
import MyProfile from "./components/layouts/MyAccount/MyProfileComponent";

import Wishlist from './components/layouts/pages/wishlist';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <HomeAnimate />
        </Route>
        <Route path={pagePath.HomeAnimate}>
          <HomeAnimate />
        </Route>
        <Route path={pagePath.home01}>
          <Home01 />
        </Route>
        <Route path={pagePath.home02}>
          <Home02 />
        </Route>
        <Route path={pagePath.home03}>
          <Home03 />
        </Route>
        <Route path={pagePath.SapAriba}>
          <SapAriba />
        </Route>
        <Route path={pagePath.AribaCourses}>
          <AribaCourses />
        </Route>
        <Route path={pagePath.FieldglassCourses}>
          <FieldglassCourses />
        </Route>
        <Route path={pagePath.CourseV1}>
          <CourseV1 />
        </Route>
        <Route path={pagePath.CourseV2}>
          <CourseV2 />
        </Route>
        <Route path={pagePath.CourseV3}>
          <CourseV3 />
        </Route>
        <Route path={pagePath.CourseSingle}>
          <CourseSingle />
        </Route>
        <Route path={pagePath.IntructorPage}>
          <IntructorPage />
        </Route>
        <Route path={pagePath.EventV1}>
          <EventV1 />
        </Route>
        <Route path={pagePath.EventV2}>
          <EventV2 />
        </Route>
        <Route path={pagePath.EventSingle}>
          <EventSingle />
        </Route>
        <Route path={pagePath.Faqs}>
          <Faqs />
        </Route>
        <Route path={pagePath.AboutUs}>
          <AboutUs />
        </Route>
        <Route path={pagePath.ContactUs}>
          <ContactUs />
        </Route>
        <Route path={pagePath.Comparision}>
          <Comparision />
        </Route>
        <Route path={pagePath.Registeration}>
          <Registeration />
        </Route>
        <Route path={pagePath.Shop}>
          <Shop />
        </Route>
        <Route path={pagePath.ShopSingle}>
          <ShopSingle />
        </Route>
        <Route path={pagePath.Blog}>
          <Blog />
        </Route>
        <Route path={pagePath.BlogSingle}>
          <BlogSingle />
        </Route>
        <Route path={pagePath.BlogSingle}>
          <BlogSingle />
        </Route>
        <Route path={pagePath.Cart}>
          <Cart />
        </Route>
        <Route path={pagePath.Checkout}>
          <Checkout />
        </Route>
        <Route path={pagePath.Login}>
          <Login />
        </Route>
        <Route path={pagePath.MyProfile}>
          <MyProfile />
          </Route>
        <Route path={pagePath.wishlist}>
          <Wishlist />
        </Route>
        <PrivateRoute
          path={pagePath.account}
          component={MyAccount}
        ></PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
