import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import CourseCard from './CourseCard';
import CourseCard from '../AribaCourses/CourseCard';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  myClassName: {
    position: 'relative',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  mainCon: {
    paddingTop: '40px',
    paddingBottom: '40px',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
}));

export default function FieldCoursesComponent() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Container
        component="main"
        maxWidth="xl"
        className=""
        justifycontent="center"
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={classes.mainCon}
        >
          <CourseCard  />
        </Grid>
      </Container>
    </div>
  );
}
