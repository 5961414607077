import React from "react";
import Layout from "../layouts/layout/layout";
import FieldCoursesComponent from "../layouts/FieldCourses/FieldCoursesComponent";
export default function FieldglassCourses() {
  return (
    <div>
      <Layout>
        <FieldCoursesComponent />
      </Layout>
    </div>
  );
}
