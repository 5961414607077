import React, { useContext, useState } from 'react';
import {
  makeStyles,
  CardContent,
  Typography,
  Button,
  Card,
  IconButton,
  Grid,
  Avatar,
} from '@material-ui/core/';

import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import httpService from '../../../HttpService/httpService';
import CustomizedSnackbars, { IMG_URL } from '../../../Constants/config';
import { CartContext } from '../layout/layout';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '80%',
    maxWidth: '100%',
    margin: '0 auto',
    marginTop: '2%',
    height: 'auto',
    borderRadius: '0px',
    '&.MuiCardActionArea-root ': {
      backgroundColor: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
      height: 'auto',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 'auto',
    },
  },
  cardActionArea: {
    display: 'flex',
    height: 'auto',
    width: '100%',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 'auto',
    },
    [theme.breakpoints.only('md')]: {
      height: 'auto',
    },
  },
  avatar: {
    height: '80px',
    width: '125px',
    margin: '10px',
    borderRadius: '0px',
    padding: '0px',
    '&:hover': {
      background: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      height: '80px',
      width: '80px',
      margin: '5px',
    },
  },
  courseTitle: {
    textTransform: 'none',
    fontSize: '14px',
    fontFamily: 'inherit',
    color: 'black',
    lineHeight: '13px',
    marginTop: '5px',
    [theme.breakpoints.only('sm')]: {
      marginTop: '10px',
    },
  },

  discountPriceText: {
    fontSize: '18px',
    textAlign: 'center',
    textDecoration: ' line-through',
    fontFamily: `'Abril Fatface', cursive'`,
    textTransform: 'none',
    color: 'black',
  },
  priceAndDiscont: {
    display: 'flex',
    flexDirection: 'column',
  },
  priceTag: {
    padding: ' 0px !important',
    marginTop: '2px',
    height: '5px',
    width: '5px',
    color: '#1F3A68',
    '&:hover': {
      background: 'none',
      color: '#1F3A68',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '6px',
      height: '27px',
      marginTop: '-10px',
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: '6px',
      height: '27px',
      marginTop: '-10px',
    },
    [theme.breakpoints.only('sm')]: {
      marginLeft: '5px',
      marginBottom: '13px',
    },
  },
  cardContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  cardButtons: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      marginTop: '5px',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      height: '20px',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      display: 'flex',
      maxHeight: '50px',
      flexDirection: 'column',
    },
  },
  cardButton: {
    color: '#1F3A68',
    textTransform: 'none',
    fontFamily: 'inherit',
    fontSize: '14px',
    margin: '0px',
    padding: '0px',
    '&:hover': {
      background: 'none',
      color: '#1F3A68',
    },
    [theme.breakpoints.down('xs')]: {
      height: '20px',
      fontSize: '12px',
      justifyContent: 'flex-start',
    },
    '& .MuiButton-label': {
      width: '100%',
      display: 'inherit',
      alignItems: 'inherit !important',
      justifyContent: 'inherit !important',
    },
  },
  priceGrid: {
    paddingBottom: '0px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      paddingBottom: '0px',
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '15px',
      marginLeft: '10px',
    },
  },
  coursePrices: {
    fontSize: '18px',
    padding: '0px !important',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '18px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0px',
    },
  },
  coursePriceText: {
    fontSize: '18px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginRight: '5px',
    },
  },
  cardContentBox: {
    width: '100%',
    paddingBottom: '0px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '0px',
    },
  },
  coursePriceBox: {
    marginTop: '0px',
    [theme.breakpoints.down('xs')]: {
      height: '26px',
      marginLeft: '0px',
      width: '100%',
    },
  },
}));

export default function CartComponent(props) {
  const classes = useStyles();
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const [snackbar, setSnackbar] = useState(false);
  const {
    token,
    cartdata = [],
    cartDataBeforeLogin = [],
  } = useContext(CartContext) || {};

  const customerId = localStorage.getItem('customerId');

  const handleRemove = (courseId) => {
    if (!token) {
      const cartAfterRemoving = cartDataBeforeLogin.filter(
        (a) => a.id === courseId
      );
      localStorage.setItem('sap-cart-list', JSON.stringify(cartAfterRemoving));
      setSeverity('success');
      setMessage('Course removed from cart');
      setSnackbar(true);
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } else {
      httpService
        .patch(`/customer/cart/remove/${customerId}/${courseId}`)
        .then((res) => {
          setSeverity('success');
          setMessage(res.data.message);
          setSnackbar(true);
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        })
        .catch((err) => {
          setSeverity('error');
          setMessage(err.response.data.message);
          setSnackbar(true);
        });
    }
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  const addToWishlistAfterLogin = (customerId, courseId) => {
    httpService
      .post('/customer/addto/wishlist', { customerId, courseId })
      .then((res) => {
        setMessage(res.data.message);
        setSeverity('success');
        setSnackbar(true);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setSeverity('error');
        setSnackbar(true);
      });
  };

  const addToWishlist = async (courseData) => {
    if (token) {
      await addToWishlistAfterLogin(customerId, courseData.course_id);
    } else {
      const wishlistExistingData = JSON.parse(
        localStorage.getItem('sap-wishlist')
      );
      if (wishlistExistingData) {
        const wishlistExisting = wishlistExistingData.filter(
          (cart) => cart.id === courseData.id
        );
        if (wishlistExisting.length > 0) {
          setSnackbar(true);
          setSeverity('warning');
          setMessage('Course is already present in your wishlist');
        } else {
          let existingData = [];
          existingData.push(...wishlistExistingData, courseData);
          localStorage.setItem('sap-wishlist', JSON.stringify(existingData));
        }
      } else {
        localStorage.setItem('sap-wishlist', JSON.stringify([courseData]));
      }
    }
  };

  return (token ? cartdata : cartDataBeforeLogin).map((course, idx) => (
    <div key={idx}>
      <CustomizedSnackbars
        open={snackbar}
        closeSnackbar={closeSnackbar}
        severity={severity}
        message={message}
      />
      <Card className={classes.root}>
        <Grid item xs={12} className={classes.cardActionArea}>
          <Avatar
            alt="course image"
            src={`${IMG_URL}/${course.image_path}`}
            className={classes.avatar}
          />
          <CardContent className={classes.cardContentBox}>
            <Grid container item xs={12} className={classes.cardContainer}>
              <Grid item xs={12} md={8} sm={12}>
                <Typography className={classes.courseTitle}>
                  {course.category_name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} sm={8} className={classes.cardButtons}>
                <Button
                  size="small"
                  color="primary"
                  className={classes.cardButton}
                  onClick={() => handleRemove(course.course_id)}
                >
                  Remove
                </Button>
                <Button
                  size="small"
                  color="primary"
                  className={classes.cardButton}
                  onClick={() => addToWishlist(course)}
                >
                  Add To Wishlist
                </Button>
              </Grid>
              <Grid item xs={12} md={2} sm={12} className={classes.priceGrid}>
                <Grid
                  container
                  item
                  spacing={2}
                  xs={12}
                  sm={12}
                  className={classes.coursePriceBox}
                >
                  <Grid item xs={6} sm={6} className={classes.coursePrices}>
                    <Typography className={classes.coursePriceText}>
                      {course.price}
                    </Typography>
                    <Typography className={classes.discountPriceText}>
                      {course.discount}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <IconButton className={classes.priceTag}>
                      <LocalOfferIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Card>
    </div>
  ));
}
