import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  TextField, FormControl,InputLabel,Select,  MenuItem,Typography, Container, Grid,} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { checkValidity } from '../../../Shared';
import httpService from '../../../HttpService/httpService';
import CustomizedSnackbars from '../../../Constants/config';

  const useStyles = makeStyles((theme) => ({
   
    headlogin: {
      fontSize: '1.875rem',
      fontWeight: '700',
      lineHeight: '2.25rem',
      padding:'10px 0 10px 0'
    },
    
  }));
export default function WelcomeDashboard()
{
  const classes = useStyles();
    return(

          <Grid  component="main" maxwidth="sm" container display="flex" justifyContent="center" > 
       
          <Grid item sm={12} >
          <Grid className="form-myprofile"  >
          <Typography component="h2" variant="h2" className={classes.headlogin}>
          Welcome to  SAP Ariba and Fieldglass
          </Typography>
          </Grid>
          
     
          </Grid>
          </Grid>
        
    );
}