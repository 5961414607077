import React, { Component } from 'react';
import { Link } from 'react-router-dom'
class SingleProduct extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-lg-8 col-md-12">
                    <div className="content-page-wrap">
                        <div className="flat-img-single-slider">
                            <div className="flat-carousel" data-gap={0} data-auto="true" data-column={1} data-column2={1}
                                data-column3={1} data-loop="true" data-nav="true" data-dots="false">
                                <div className="img-single-slider owl-carousel">
                                    <div className="img-slider ">
                                        <Link to="#"><img src="images/shop/img-big.jpg" alt="bookflare" /></Link>
                                    </div>
                                    <div className="img-slider ">
                                        <Link to="#"><img src="images/shop/img-big.jpg" alt="bookflare" /></Link>
                                    </div>
                                    <div className="img-slider ">
                                        <Link to="#"><img src="images/shop/img-big.jpg" alt="bookflare" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="heading">
                                    <h6 className="title"><Link to="#">Just Enough Research</Link></h6>
                                </div>
                                <div className="author">
                                    <div className="author-avata">
                                        <img src="images/events/author-teacher.jpg" alt="bookflare" />
                                    </div>
                                    <div className="author-info">
                                        <h4 className="name"><Link to="#">Charlotte H. Bronk</Link></h4>
                                        <div className="wrap-list">
                                            <ul className="list meta-list">
                                                <li>
                                                    <span className="title">Categories:</span>
                                                    <Link to="#"><span> Software </span></Link>
                                                </li>
                                                <li className="tags">
                                                    <span className="title">Tags:</span>
                                                    <Link to="#"><span>Software</span></Link><Link to="#"><span> Ebook</span></Link>
                                                </li>
                                            </ul>
                                            <ul className="list meta-rate">
                                                <li>
                                                    <ul className="list star-list">
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                    </ul>
                                                    <span className="font-weight-700">5.0 (980) </span>
                                                </li>
                                                <li>
                                                    <span className="font-weight-700"> Sep 07, 2018</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 flat-text-right shop-single">
                                <div className="price-wrap">
                                    <span className="sale-price color-f3728b">$19.99</span>
                                    <span className="price">$199.99</span>
                                </div>
                                <div className="wrap-btn">
                                    <Link to="#" className="flat-button btn-cart v2">Add To Cart</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff">
                                    <ul className="tab-title style1 clearfix border-bt-e6f3ff">
                                        <li className="item-title  overview">
                                            <span className="inner">OVERVIEW</span>
                                        </li>
                                        <li className="item-title event">
                                            <span className="inner">DESCRIPTION</span>
                                        </li>
                                        <li className="item-title review">
                                            <span className="inner">REVIEW</span>
                                        </li>
                                    </ul>
                                    <div className="tab-content-wrap">
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="text-wrap">
                                                    <h6 className="title"><Link to="#">Limitless Learning, Limitless
                                                            Possibilities</Link></h6>
                                                    <p className="text">Lorem Ipsn gravida nibh vel velit auctor aliquet. Aenean
                                                        sollicitudin, lorem quis bibendum auci elit consequat ipsutis sem nibh id
                                                        elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi
                                                        accum dolor same mipsum rvelit. </p>
                                                    <p className="text">The standard chunk of Lorem Ipsum used since the 1500s is
                                                        reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de
                                                        Finibus Bonorum ealorum" by Cicero areexact original form accompanied by
                                                        English versions. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="img-single-small flat-text-center">
                                                    <img src="images/events/img-small.png" alt="bookflare" />
                                                </div>
                                                <div className="text-wrap">
                                                    <h6 className="title"><Link to="#">Limitless Learning, Limitless
                                                            Possibilities</Link></h6>
                                                    <p className="text">Lorem Ipsn gravida nibh vel velit auctor aliquet. Aenean
                                                        sollicitudin, lorem quis bibendum auci elit consequat ipsutis sem nibh id
                                                        elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi
                                                        accum dolor same mipsum rvelit. </p>
                                                </div>
                                                <div className="text-wrap">
                                                    <h6 className="title"><Link to="#">About This Event</Link></h6>
                                                    <p className="text">Per inceptos himenaeos. Mauris in erat justo. Nullam ac urna
                                                        eu felis dapibus condimentum sit amet a augue. Sed non mauris vitae erat
                                                        consequat auctor eu in elit. Class aptent tacitio sociosqu ad litora
                                                        torquent.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <h1>No Review</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <div className="sibar-right">
                        <div className="product-item transition-img-socails border-f-e6f3ff">
                            <div className="product-img transition-img">
                                <img src="images/sidebar/img-sibar-1.jpg" alt="bookflare" />
                                <div className="overlay" />
                                <div className="socails">
                                    <ul className="list">
                                        <li><Link to="#"><span className="icon-search" /></Link></li>
                                        <li><Link to="#"><span className="icon_heart_alt" /></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="product-content flat-text-center border-bt-e6f3ff">
                                <h6 className="title"><Link to="/shop-single" onClick={() => {window.location.href="/shop-single"}}>Accessibility for Everyone</Link></h6>
                                <div className="price-wrap">
                                    <span className="sale-price">$19.99 </span><span className="price"> $99.99</span>
                                </div>
                            </div>
                            <div className="wrap-btn flat-text-center">
                                <Link to="#" className="flat-button btn-cart">ADD TO CART</Link>
                            </div>
                        </div>
                        <div className="product-item transition-img-socails border-f-e6f3ff">
                            <div className="product-img transition-img">
                                <img src="images/sidebar/img-sibar-2.jpg" alt="bookflare" />
                                <div className="overlay" />
                                <div className="socails">
                                    <ul className="list">
                                        <li><Link to="#"><span className="icon-search" /></Link></li>
                                        <li><Link to="#"><span className="icon_heart_alt" /></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="product-content flat-text-center border-bt-e6f3ff">
                                <h6 className="title"><Link to="/shop-single" onClick={() => {window.location.href="/shop-single"}}>JavaScript for Web Designers</Link></h6>
                                <div className="price-wrap">
                                    <span className="sale-price">$13.99 </span><span className="price"> $49.99</span>
                                </div>
                            </div>
                            <div className="wrap-btn flat-text-center">
                                <Link to="#" className="flat-button btn-cart">ADD TO CART</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SingleProduct;