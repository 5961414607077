import React, { Component,Fragment } from 'react';
import {Link} from 'react-router-dom'
import datacourses from '../homeanimate/dataBox'
class CoursesBox extends Component {
    render() {
        return (
         
<div className=" flat-courses">
      <div className="row m-auto text-center">
      {
        datacourses.map(data =>(
               
        <div className={data.classNames} data-animation={data.dataanimate} data-animation-delay={data.delay} data-animation-offset={data.offset} key={data.id}>
            <div className="pricing-divider ">
                <h4 className="text-light">{data.title1}<br/>{data.title2}<br/><span className={data.title3}/></h4>
                 <h4 className="my-0 display-4 text-light font-weight-normal mb-3"><span className="h3">$</span> 250 <span className="h5">/mo</span></h4>
                <svg className='pricing-divider-img' enableBackground='new 0 0 300 100' height='100px' id='Layer_1' preserveAspectRatio='none' version='1.1' viewBox='0 0 300 100' width='300px' x='0px' xmlSpace='preserve' xlinkHref='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' y='0px'>
                    <path className='deco-layer deco-layer--1' d='M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
                    c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z' fill='#FFFFFF' opacity='0.6'></path>
                        <path className='deco-layer deco-layer--2' d='M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
                    c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z' fill='#FFFFFF' opacity='0.6'></path>
                        <path className='deco-layer deco-layer--3' d='M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
                    H42.401L43.415,98.342z' fill='#FFFFFF' opacity='0.7'></path>
                        <path className='deco-layer deco-layer--4' d='M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
                    c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z' fill='#FFFFFF'></path>
                </svg>
            </div>

            <div className="card-body bg-white mt-0 shadow">
                <ul className="list-unstyled mb-5 position-relative">
                <li>{data.feature1}</li>
                <li>{data.feature2}</li>
                <li>{data.feature3}</li>
                <li>{data.feature4}</li>
                </ul>
                <Link to="#" ><button className="btn btn-lg btn-block  btn-custom font-weight-700 border-ra4 v2">Enroll Now</button></Link>
              
            </div>
        </div>
         
        
        
        ))
    }
        
</div>
</div>
            
        );
    }
}

export default (CoursesBox);