import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import dataEvent from './dataEvent';

class CardEventComponent extends Component {
    render() {
        return (
            <div className="flat-events style2">
                <div className="row">
                    {
                        dataEvent.map( data => (
                            <div className="col-lg-4 col-md-6 col-sm-12" key={data.id}>
                                <div className="event style2 border-f-e6f3ff">
                                    <div className="event-img">
                                        <Link to="#"><img src={data.imgsrc} alt="bookflare" /></Link>
                                    </div>
                                    <div className="event-content">
                                        <h6 className="title"><Link to="/event-single" onClick={() => {window.location.href="/event-single"}}>{data.title}</Link></h6>
                                        <div className="meta-list">
                                            <ul className="list">
                                                <li className="time"><Link to="#">{data.time}</Link></li>
                                                <li className="address"><Link to="#">{data.address}</Link></li>
                                            </ul>
                                        </div>
                                        <div className="meta-date flat-text-center">
                                            <span className="day">{data.day}</span>
                                            <span className="month">{data.month}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default CardEventComponent;