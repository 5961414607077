import React, { Component } from 'react';

class TitleHeading extends Component {
  render() {
    return (
      <div
        className="flat-title flat-text-center  effect-animation"
        data-animation="zoomIn"
        data-animation-delay="0"
        data-animation-offset="75%"
      >
        <h2 className={this.props.data.classNameTitle}>
          {this.props.data.title}
        </h2>
        <p className={this.props.data.classNameDescription}>
          {this.props.data.description}
        </p>
      </div>
    );
  }
}

export default TitleHeading;
