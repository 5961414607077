const pagePath = {
  HomeAnimate: "/home",
  account: "/my-account",
  home01: "/home-01",
  home02: "/home-02",
  home03: "/home-03",

  SapAriba: "/sap-ariba",
  AribaCourses: "/sap-ariba-courses/:id",
  FieldglassCourses: "/sap-fieldglass-courses/:id",
  MyProfile: "/my-account/my-profile",

  CourseV1: "/courses-grid-v1",
  CourseV2: "/courses-grid-v2",
  CourseV3: "/courses-grid-v3",
  CourseSingle: "/courses-single",
  IntructorPage: "/instructors",
  EventV1: "/event-v1",
  EventV2: "/event-v2",
  EventSingle: "/event-single",
  Faqs: "/faq",
  AboutUs: "/about-us",
  ContactUs: "/contact-us",
  Comparision: "/comparision",
  Login: "/login",
  Registeration: "/registeration",
  Shop: "/shop",
  ShopSingle: "/shop-single",
  Blog: "/blog",
  BlogSingle: "/blog-single",
  Cart: "/cart",
  Checkout: "/checkout",
  wishlist: '/wishlist',
};
export default pagePath;
