import { Button, Typography, Card, Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link, withRouter, Route, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
class TopBar extends Component {
  render() {
    const linkClickHandleReg = () => {
      window.scrollTo({ top: 0 });
      window.location.href = "/registeration";
    };
    const linkClickHandleLogin = () => {
      window.scrollTo({ top: 0 });
      window.location.href = "/login";
    };
    const linkClickHandleLogout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("customer_name");
      //  window.location.href='/login';
      window.location.reload();
    };

    return (
      <div className="top border-bt-d1d1ff style2">
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="flat-info">
                <ul>
                  <li className="phone">
                    <Link to="#">+123 345 678 000</Link>
                  </li>
                  <li className="mail">
                    <Link to="#">info@aribafieldglassonlinetrainig.com</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 pd-left-0">
              {/* <div className="flat-adds flat-text-right">
                <p>
                  Enjoy A New Student Discount All{" "}
                  <span className="font-Roboto font-weight-700 underline-text">
                    55,000 Courses
                  </span>{" "}
                  on sale for{" "}
                  <span className="font-Hall font-weight-700 letter-spaceing-top">
                    Only{" "}
                  </span>{" "}
                  <span className="font-Playfair font-weight-700 letter-spaceing-top">
                    $12.99{" "}
                  </span>{" "}
                </p>
                <p className="font-weight-700 datetime">Ends in 05h 23m 49s </p>
              </div> */}
              <div className="flat-action flat-text-right style1">
                <>
                  {localStorage.getItem("token") ? (
                    <Grid className="topSignout">
                      <Grid style={{ color: "#fff" }}>
                        <Grid className="dropdown">
                          <Grid className="dropbtn">
                            <i
                              className="fas fa-user-circle"
                              style={{
                                float: "left",
                                color: "white",
                                fontSize: "18px",
                              }}
                            ></i>
                          </Grid>
                          {/* <button class="dropbtn">Dropdown</button> */}
                          <Grid className="dropdown-content">
                            <i
                              className="fa fa-sign-out"
                              style={{ color: "black", fontSize: "18px" }}
                            ></i>
                            <Link to="#" onClick={linkClickHandleLogout}>
                              Sign Out
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid>
                        {
                          <Grid style={{ color: "#fff" }}>
                            <Typography style={{ color: "#fff" }}>
                              {localStorage.getItem("customer_name")}{" "}
                            </Typography>
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                  ) : (
                    <ul>
                      <li>
                        <Link onClick={linkClickHandleReg} to="/registeration">
                          REGISTER
                        </Link>
                      </li>
                      <li>
                        <Link onClick={linkClickHandleLogin} to="/login">
                          LOGIN
                        </Link>
                      </li>
                    </ul>
                  )}
                </>
                {/* <ul>
                                <li><Link onClick={linkClickHandleReg} to="/registeration">REGISTER</Link></li>
                                <li><Link onClick={linkClickHandleLogin}  to="/login">LOGIN</Link></li> 
                                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
