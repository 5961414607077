import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class CoursesBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCourse: [
                {
                    id: 1,
                    srcimg: 'images/courses/courses-index2-2.jpg',
                    ratting: [
                        {
                            id: 1,
                            icon: 'far fa-star'
                        },
                        {
                            id: 2,
                            icon: 'far fa-star'
                        },
                        {
                            id: 3,
                            icon: 'far fa-star'
                        },
                        {
                            id: 4,
                            icon: 'far fa-star'
                        },
                        {
                            id: 5,
                            icon: 'far fa-star'
                        },
                    ],
                    count: '5.0 (572)',
                    teacher: 'Demyan Rostislav',
                    title: 'Introduction Web Design With HTML',
                    description:'Rellus integer feugiat scelerisque parius fmepol consequat mauris nunco.'
                },
                {
                    id: 2,
                    srcimg: 'images/courses/courses-index2-3.jpg',
                    ratting: [
                        {
                            id: 1,
                            icon: 'far fa-star'
                        },
                        {
                            id: 2,
                            icon: 'far fa-star'
                        },
                        {
                            id: 3,
                            icon: 'far fa-star'
                        },
                        {
                            id: 4,
                            icon: 'far fa-star'
                        },
                        {
                            id: 5,
                            icon: 'far fa-star-half'
                        },
                    ],
                    count: '5.0 (572)',
                    teacher: 'Roman Eithne',
                    title: 'Special Education Needs Teaching',
                    description:'Rellus integer feugiat scelerisque parius fmepol consequat mauris nunco.'
                },
                {
                    id: 3,
                    srcimg: 'images/courses/courses-index2-4.jpg',
                    ratting: [
                        {
                            id: 1,
                            icon: 'far fa-star'
                        },
                        {
                            id: 2,
                            icon: 'far fa-star'
                        },
                        {
                            id: 3,
                            icon: 'far fa-star'
                        },
                        {
                            id: 4,
                            icon: 'far fa-star'
                        },
                        {
                            id: 5,
                            icon: 'far fa-star-half'
                        },
                    ],
                    count: '4.5 (1,387)',
                    teacher: 'John Maddix',
                    title: 'Higher Level Teaching Child Psychology',
                    description:'Rellus integer feugiat scelerisque parius fmepol consequat mauris nunco.'
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="flat-courses clear-sub-columns clearfix isotope-courses style2">
                <div className="course style5 v1 ">
                    <div className="course-border transition-vline">
                        <div className="course-img img-vline">
                            <Link to="#"><img src="images/courses/courses-index2-1.jpg" alt="bookflare" /></Link>
                            <div className="overlay">
                                <span className="vline" />
                                <span className="vline vline-bottom" />
                            </div>
                        </div>
                        <div className="course-content">
                            <div className="text-wrap ">
                                <div className="wrap-rating">
                                    <ul className="list star-list">
                                        <li><i className="far fa-star" /></li>
                                        <li><i className="far fa-star" /></li>
                                        <li><i className="far fa-star" /></li>
                                        <li><i className="far fa-star" /></li>
                                        <li><i className="far fa-star-half" /></li>
                                    </ul>
                                    <span>4.7 (2,632)</span>
                                </div>
                                <p className="teacher">
                                    <Link to="#">Robert Cook</Link>
                                </p>
                                <h6 className="title">
                                    <Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>Information Technology Inside</Link>
                                </h6>
                                <p className="description">Aellus integer feugiat scelerisque varius. Sit amet volutpat consequat
                                    mauris nunc congue nisi. Atpltrices mi tempus imperdiet nulla malesuada pellentesque. Netus et
                                    malesuad fames ac turpis mgestasMorbi blandit cursus risus at. Par etiam non quam lacus. Sit
                                    amet porttitor eget sergel mornon arcu risus. Volutpat consequat mauris nunc congue.</p>
                                <div className="wrap-btn">
                                    <Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}} className="btn-learn-more">LEARN MORE</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="course style5 v2">
                    <div className="widget-subscribe flat-text-center">
                        <h4 className="widget-title ">Subscribe Newslatter </h4>
                        <p className="text">Netus et malesuad fames ac tmgest blandi cursus risuar etiam non puam lacus. </p>
                        <p className="over font-Freestyle">Over </p>
                        <p className="wrap-over"><span className="count  font-Freestyle">20,640</span> <span className="free"> Free
                                Tutorial</span></p>
                        <div className="wrap-description">
                            <p className="description">Develop in-demand skills with access to thousands of expert-led courses on
                                business, tech and creative topics.</p>
                        </div>
                        <div className="wrap-form-subscribe">
                            <form>
                                <input type="email" name="email" placeholder="Enter your Email" />
                                <button className="btn-send flat-button v2">SEND</button>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    this.state.dataCourse.map(data =>(
                        <div className="course style5 v2" key={data.id}>
                            <div className="course-border transition-vline">
                                <div className="course-img img-vline">
                                    <Link to="#"><img src={data.srcimg} alt="bookflare" /></Link>
                                    <div className="overlay">
                                        <span className="vline" />
                                        <span className="vline vline-bottom" />
                                    </div>
                                </div>
                                <div className="course-content">
                                    <div className="text-wrap ">
                                        <div className="wrap-rating">
                                            <ul className="list star-list">
                                                {
                                                    data.ratting.map(rat =>(
                                                        <li key={rat.id}><i className={rat.icon}></i></li>
                                                    ))
                                                }
                                            </ul>
                                            <span>{data.count}</span>
                                        </div>
                                        <p className="teacher">
                                            <Link to="#">{data.teacher}</Link>
                                        </p>
                                        <h6 className="title">
                                            <Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>{data.title}</Link>
                                        </h6>
                                        <p className="description">{data.description}</p>
                                        <div className="wrap-btn">
                                            <Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}} className="btn-learn-more">LEARN MORE</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default CoursesBox;