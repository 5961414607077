import React, { Component } from 'react';
import {Link} from 'react-router-dom';

const linkClickHandle = () =>{
window.scrollTo({ top:0 });
window.location.href='/home';
}
export default function IntroducFT() {
    return (
        <div className="widget-about">
        <div id="logo-ft">
            <Link to="#" onClick={linkClickHandle}><img src="images/logo/ariba-feildglass-logo.png" alt="bookflare" width={157} height={29}
                data-retina="images/logo/ariba-feildglass-logo.png" data-width={157} data-height={29} /></Link>
        </div>
        <p className="description">Our Online SAP Courses  are  constructed as per the Current Industry Standard with Live Project Based Training. We are offering a comprehensive and one-to-one Online SAP training program to our students.
        </p>
        {/* <div className="list-info">
            <ul>
                <li className="address">
                    <Link to="#">1107 Wood Street Saginaw, MI New York 48607</Link>
                </li>
                <li className="phone">
                    <Link to="#">+123 345 678 000</Link>
                </li>
                <li className="mail">
                    <Link to="#">info@example.com</Link>
                </li>
            </ul>
        </div> */}
        {/* <div className="socails">
            <ul className="list">
                <li>
                    <Link to="#"><span className="fa fa-twitter" /></Link>
                </li>
                <li>
                    <Link to="#"><span className="fa fa-linkedin-square" /></Link>
                </li>
                <li>
                    <Link to="#"><span className="fa fa-facebook-official" /></Link>
                </li>
                <li>
                    <Link to="#"><span className="fa fa-skype" /></Link>
                </li>
                <li>
                    <Link to="#"><span className="fa fa-pinterest-square" /></Link>
                </li>
            </ul>
        </div> */}
    </div>
    )
}

// import React, { Component } from 'react';
// import {Link} from 'react-router-dom';

// class IntroducFT extends Component {
//     render() {
//         return (
//             <div className="widget-about">
//                 <div id="logo-ft">
//                     <Link to="index.html"><img src="images/logo/ariba-feildglass-logo.png" alt="bookflare" width={157} height={29}
//                         data-retina="images/logo/ariba-feildglass-logo.png" data-width={157} data-height={29} /></Link>
//                 </div>
//                 <p className="description">Our Online SAP Courses  are  constructed as per the Current Industry Standard with Live Project Based Training. We are offering a comprehensive and one-to-one Online SAP training program to our students.
//                 </p>
//                 {/* <div className="list-info">
//                     <ul>
//                         <li className="address">
//                             <Link to="#">1107 Wood Street Saginaw, MI New York 48607</Link>
//                         </li>
//                         <li className="phone">
//                             <Link to="#">+123 345 678 000</Link>
//                         </li>
//                         <li className="mail">
//                             <Link to="#">info@example.com</Link>
//                         </li>
//                     </ul>
//                 </div> */}
//                 {/* <div className="socails">
//                     <ul className="list">
//                         <li>
//                             <Link to="#"><span className="fa fa-twitter" /></Link>
//                         </li>
//                         <li>
//                             <Link to="#"><span className="fa fa-linkedin-square" /></Link>
//                         </li>
//                         <li>
//                             <Link to="#"><span className="fa fa-facebook-official" /></Link>
//                         </li>
//                         <li>
//                             <Link to="#"><span className="fa fa-skype" /></Link>
//                         </li>
//                         <li>
//                             <Link to="#"><span className="fa fa-pinterest-square" /></Link>
//                         </li>
//                     </ul>
//                 </div> */}
//             </div>
//         );
//     }
// }

// export default IntroducFT;