import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  TextField,
  Typography,
  Container,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CheckoutSummary from "./CheckoutSummary";
//import { GooglePay } from 'react-native-google-pay';
import GooglePayButton from "@google-pay/button-react";
// import httpService from "../../../HttpService/httpService";
//import DatePicker from '@mui/lab/DatePicker';
const useStyles = makeStyles((theme) => ({
  subform: {
    padding: "10px",
    border: "1px solid #d9d2d2",
    marginTop: "10px",
    marginBottom: "10px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1f3a68",
    "&:hover": {
      background: "#1f3a68",
      color: "#f7c13a",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "theme.palette.background.paper",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
  formControl: {
    margin: "0px",
    minWidth: 120,
  },

  formField: {
    padding: "5px 0px 10px 0px",
  },
  headlogin: {
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "2.25rem",
    paddingBottom: "20px",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    "& .MuiAutocomplete-inputRoot": {
      padding: "0px",
      height: "46px",
      background: "transperent",
      marginTop: "5px;",
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: "20px",
    },
  },
}));
export default function CheckoutComponent() {
  const classes = useStyles();

  const [value, setValue] = React.useState("Credit or Debit Card");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  // useEffect(() => {
  //     httpService
  //       .get("/countries")
  //       .then((res) => {
  //         setCountries(res.data.countries[0]);
  //       })
  //       .catch((err) => err);
  //   }, []);
  return (
    <div>
      <Container component="main" maxWidth="xl">
        <div className="paper ">
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7} xl={7}>
                <CssBaseline />

                {/* <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.headlogin}
                >
                  Checkout
                </Typography>

                <Typography>Billing Address</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id="country-select-demo"
                      className={classes.root}
                      // value={stateObj.account.country}
                      // onChange={(event, newValue) => {
                      //   onDropdownChangeHandler("country", newValue);
                      // }}
                      autoHighlight
                      // options={countries}
                      getOptionLabel={(option) => (option ? option.name : "")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a country"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      id="country-select-demo"
                      className={classes.root}
                      // value={stateObj.account.country}
                      // onChange={(event, newValue) => {
                      //   onDropdownChangeHandler("country", newValue);
                      // }}
                      autoHighlight
                      // options={countries}
                      getOptionLabel={(option) => (option ? option.name : "")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a country"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset">
                    {/* <FormLabel component="legend">Gender</FormLabel> */}
                    <RadioGroup
                      aria-label="Credit or Debit Card"
                      name="gender1"
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Credit or Debit Card"
                        control={<Radio color="primary" />}
                        label="Credit or Debit Card"
                      />
                      <GooglePayButton
                        environment="TEST"
                        paymentRequest={{
                          apiVersion: 2,
                          apiVersionMinor: 0,
                          allowedPaymentMethods: [
                            {
                              type: "CARD",
                              parameters: {
                                allowedAuthMethods: [
                                  "PAN_ONLY",
                                  "CRYPTOGRAM_3DS",
                                ],
                                allowedCardNetworks: ["MASTERCARD", "VISA"],
                              },
                              tokenizationSpecification: {
                                type: "PAYMENT_GATEWAY",
                                parameters: {
                                  gateway: "example",
                                  gatewayMerchantId: "exampleGatewayMerchantId",
                                },
                              },
                            },
                          ],
                          merchantInfo: {
                            merchantId: "12345678901234567890",
                            merchantName: "Demo Merchant",
                          },
                          transactionInfo: {
                            totalPriceStatus: "FINAL",
                            totalPriceLabel: "Total",
                            totalPrice: "1",
                            currencyCode: "USD",
                            countryCode: "IN",
                          },
                          shippingAddressRequired: true,
                          callbackIntents: [
                            "SHIPPING_ADDRESS",
                            "PAYMENT_AUTHORIZATION",
                          ],
                        }}
                        onLoadPaymentData={(paymentRequest) => {
                          console.log("Success", paymentRequest);
                        }}
                        onPaymentAuthorized={(paymentData) => {
                          console.log(
                            "Payment Authorised Success",
                            paymentData
                          );
                          return { transactionState: "SUCCESS" };
                        }}
                        onPaymentDataChanged={(paymentData) => {
                          console.log("On Payment Data Changed", paymentData);
                          return {};
                        }}
                        existingPaymentMethodRequired="false"
                        buttonColor="black"
                        buttonType="Buy"
                      />
                      <FormControlLabel
                        value="Pay with Net Banking"
                        control={<Radio color="primary" />}
                        label="Pay with Net Banking"
                      />
                      <FormControlLabel
                        value="Pay with UPI"
                        control={<Radio color="primary" />}
                        label="Pay with UPI"
                      />
                      <FormControlLabel
                        value="Pay with PayTM"
                        control={<Radio color="primary" />}
                        label="Pay with PayTM"
                      />
                      <FormControlLabel
                        value="Pay with Wallets India"
                        control={<Radio color="primary" />}
                        label="Pay with Wallets India"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid container spacing={2} className={classes.subform}>
                  <Grid item xs={12} sm={12} xl={6}>
                    <TextField
                      className={classes.formField}
                      // autoComplete="off"
                      name="nameOnCard"
                      variant="outlined"
                      fullWidth
                      id="nameOnCard"
                      label="Name on Card"
                      autoFocus
                      // onChange={changeHandler}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} xl={6}>
                    <TextField
                      className={classes.formField}
                      // autoComplete="off"
                      name="cardNumber"
                      variant="outlined"
                      fullWidth
                      id="cardNumber"
                      label="Card Number"
                      autoFocus
                      // onChange={changeHandler}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.formField}
                      // autoComplete="off"
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className={classes.formField}
                      // autoComplete="off"
                      name="SecurityCode"
                      variant="outlined"
                      fullWidth
                      id="SecurityCode"
                      label="Security Code"
                      autoFocus
                    />
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={12} xl={6}>
                {/* <DatePicker
                  label="Helper text example"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={params?.inputProps?.placeholder}
                    />
                  )}
                />
              </Grid> */}
              </Grid>
              <Grid item xs={12} sm={5} xl={5}>
                <CheckoutSummary />
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}
