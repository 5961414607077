import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class BottomBar extends Component {
    render() {
        return (
            <div className="bottom bg-15222e">
                <div className="container">
                    <div className="row">
                        <div className="  col-md-6">
                            <div className="copyright flat-text-left">
                                <p>© Copyright 2021 AribaFieldglassOnline Training. All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="  col-md-6">
                            <div className="widget flat-text-right no-border">
                                <ul className="list">
                                    <li><Link to="/faq">Refund policy</Link></li>
                                    <li><Link to="/about-us">Terms & Conditions</Link></li>
                                    <li><Link to="/blog">Privacy Policy</Link></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomBar;