import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class VideoComponent extends Component {
    render() {
        return (
            <section className="flat-video">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-12" />
                        <div className="col-xl-8 col-lg-12">
                            <div className="video">
                                <div className="video-content">
                                    <h2 className="title"><Link to="#">Join us to become SAP industry expert!</Link></h2>
                                    <p className="description">  We aim to provide the most practical, industry-focused, hands-on, cost-effective courses which will enable our students to gain skills needed by industry and hence become employable.</p>
                                </div>
                                <div className="video-icon">
                                    <a className="fancybox videobox-link" data-type="iframe"
                                        href="https://www.youtube.com/embed/2Ge1GGitzLw?autoplay=1">
                                        <span className="icon-video" />
                                        <div className="videobox-animation circle-1" />
                                        <div className="videobox-animation circle-2" />
                                        <div className="videobox-animation circle-3" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default VideoComponent;