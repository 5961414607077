import React, { Component } from 'react';
import { BottomBar, Footer, Header, TopBar, Loader } from '../layouts/general';
import {  BannerComponent,ComparisionComponent } from '../layouts/pages';
import { ContactForm,ContactForm2} from '../layouts/homeanimate';
import { Featured, Slider, FormApply,TabCourses } from '../layouts/home01';
//import { CourseBox, TitleHeading, Instructor, EventBoxs } from '../layouts/homeanimate';
import { Link } from 'react-router-dom';
import { render } from '@testing-library/react';
import data from '../layouts/events/dataEvent';




class Comparision extends Component{
    constructor(props){
        super(props);
        this.state =  {
            headers:
                [
                    {
                        id:1,
                        name:'pages'
                    }
                ],
                dataBanner:[
                    {
                    id: 1,
                    classename01: 'flat-title-page parallax parallax7 style3',
                    classename02: 'container wrap-title-page ',
                    links: '/comparision',
                    names: 'Comparision'
                    }
                ]
            
        }


    }

render(){
    
    return(
        <div className="bg-body2">
        <div className="boxed">
        <Loader />
        <TopBar />
        {
            this.state.headers.map(data=>(
                <Header data={data} key={data.id}/>

            ))
        }
        {   
            this.state.dataBanner.map(data=>(
                <BannerComponent data={data} key={data.id} />

            ))
           
        }
                <section>
                  <ComparisionComponent />
        
                </section>
<Footer />
<BottomBar/>

</div>
</div>
    );
}


}
export default Comparision;


