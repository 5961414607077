import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import menus from '../menu'
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linklogo: '/',
        }
    }
    render() {
        const { location } = this.props;
        return (
            <div id="header" className="bg-192836 style2 v2">
                <div className="container">
                    <div className="header-wrap clearfix">
                        <div id="logo">
                            <Link to="/" onClick={() => {window.location.href=this.state.linklogo}}><img src="images/logo/logo2.png" alt="bookflare" width={157} height={29}
                                data-retina="images/logo/logo2@2x.png" data-width={157} data-height={29} /></Link>
                        </div>
                        <div className="nav-wrap flat-text-right style1">
                            <nav id="main-nav">
                                <ul className="menu">
                                    {
                                        menus.map(data =>(
                                            <li className={data.name === this.props.data.names ? "active" : ""} key={data.id}>
                                                <Link to={data.linkmenu} onClick={() => {window.location.href=data.linkmenu}}>{data.name}</Link>
                                                {
                                                    data.namesub === undefined ? "" : 
                                                    <ul className="submenu">
                                                        {
                                                            data.namesub.map(submenus =>(
                                                                <li className={location.pathname === submenus.links ? "item-has-child active" : "" } key={submenus.id}><Link to={submenus.links} onClick={() => {window.location.href=submenus.links}}>{submenus.sub}</Link>
                                                                    {
                                                                        submenus.submenu === undefined ? "" :
                                                                        <ul className="submenu">
                                                                            {
                                                                                submenus.submenu.map(menusub => (
                                                                                    <li className={location.pathname === menusub.linksub ? "active" : ""} key={menusub.id}><Link to= {menusub.linksub} onClick={() => {window.location.href=menusub.linksub}}>{menusub.titlesub}</Link></li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    }
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </nav>
                        </div>
                        <div className="extra-menu flat-text-right">
                            <ul>
                                <li>
                                    <div className="show-search text-right">
                                        <Link to="#"><i className="icon-search" /></Link>
                                        <div className="submenu top-search widget-search">
                                            <div className="search-header">
                                                <form>
                                                    <input type="search" name="key" placeholder="Search for Courses" />
                                                    <button className="btn-search"><span className="icon-search" /></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="cart nav-top-cart-wrapper">
                                    <Link to="#"><span className="bf-icon icon-cart" /></Link> <span className="count-cart">0</span>
                                    <div className="nav-shop-cart">
                                        <div className="widget_shopping_cart_content">
                                            <div className="woocommerce-min-cart-wrap">
                                                <ul
                                                    className="woocommerce-mini-cart cart_list product_list_widget flat-text-center">
                                                    <li className="woocommerce-mini-cart-item mini_cart_item">
                                                        <span>No Items in Shopping Cart</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="mobile-button">
                            <span />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);