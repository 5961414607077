import React, { Component } from 'react';
import { TopBar, Header, TitleHeading, CoursesBox, SlideTestimonial, Instructor, Paramater, Footer, BottomBar, Loader} from '../layouts/general';
import {CounterComponent, Slider, VideoComponent} from '../layouts/home03';
class Home03 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Home'
                }
            ],
            titleCourses: [
                {
                    id: 1,
                    title: 'Discover Our Popular Courses',
                    classTitle: 'title',
                     classDescription: 'description',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleTestimonial: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'What Our Teacher’s Say',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleIntructor: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'Our Skilled Instructors',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
        }
    }
    render() {
        return (
            <div className="boxed">
                <Loader />
                <TopBar />
                {
                    this.state.headers.map(data => (
                        <Header data={data} key={data.id}/>
                    ))
                }             
                <Slider />  
                <CounterComponent />
                <section className="flat-popular-courses style3">
                    <div className="container">
                        {
                            this.state.titleCourses.map(data =>(
                                <TitleHeading data={data} key={data.id}/>
                            ))
                        }
                        <CoursesBox />
                    </div>
                </section>              
                <VideoComponent />  
                <section className="flat-testimonials style3">
                    <div className="container">
                        {
                            this.state.titleTestimonial.map(data =>(
                                <TitleHeading data={data} key={data.id}/>
                            ))
                        }
                        <SlideTestimonial />
                    </div>
                </section>
                <section className="flat-skilled-instructors style3 bg-fff">
                    <div className="container">
                        {
                            this.state.titleIntructor.map(data =>(
                                <TitleHeading data={data} key={data.id}/>
                            ))
                        }
                        <Instructor />
                    </div>
                </section>
                <section className="flat-feature parallax parallax2">
                    <div className="container">
                        <Paramater />
                    </div>
                </section>
                <Footer />
                <BottomBar />
            </div>
        );
    }
}

export default Home03;