import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { checkValidity } from '../../../Shared';
import httpService from '../../../HttpService/httpService';
import CustomizedSnackbars from '../../../Constants/config';
import { Button, TextField, Grid, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#1f3a68',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#1f3a68',
    '&:hover': {
      background: '#1f3a68',
      color: '#f7c13a',
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'theme.palette.background.paper',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  formControl: {
    margin: '0px',
    minWidth: 120,
  },

  formField: {
    padding: '5px 0px 10px 0px',
    '& .MuiFormLabel-root': {
      // color: "#c2c2c2",
    },
  },
  headlogin: {
    fontSize: '1.875rem',
    fontWeight: '700',
    lineHeight: '2.25rem',
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '0px',
      height: '46px',
      background: 'transperent',
      marginTop: '4px;',
    },
    '& .MuiOutlinedInput-root': {
      paddingLeft: '20px',
    },
  },
  country: {
    '& .MuiFormLabel-root': {
      // color: "#c2c2c2",
    },
  },
  d_snackbar: {
    '& .MuiSnackbar-anchorOriginTopRight': {
      top: '0px',
      left: 'auto',
      right: '24px',
      position: 'relative',
      paddingBottom: '35px',
    },
  },
}));

export default function FormApply() {
  const classes = useStyles();
  const [courses, setCourses] = useState([]);
  const [snackbar, setSnackbar] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');

  const [stateObj, setError] = useState({
    error: {
      Name: {
        errorObject: {
          required: true,
          errorMessage: '',
          isValid: true,
          isName: true,
          nMinlength: 3,
          nMaxlength: 18,
        },
      },

      email: {
        errorObject: {
          required: true,
          errorMessage: '',
          isValid: true,
          isEmail: true,
        },
      },

      mobile: {
        errorObject: {
          required: true,
          errorMessage: '',
          isValid: true,
          isMobile: true,
          MobminLength: 10,
          MobmaxLength: 10,
          isNumber: true,
        },
      },
      course: {
        errorObject: {
          required: true,
          errorMessage: '',
          isValid: true,
          isDropdown: true,
        },
      },
    },
    account: {
      Name: '',

      email: '',

      mobile: '',
      course: null,
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
    httpService
      .get('/customer/demo/course/list')
      .then((res) => {
        setCourses(res.data.courses);
      })
      .catch((err) => err);
  }, []);

  const changeHandler = (e) => {
    const { value, name } = e.target;
    const { error, account } = stateObj;
    const validationObj = error[name];
    const { errorObject } = checkValidity(value, name, validationObj);
    setError({
      ...stateObj,
      error: { ...error, [name]: { errorObject } },
      account: { ...account, [name]: value },
    });
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  const onDropdownChangeHandler = (cntrlName, value) => {
    const { error, account } = stateObj;
    const validationObj = error[cntrlName];
    const id = value === null ? 0 : value.id;
    console.log(id);
    const { errorObject } = checkValidity(id, cntrlName, validationObj);
    setError({
      ...stateObj,
      error: { ...error, [cntrlName]: { errorObject } },
      account: { ...account, [cntrlName]: value },
    });
  };

  const onSubmitHandler = () => {
    const { error, account } = stateObj;
    let result = true;
    for (let err in error) {
      const value = account[err];
      const name = err;
      const validationObj = error[name];
      const { errorObject } = checkValidity(value, name, validationObj);
      if (errorObject.errorMessage !== '') {
        result = false;
      }
      error[name] = { errorObject };
    }
    setError({ account, error: error });
    if (result === true) {
      httpService
        .post('/customer/demo/course/request', {
          customerName: account.Name,
          customerMail: account.email,
          customerMobile: account.mobile,
          courseTypeId: account.course.id,
        })
        .then((res) => {
          console.log(res);
          setSeverity('success');
          setMessage(res.data.message);
          setSnackbar(true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          // console.log(err);
          setSeverity('warning');
          setMessage(err.response.data.message);
          setSnackbar(true);
        });
    }
  };
  return (
    <div className="wrap-form flat-text-center">
      <p className="free">Online Courses </p>
      <div className="heading">
        <h6 className="title"> START FREE DEMO</h6>
        <p className="description">
          Stay Sharp. Get ahead with Learning Paths.
        </p>
      </div>

      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12} className={classes.d_snackbar}>
              <CustomizedSnackbars
                open={snackbar}
                closeSnackbar={closeSnackbar}
                message={message}
                severity={severity}
              />
            </Grid>
            <TextField
              className={classes.formField}
              // autoComplete="off"
              name="Name"
              variant="outlined"
              fullWidth
              id="firstName"
              label="Name"
              onChange={changeHandler}
              error={!stateObj.error.Name.errorObject.isValid}
              helperText={stateObj.error.Name.errorObject.errorMessage}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              className={classes.formField}
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              // autoComplete="off"
              onChange={changeHandler}
              error={!stateObj.error.email.errorObject.isValid}
              helperText={stateObj.error.email.errorObject.errorMessage}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.formField}
              variant="outlined"
              required
              fullWidth
              id="mobile"
              label="Mobile"
              name="mobile"
              //  autoComplete="off"
              onChange={changeHandler}
              error={!stateObj.error.mobile.errorObject.isValid}
              helperText={stateObj.error.mobile.errorObject.errorMessage}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="country-select-demo"
              name="course"
              className={classes.root}
              value={stateObj.account.course}
              onChange={(event, newValue) => {
                onDropdownChangeHandler('course', newValue);
              }}
              options={courses}
              getOptionLabel={(option) =>
                option ? option.course_type_name : ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a Course"
                  variant="outlined"
                  name="course"
                  className={classes.country}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  error={!stateObj.error.course.errorObject.isValid}
                  helperText={stateObj.error.course.errorObject.errorMessage}
                />
              )}
            />
          </Grid>
        </Grid>
        <Button
          // type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmitHandler}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}
