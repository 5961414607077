import React from "react";
import CheckoutComponent from "../../chekout/CheckoutComponent";
import Layout from "../layout/layout";
export default function Checkout() {
  return (
    <Layout>
      <CheckoutComponent />
    </Layout>
  );
}
