import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import menus from '../menu';
import menusWithMyAcc from '../menusWithMyAcc';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { CartContext } from '../layout/layout';
import logoImage from '../../../images/logo/ariba-feildglass-logo.png';
export default function Header(props) {
  const {
    token,
    cartdata = [],
    cartDataBeforeLogin = [],
    wishlist = [],
  } = useContext(CartContext) || {};
  const cartsCount = token ? cartdata : cartDataBeforeLogin;
  const wishlistBeforeLogin = JSON.parse(localStorage.getItem('sap-wishlist'));
  const wishlistCount = token ? wishlist : wishlistBeforeLogin;

  const { location } = props;
  const state = [{ linklogo: '/' }];
  const linkClickHandle = () => {
    window.scrollTo({ top: 0 });
    window.location.href='/home';
  }
  return (
    <div id="header" className="bg-fff style1">
      <div className="container">
        <div className="header-wrap clearfix">
          <div id="logo">
            <Link
              to="#"
              onClick={linkClickHandle}
            >
              <img
                src={logoImage}
                alt="bookflare"
                width={157}
                height={29}
                data-retina={logoImage}
                data-width={157}
                data-height={29}
              />
            </Link>
          </div>
          <div className="nav-wrap flat-text-right style1">
            <nav id="main-nav">
              {localStorage.getItem('token') ? (
                <ul className="menu">
                  {menusWithMyAcc.map((data) => (
                    <li className={data.name ? 'active' : ''} key={data.id}>
                      <Link
                        to={data.linkmenu}
                        onClick={() => {
                          window.location.href = data.linkmenu;
                        }}
                      >
                        {data.name}
                      </Link>
                      {data.namesub === undefined ? (
                        ''
                      ) : (
                        <ul className="submenu">
                          {data.namesub.map((submenus) => (
                            <li
                              className={
                                location.pathname === submenus.links ||
                                submenus.sub === this.props.data.names02
                                  ? 'active'
                                  : ''
                              }
                              key={submenus.id}
                            >
                              <Link
                                to={submenus.links}
                                onClick={() => {
                                  window.location.href = submenus.links;
                                }}
                              >
                                {submenus.sub}
                              </Link>
                              {submenus.submenu === undefined ? (
                                ''
                              ) : (
                                <ul className="submenu">
                                  {submenus.submenu.map((menusub) => (
                                    <li
                                      className={
                                        location.pathname === menusub.linksub
                                          ? 'active'
                                          : ''
                                      }
                                      key={menusub.id}
                                    >
                                      <Link
                                        to={menusub.linksub}
                                        onClick={() => {
                                          window.location.href =
                                            menusub.linksub;
                                        }}
                                      >
                                        {menusub.titlesub}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="menu">
                  {menus.map((data) => (
                    <li className={data.name ? 'active' : ''} key={data.id}>
                      <Link
                        to={data.linkmenu}
                        onClick={() => {
                          window.location.href = data.linkmenu;
                        }}
                      >
                        {data.name}
                      </Link>
                      {data.namesub === undefined ? (
                        ''
                      ) : (
                        <ul className="submenu">
                          {data.namesub.map((submenus) => (
                            <li
                              className={
                                location.pathname === submenus.links ||
                                submenus.sub === this.props.data.names02
                                  ? 'active'
                                  : ''
                              }
                              key={submenus.id}
                            >
                              <Link
                                to={submenus.links}
                                onClick={() => {
                                  window.location.href = submenus.links;
                                }}
                              >
                                {submenus.sub}
                              </Link>
                              {submenus.submenu === undefined ? (
                                ''
                              ) : (
                                <ul className="submenu">
                                  {submenus.submenu.map((menusub) => (
                                    <li
                                      className={
                                        location.pathname === menusub.linksub
                                          ? 'active'
                                          : ''
                                      }
                                      key={menusub.id}
                                    >
                                      <Link
                                        to={menusub.linksub}
                                        onClick={() => {
                                          window.location.href =
                                            menusub.linksub;
                                        }}
                                      >
                                        {menusub.titlesub}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </nav>
          </div>
          <div className="extra-menu flat-text-right clearfix">
            {/* <div className="wrap-search-header">
                                <div className="search-header">
                                    <form>
                                        <input type="search" name="key" placeholder="Search for Courses" />
                                        <button className="btn-search"><span className="icon-search" /></button>
                                    </form>
                                </div>
                            </div> */}

            {/* cart starts here */}
            <div className="cart nav-top-cart-wrapper">
              <Link to="/cart">
                {/* <span className="bf-icon icon-cart" /> */}
                <ShoppingCartIcon />
              </Link>
              <span className="count-cart">{cartsCount.length}</span>
              <div className="nav-shop-cart">
                <div className="widget_shopping_cart_content">
                  <div className="woocommerce-min-cart-wrap">
                    <ul className="woocommerce-mini-cart cart_list product_list_widget flat-text-center">
                      <li className="woocommerce-mini-cart-item mini_cart_item">
                        {cartsCount ? (
                          <span>
                            {cartsCount.length} Items in Shopping Cart
                          </span>
                        ) : (
                          <span>No Items in Shopping Cart</span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* cart ends here */}

            {/* wishlist starts */}
            <div className="cart nav-top-cart-wrapper">
              <Link to="/wishlist">
                <FavoriteBorderIcon />
              </Link>
              <span className="count-cart">{wishlist.length}</span>
              <div className="nav-shop-cart">
                <div className="widget_shopping_cart_content">
                  <div className="woocommerce-min-cart-wrap">
                    <ul className="woocommerce-mini-cart cart_list product_list_widget flat-text-center">
                      <li className="woocommerce-mini-cart-item mini_cart_item">
                        {cartsCount ? (
                          <span>{wishlist.length} Items in wishlist</span>
                        ) : (
                          <span>No Items in wishlist</span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* wishlist ends here */}
          </div>
          <div className="mobile-button">
            <span />
          </div>
        </div>
      </div>
    </div>
  );
}
